import {
  Box,
  Button,
  Heading,
  Text,
  useToast,
  Skeleton,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Center,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react'
import InfoAlert from '../../../components/infoAlert/infoAlert'
import FormDrawer, {
  FormDataType,
} from '../../../components/formDrawer/formDrawer'
import { useEffect, useState } from 'react'
import { isCurrentUserStaff } from '../../../utils/basicFunc/basicFunc'
import { useAuth } from '../../../hooks/use-auth'
import {
  allDrawerInputs,
  mapKeyValue,
} from '../../../utils/pageDrawerInputLists/inputLists'
import { GET_BUILDING, GET_BUILDINGS } from '../../../graphql/API/queries'
import { useTranslation } from 'react-i18next'
import {
  AddCompanyRelationshipInput,
  Address,
  BuildingUpdateInput,
  Company,
  CompanyListQuery,
  CompanyRelationship,
  useAddCompanyRelationshipMutation,
  useBuildingDeleteMutation,
  useBuildingUpdateMutation,
  useCompanyListQuery,
  useDashboardUpdateMutation,
} from '../../../graphql/generated/graphql'
import BuildingRelationTable from '../buildingRelationTable/buildingRelationTable'
import { SettingsIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

export enum periodEnum {
  none = 'none',
  hourly = 'hourly',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  yearly = 'yearly',
}

export function periodGuard(period: unknown): period is periodEnum {
  if (
    period === 'none' ||
    period === 'hourly' ||
    period === 'daily' ||
    period === 'weekly' ||
    period === 'monthly' ||
    period === 'yearly'
  ) {
    return true
  }
  return false
}

type BuildingCompanyModalProps = {
  isOpen: boolean
  onClose: () => void
  companyRelationships: CompanyRelationship[]
  companyList: CompanyListQuery
  buildingId: string
}

const BuildingCompanyModal = ({
  isOpen,
  onClose,
  companyRelationships,
  companyList,
  buildingId,
}: BuildingCompanyModalProps) => {
  const [addCompanyRelationship, { loading }] =
    useAddCompanyRelationshipMutation()
  const companySelectOptions = companyList?.companies.map(
    (company: Partial<Company>) => {
      return { value: company.id!, text: company.name! }
    },
  )
  const [isDrawerOpen, setDrawerVisibility] = useState(false)
  const [tableData, setTableData] = useState<CompanyRelationship[]>([])
  const toast = useToast()

  useEffect(() => {
    setTableData(companyRelationships)
  }, [companyRelationships])

  //NEED TO CHANGE THE SETSTATE LOGIC TO UPDATE METHOD PREFERRED BY APOLLO CLIENT

  const onDrawerFormSubmit = (data: FormDataType) => {
    const companyRelationInput = {
      ...data,
      buildingId,
    } as AddCompanyRelationshipInput
    addCompanyRelationship({
      variables: { data: companyRelationInput },
      onCompleted: (res) => {
        const newTableData = [
          ...tableData,
          res.addCompanyRelationship,
        ] as CompanyRelationship[]
        setDrawerVisibility(false)
        setTableData(newTableData)
        toast({
          title: 'Relation created successfully',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const onDelete = (values: CompanyRelationship) => {
    const afterDelete = tableData?.filter(
      (data) => data.companyId !== values.companyId,
    )
    setTableData(afterDelete)
  }

  const onUpdate = (values: CompanyRelationship) => {
    const afterUpdate = tableData.map((data) =>
      data.companyId === values.companyId ? values : data,
    )
    setTableData(afterUpdate)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center justifyContent={'space-between'}>
            <Text>Building-Company Relationship</Text>
            <Button
              onClick={() => setDrawerVisibility(true)}
              colorScheme={'purple'}
            >
              + Create relation
            </Button>
          </Center>
        </ModalHeader>
        <ModalBody>
          <BuildingRelationTable
            companyRelationships={tableData!}
            companySelectOptions={companySelectOptions}
            onRelationDelete={onDelete}
            onRelationUpdate={onUpdate}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
        <FormDrawer
          openedFrom={'Company Relation'}
          isCreate={true}
          isOpen={isDrawerOpen}
          onClose={() => setDrawerVisibility(false)}
          loading={loading}
          inputElements={allDrawerInputs['companyRelationCreate']}
          onDrawerFormSubmit={onDrawerFormSubmit}
          selectOptions={companySelectOptions}
        />
        {/* <ModalCloseButton /> */}
      </ModalContent>
    </Modal>
  )
}

type BuildingRowProps = {
  id: string
  name: string
  type: string
  picture: string
  floorArea: number
  epcRating: string
  companyRelationships: CompanyRelationship[]
  projectRef: string
  loading: boolean
  buildingSlug: string
  epcUrl: string
  address: Address
  constructionYear: number
  breeamScore: number
  dashboardPeriod?: periodEnum
}

export default function BuildingConfiguration({
  id,
  name,
  type,
  picture,
  floorArea,
  companyRelationships,
  epcRating,
  projectRef,
  loading,
  buildingSlug,
  address,
  epcUrl,
  breeamScore,
  constructionYear,
  dashboardPeriod,
}: BuildingRowProps) {
  const havePermissions = useAuth().user.isStaff ?? isCurrentUserStaff()
  const { t } = useTranslation('building')
  const toast = useToast()
  const toastSuccess = (message: string) =>
    toast({
      title: message,
      status: 'success',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })
  const toastFail = (message: string) =>
    toast({
      title: message,
      status: 'error',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })

  // if (!periodGuard(dashboardPeriod)) {
  //   console.error(
  //     `DataQuality received unexpected dashboardPeriod value : ${dashboardPeriod}`,
  //   )
  // }

  const slug = buildingSlug ?? 'unknown_building'

  // State variables
  const [isDeleteOpen, setDeleteOpen] = useState(false)
  const [isBuildingEditOpen, setBuildingEditOpen] = useState(false)
  const [isDashboardEditOpen, setDashboardEditOpen] = useState(false)
  const {
    isOpen: isRelationshipsOpen,
    onOpen: openRelationships,
    onClose: closeRelationships,
  } = useDisclosure()
  const navigate = useNavigate()

  // Company list
  const companyList = useCompanyListQuery({
    variables: { where: {} },
  }).data

  const dashboardEditProps = {
    page: 'dashboardEdit',
    data: {
      period: dashboardPeriod ?? '',
    },
  }

  const [updateDashboard, { loading: dashboardEditLoader }] =
    useDashboardUpdateMutation()
  const onDashboardEditSubmit = (formData: FormDataType) => {
    if (!periodGuard(formData.period)) {
      throw Error(
        `dashboard update received unexpected period type : ${formData.period}`,
      )
    } else if (formData.period === 'none' || formData.period === 'hourly') {
      throw Error(
        `${formData.period} is an invalid period option for the dashboard`,
      )
    }
    updateDashboard({
      variables: {
        data: {
          buildingId: id,
          period: formData.period,
        },
      },
      refetchQueries: [
        {
          query: GET_BUILDING,
          variables: {
            where: { slug },
          },
        },
        {
          query: GET_BUILDINGS,
          variables: { where: {} },
        },
      ],
      onCompleted: (response) => {
        setBuildingEditOpen(false)
        toastSuccess('Dashboard updated successfully')
      },
      onError: (err) => {
        toastFail(err.message)
      },
    })
  }

  const buildingEditProps = {
    page: 'buildingsEdit',
    data: {
      id,
      type, // What is this?
      name,
      floorArea,
      epcRating,
      epcUrl,
      slug,
      picture,
      projectRef,
      breeamScore,
      constructionYear,
      city: address?.city ?? '',
      country: address?.country ?? '',
      houseNameOrNumber: address?.houseNameOrNumber ?? '',
      postcode: address?.postcode ?? '',
      region: address?.region ?? '',
      street: address?.street ?? '',
    },
  }
  const [updateBuilding, { loading: buildingEditLoader }] =
    useBuildingUpdateMutation()
  const onBuildingEditSubmit = (formData: FormDataType) => {
    updateBuilding({
      variables: {
        data: {
          id,
          name: formData?.name,
          company: formData?.company,
          epcRating: formData?.epcRating,
          epcUrl: formData?.epcUrl,
          picture: formData?.picture,
          slug: formData?.slug,
          floorArea: formData?.floorArea ? +formData.floorArea : null,
          thumb: formData?.picture,
          breeamScore: formData?.breeamScore ? +formData.breeamScore : null,
          constructionYear: formData?.constructionYear
            ? +formData.constructionYear
            : null,
          address: {
            houseNameOrNumber: formData.houseNameOrNumber ?? '',
            street: formData.street ?? '',
            city: formData.city ?? '',
            region: formData.region ?? '',
            country: formData.country ?? '',
            postcode: formData.postcode ?? '',
          },
        } as BuildingUpdateInput,
      },

      refetchQueries: [
        {
          query: GET_BUILDING,
          variables: {
            where: { slug },
          },
        },
        {
          query: GET_BUILDINGS,
          variables: { where: {} },
        },
      ],
      onCompleted: (response) => {
        setBuildingEditOpen(false)
        if (buildingSlug !== response.buildingUpdate.slug) {
          navigate(`/buildings/${response.buildingUpdate.slug}`)
        }
        toastSuccess('Building updated successfully')
      },
      onError: (err) => {
        toastFail(err.message)
      },
    })
  }

  const [deleteBuilding] = useBuildingDeleteMutation({
    refetchQueries: [
      {
        query: GET_BUILDINGS,
        variables: {
          where: {},
        },
      },
    ],
    onCompleted: (response) => {
      toast({
        title: t('deleteOkResult'),
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: (err) => {
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })
  const onDelete = () => {
    deleteBuilding({ variables: { data: { id: id! } } })
    setDeleteOpen(false)
    window.location.href = `/buildings`
  }

  const hrefFunction = () => {
    window.location.href = `/buildings/${slug}/dashboard`
  }

  return (
    <Box
      py={{ base: 2, md: 6 }}
      px={{ base: '0.5rem', md: 6 }}
      background={'gray.300'}
      textColor={'purple.600'}
      h="100%"
    >
      <Box
        background={'white'}
        p={4}
        h="100%"
        display="flex"
        flexDirection="column"
        justifyContent={'space-between'}
      >
        <Heading as="h3" size="md" pb={6}>
          {t('buildingConfig')}
        </Heading>
        {havePermissions && (
          <Skeleton isLoaded={!loading}>
            <Stack direction="row" spacing={2} paddingTop={2}>
              <Button
                colorScheme={'purple.500'}
                variant={'outline'}
                width={'100%'}
                onClick={() => hrefFunction()}
                p={4}
              >
                {t('dashboard')}
              </Button>

              <Button
                colorScheme={'purple.500'}
                leftIcon={<SettingsIcon />}
                iconSpacing="0"
                variant={'outline'}
                // width={'100%'}
                onClick={() => setDashboardEditOpen(true)}
                p={2}
                fontSize="2xl"
              ></Button>
              <FormDrawer
                openedFrom={'Building'}
                isCreate={false}
                isOpen={isDashboardEditOpen}
                onClose={() => setDashboardEditOpen(false)}
                inputElements={mapKeyValue(dashboardEditProps)}
                onDrawerFormSubmit={onDashboardEditSubmit}
                loading={dashboardEditLoader}
              />
            </Stack>

            <Button
              colorScheme={'purple.500'}
              variant={'outline'}
              width={'100%'}
              onClick={openRelationships}
              p={4}
              mt={2}
            >
              {t('relationshipsEdit')}
            </Button>
            <BuildingCompanyModal
              isOpen={isRelationshipsOpen}
              onClose={closeRelationships}
              companyRelationships={companyRelationships}
              companyList={companyList!}
              buildingId={id!}
            />

            <Stack direction="row" spacing={2} paddingTop={2}>
              <Button
                variant={'outline'}
                colorScheme={'purple.500'}
                width={'100%'}
                onClick={() => setBuildingEditOpen(true)}
                p={'4px'}
                fontSize={'15px'}
              >
                {t('editBuilding')}
              </Button>
              <FormDrawer
                openedFrom={'Building'}
                isCreate={false}
                isOpen={isBuildingEditOpen}
                onClose={() => setBuildingEditOpen(false)}
                inputElements={mapKeyValue(buildingEditProps)}
                onDrawerFormSubmit={onBuildingEditSubmit}
                loading={buildingEditLoader}
              />

              <Button
                colorScheme={'red'}
                ml={4}
                width={'100%'}
                variant={'outline'}
                onClick={() => setDeleteOpen(true)}
                padding={'4px'}
                fontSize={'15px'}
              >
                {t('deleteBuilding')}
              </Button>
              <InfoAlert
                isOpen={isDeleteOpen}
                onClose={() => setDeleteOpen(false)}
                onSubmit={onDelete}
                headerText={`${t('deleteText')} ?`}
                bodyText={t('deleteConfirmMsg')}
                buttonText={t('deleteText')}
              />
            </Stack>
          </Skeleton>
        )}
      </Box>
    </Box>
  )
}
