import {
  ChannelSearchManyQuery,
  CompaniesQuery,
  CompanyRelationship,
  UserGetManyQuery,
} from '../../graphql/generated/graphql'
import { AlertTargetCombined } from '../../Pages/alerts/alerts'

// Restructure the data according to the table

export type TableDataConstructType = {
  page: string
  data:
    | ChannelSearchManyQuery['channelSearchMany']
    | UserGetManyQuery['userGetMany']
    | CompaniesQuery['companies']
    | AlertTargetCombined[]
    | CompanyRelationship[]
    | undefined
}

export function tableDataConstruct(
  modParams: TableDataConstructType,
): TableDataConstructType['data'] {
  const { data, page } = modParams
  if (!data) {
    return []
  }
  if (page === 'users') {
    let userData = data as UserGetManyQuery['userGetMany']
    return userData?.map((datum) => {
      return {
        id: datum?.id,
        picture: datum?.picture,
        firstName: datum?.firstName,
        lastName: datum?.lastName,
        email: datum?.email,
        role: datum?.role,
        companyId: datum?.companyId,
        company: datum?.companyId,
        slug: datum?.slug,
      }
    })
  } else if (page === 'companies') {
    return data as CompaniesQuery['companies']
  } else if (page === 'channels' && data) {
    let channelList = data as ChannelSearchManyQuery['channelSearchMany']
    return channelList
      ?.map((datum) => {
        return {
          id: datum?.id,
          name: datum?.name,
          measurementType: datum?.measurementType,
          // period: datum.period,
          reference: datum?.reference,
          buildingId: datum?.buildingId,
          channelType: datum?.channelType,
        }
      })
      .sort((a, b) => {
        const nameA = a.name?.toUpperCase()
        const nameB = b.name?.toUpperCase()

        if (a.channelType !== b.channelType) {
          return a.channelType !== 'virtual' ? -1 : 1
        }

        return nameA!.localeCompare(nameB!)
      })
      .filter((datum) => datum.channelType !== 'shadow')
  } else if (page === 'targets') {
    let targetData = data as AlertTargetCombined[]
    return targetData.map((datum) => {
      return {
        id: datum?.id,
        period: datum?.period,
        triggerCondition: datum?.triggerCondition,
        targetValue: datum?.targetValue,
        severity: datum?.severity,
        alertId: datum?.alertId,
        name: datum?.name,
      }
    }) as AlertTargetCombined[]
  }
}

// Set the order of the table headers here
export const tableHeaders: { [key: string]: string[] } = {
  usersEdit: ['picture', 'firstName', 'lastName', 'email', 'role', 'company'],
  'usersEdit-sm': ['lastName'],
  'usersEdit-md': ['picture', 'firstName', 'email', 'role'],
  companiesEdit: ['portfolioImage', 'name', 'logo'],
  'companiesEdit-sm': ['name'],
  'companiesEdit-md': ['portfolioImage', 'name', 'logo'],
  channels: ['name', 'measurementType', 'reference', 'channelType'],
  'channels-sm': ['name'],
  'channels-md': ['name', 'channelType'],
  targets: ['name', 'period', 'triggerCondition', 'targetValue', 'severity'],
  companyRelation: [
    'companyId',
    'buildingName',
    'relationshipType',
    'companyReferenceName',
    'companyReference',
  ],
}
