/* eslint-disable @typescript-eslint/no-use-before-define */
import { useRef, useState, useMemo, useEffect } from 'react'
import { scaleTime, scaleLinear } from '@visx/scale'
import { Brush } from '@visx/brush'
import { Bounds } from '@visx/brush/lib/types'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import { PatternLines } from '@visx/pattern'
import { Group } from '@visx/group'
import { max, min } from 'd3-array'
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle'
import AreaChart from './areaChart'
import { Box } from '@chakra-ui/react'
import { DataGetManyQuery, Datum } from '../../graphql/generated/graphql'
import { graphColors } from '../../utils/colorMaps/tightMap'
import {
  filterDataByTimestamp,
  flattenGraphData,
  minMaxTimeStamp,
} from '../../utils/basicFunc/basicFunc'

// Initialize some variables
const brushMargin = { top: 10, bottom: 15, left: 20, right: 0 }
const chartSeparation = 30
const PATTERN_ID = 'brush_pattern'
const GRADIENT_ID = 'brush_gradient'
export const accentColor = '#77777745'
export const background = '#3C1D87'
export const background2 = '#3C1D87'
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: 'white',
}
// accessors
// const getDate = (d: Partial<Datum>) => new Date(d?.timestamp!)
const getValue = (d: Partial<Datum>) => d?.value!

export type BrushProps = {
  width: number
  height: number
  margin?: { top: number; right: number; bottom: number; left: number }
  compact?: boolean
  channelGraphData?: DataGetManyQuery
  selectedChannelData: DataGetManyQuery[]
}

function BrushChart({
  compact = false,
  width,
  height,
  margin = {
    top: 20,
    left: 20,
    bottom: 20,
    right: 0,
  },
  // channelGraphData,
  selectedChannelData,
}: BrushProps) {
  const brushRef = useRef<BaseBrush | null>(null)
  const [filteredValues, setFilteredValues] =
    useState<DataGetManyQuery[]>(selectedChannelData)
  const { minTimestamp, maxTimestamp } = minMaxTimeStamp(selectedChannelData)
  const [dateRange, setDateRange] = useState<Date[]>([
    minTimestamp,
    maxTimestamp,
  ])
  const brushDateRange = useMemo(
    () => [minTimestamp, maxTimestamp],
    [minTimestamp, maxTimestamp],
  )
  // useMemo(() => [minTimestamp, maxTimestamp], [minTimestamp, maxTimestamp])
  const flattenedGraphData = flattenGraphData(selectedChannelData)

  useEffect(() => {
    setDateRange([minTimestamp, maxTimestamp])
    if (brushRef?.current) {
      const updater = (prevBrush: any) => {
        // NOT SUPPORTED BY THE PLUGIN SO HAVE TO USE ANY
        const newExtent = brushRef.current!.getExtent(
          {
            x: brushDateScale(
              minMaxTimeStamp(selectedChannelData).minTimestamp,
            ),
          },
          {
            x: brushDateScale(
              minMaxTimeStamp(selectedChannelData).maxTimestamp,
            ),
          },
        )

        const newState: any = {
          // NOT SUPPORTED BY THE PLUGIN SO HAVE TO USE ANY
          ...prevBrush,
          start: { y: newExtent.y0, x: newExtent.x0 },
          end: { y: newExtent.y1, x: newExtent.x1 },
          extent: newExtent,
        }

        return newState
      }
      brushRef.current.updateBrush(updater)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannelData])

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return
    const { x0, x1 } = domain
    const filteredData = filterDataByTimestamp({
      selectedChannelData,
      startDate: x0,
      endDate: x1,
    })
    setDateRange([new Date(x0), new Date(x1)])
    setFilteredValues(filteredData)
  }

  const innerHeight = height - margin.top - margin.bottom
  const topChartBottomMargin = compact
    ? chartSeparation / 2
    : chartSeparation + 10
  const topChartHeight = 0.8 * innerHeight - topChartBottomMargin
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0)
  const yMax = Math.max(topChartHeight, 0)
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0)
  const yBrushMax = Math.max(
    bottomChartHeight - brushMargin.top - brushMargin.bottom,
    0,
  )

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xMax],
        domain: dateRange,
      }),
    [xMax, dateRange],
  )
  const valueScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [
          min(flattenGraphData(filteredValues) ?? [], getValue)!,
          max(flattenGraphData(filteredValues) ?? [], getValue)!,
        ],
        nice: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [yMax, flattenedGraphData],
  )
  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: brushDateRange,
      }),
    [xBrushMax, brushDateRange],
  )

  const brushValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [
          min(flattenedGraphData ?? [], getValue)!,
          max(flattenedGraphData ?? [], getValue)!,
        ],
        nice: true,
      }),
    [yBrushMax, flattenedGraphData],
  )

  const initialBrushPosition = useMemo(
    () => ({
      start: {
        x: brushDateScale(dateRange[0]),
      },
      end: {
        x: brushDateScale(dateRange[1]),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brushDateScale],
  )

  // event handlers
  // const handleClearClick = () => {
  //   if (brushRef?.current) {
  //     // setFilteredValues(details)
  //     brushRef.current.reset()
  //   }
  // }

  // const handleResetClick = () => {
  //   if (brushRef?.current) {
  //     const updater: UpdateBrush = (prevBrush) => {
  //       const newExtent = brushRef.current!.getExtent(
  //         initialBrushPosition.start,
  //         initialBrushPosition.end,
  //       )

  //       const newState: BaseBrushState = {
  //         ...prevBrush,
  //         start: { y: newExtent.y0, x: newExtent.x0 },
  //         end: { y: newExtent.y1, x: newExtent.x1 },
  //         extent: newExtent,
  //       }

  //       return newState
  //     }
  //     brushRef.current.updateBrush(updater)
  //   }
  // }

  return (
    <Box display={'flex'}>
      <Box>
        <div>
          <svg
            width={width}
            height={height}
            overflow={'visible'}
            fill={'white'}
          >
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              fill={`url(#${GRADIENT_ID})`}
              rx={14}
            />
            {filteredValues.map((data, i) => (
              <AreaChart
                key={i}
                hideLeftAxis={false}
                hideBottomAxis={compact}
                data={data}
                width={width}
                margin={{ ...margin, bottom: topChartBottomMargin }}
                yMax={yMax}
                xMax={xMax}
                xScale={dateScale}
                yScale={valueScale}
                isBrush={false}
                gradientColor={background2}
                graphColor={graphColors[i]}
              />
            ))}

            <AreaChart
              hideLeftAxis
              hideBottomAxis={false}
              // data={selectedChannelData[0]}
              selectedChannelData={selectedChannelData}
              isBrush={true}
              width={width}
              yMax={yBrushMax}
              xMax={xMax}
              xScale={brushDateScale}
              yScale={brushValueScale}
              margin={brushMargin}
              top={topChartHeight + topChartBottomMargin + margin.top}
              gradientColor={background2}
            >
              <PatternLines
                id={PATTERN_ID}
                height={8}
                width={8}
                stroke={accentColor}
                strokeWidth={2}
                orientation={['diagonal']}
              />
              <Brush
                xScale={brushDateScale}
                yScale={brushValueScale}
                width={xBrushMax}
                height={yBrushMax}
                margin={brushMargin}
                handleSize={8}
                innerRef={brushRef}
                resizeTriggerAreas={['left', 'right']}
                brushDirection="horizontal"
                initialBrushPosition={initialBrushPosition}
                onChange={onBrushChange}
                // onClick={() => setFilteredValues(details)}
                selectedBoxStyle={selectedBrushStyle}
                useWindowMoveEvents
                renderBrushHandle={(props) => <BrushHandle {...props} />}
              />
            </AreaChart>
          </svg>
          {/* <Button m={'30px 0'} onClick={handleClearClick}>
        Clear
      </Button> */}
          {/* &nbsp; */}
          {/* <button onClick={handleResetClick}>Reset</button> */}
        </div>
      </Box>
    </Box>
  )
}

// We need to manually offset the handles for them to be rendered at the right position
function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
  const pathWidth = 8
  const pathHeight = 20
  if (!isBrushActive) {
    return null
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: 'ew-resize' }}
      />
    </Group>
  )
}

export default BrushChart
