import {
  Button,
  Center,
  GridItem,
  Heading,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import FormDrawer, {
  FormDataType,
} from '../../components/formDrawer/formDrawer'
import ListTable from '../../components/listTable/listTable'
import { GET_USERS } from '../../graphql/API/queries'
import {
  Company,
  useCompaniesQuery,
  UserCreateInput,
  useUserCreateMutation,
  useUserGetManyQuery,
} from '../../graphql/generated/graphql'
import { useAuth } from '../../hooks/use-auth'
import { isCurrentUserStaff } from '../../utils/basicFunc/basicFunc'
import { allDrawerInputs } from '../../utils/pageDrawerInputLists/inputLists'
import { tableDataConstruct } from '../../utils/tableDataConstruct/tableDataConstruct'

const breadcrumbLinks = [
  { text: 'Home', route: '/' },
  { text: 'Users', route: '/users' },
]

const Users = () => {
  const [isOpen, setDrawerVisible] = useState(false)
  const [buttonLoading, setButtonLoader] = useState(false)
  const Auth = useAuth(),
    { user } = Auth,
    { isStaff } = user,
    havePermissions = isStaff ?? isCurrentUserStaff()
  const {
    data: userData,
    // loading,
    // error,
  } = useUserGetManyQuery({
    variables: { where: {} },
  })
  const { data: companyData } = useCompaniesQuery({
    variables: { where: {} },
  })
  const toast = useToast()
  const companySelectOptions = companyData?.companies.map(
    (company: Partial<Company>) => {
      return { value: company.id!, text: company.name! }
    },
  )
  const [createUser] = useUserCreateMutation({
    refetchQueries: [
      {
        query: GET_USERS,
        variables: { where: {} },
      },
    ],
    onCompleted: (response) => {
      setButtonLoader(false)
      setDrawerVisible(false)
      toast({
        title: 'User created succesfully',
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
    onError(error) {
      setButtonLoader(false)
      setDrawerVisible(false)
      toast({
        title: error.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  const onClose = () => {
    setDrawerVisible(false)
  }

  const onDrawerFormSubmit = (formData: FormDataType) => {
    setButtonLoader(true)
    createUser({
      variables: { data: formData as UserCreateInput },
    })
  }

  const constructedData = tableDataConstruct({
    data: userData && userData.userGetMany,
    page: 'users',
  })

  return (
    <SimpleGrid columns={12} padding={{ base: '0 15px', md: 0 }}>
      <GridItem
        colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
        colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
      >
        <Breadcrumbs links={breadcrumbLinks} />
        <Center justifyContent={'space-between'} mt={'1%'}>
          <Heading fontSize={{ base: '24px', md: '28px', lg: '30px' }}>
            List of Users
          </Heading>
          {havePermissions && (
            <Button onClick={() => setDrawerVisible(true)}>+ Add User</Button>
          )}
        </Center>
        <ListTable
          constructedData={constructedData}
          page={'usersEdit'}
          formDrawerProps={{ selectOptions: companySelectOptions }}
        />
        <FormDrawer
          openedFrom={'User'}
          isCreate={true}
          isOpen={isOpen}
          loading={buttonLoading}
          onClose={onClose}
          inputElements={allDrawerInputs['users']}
          onDrawerFormSubmit={onDrawerFormSubmit}
          selectOptions={companySelectOptions}
        />
      </GridItem>
    </SimpleGrid>
  )
}

export default Users
