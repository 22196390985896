import { Search2Icon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Heading,
  Hide,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ChannelSearchManyQuery } from '../../graphql/generated/graphql'
import { tableBreak } from '../../styles/buildingsVariables'

export const ChannelListTable = ({
  onChannelSelect,
  channelListData,
  selectedChannels,
}: {
  onChannelSelect: (arg1: string) => void
  channelListData: ChannelSearchManyQuery['channelSearchMany']
  selectedChannels: string[]
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredChannels, setFilteredChannels] =
    useState<ChannelSearchManyQuery['channelSearchMany']>(channelListData)

  useEffect(() => {
    setFilteredChannels(channelListData)
  }, [channelListData])

  useEffect(() => {
    if (searchValue) {
      setFilteredChannels(
        channelListData.filter((channel) =>
          channel?.name?.toLowerCase().includes(searchValue),
        ),
      )
    } else {
      setFilteredChannels(channelListData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  return (
    <Box mt={4}>
      <Center justifyContent={'space-between'} mb={4}>
        <Heading fontSize={{ lg: 36, md: 28, base: 20 }} flexGrow={1}>
          All Channels
        </Heading>
        <InputGroup flexBasis={{ base: '50%', md: '35%', xl: '25%' }}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search channels... "
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </InputGroup>
      </Center>
      <TableContainer bg={'white'} maxH={350} overflowY={'auto'}>
        {filteredChannels.length ? (
          <Table variant="simple">
            <TableCaption>Upto five channels can be selected</TableCaption>
            <Thead>
              <Tr>
                <Th pl={0}>Channel Name</Th>
                <Hide breakpoint={tableBreak.medium}>
                  <Th pl={0}>Channel Type</Th>
                </Hide>
                <Hide breakpoint={tableBreak.small}>
                  <Th pl={0}>Measurement Type</Th>
                </Hide>
                <Th pl={0}>Action</Th>
              </Tr>
            </Thead>

            <Tbody>
              {filteredChannels.map((channel, i) => (
                <Tr key={i}>
                  <Td pl={0}>{channel?.name}</Td>
                  <Hide breakpoint={tableBreak.medium}>
                    <Td pl={0} textTransform={'capitalize'}>
                      {channel?.channelType}
                    </Td>
                  </Hide>
                  <Hide breakpoint={tableBreak.small}>
                    <Td pl={0} textTransform={'capitalize'}>
                      {channel?.measurementType}
                    </Td>
                  </Hide>
                  <Td pl={0}>
                    <Button
                      colorScheme={'blue'}
                      onClick={() => onChannelSelect(channel?.id ?? '')}
                      variant={
                        selectedChannels.includes(channel?.id ?? '')
                          ? 'outline'
                          : 'solid'
                      }
                    >
                      {selectedChannels.includes(channel?.id ?? '')
                        ? 'Hide'
                        : 'View'}
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th pl={0}>Channel Name</Th>
                <Hide breakpoint={tableBreak.medium}>
                  <Th pl={0}>Channel Type</Th>
                </Hide>
                <Hide breakpoint={tableBreak.small}>
                  <Th pl={0}>Measurement Type</Th>
                </Hide>
                <Th pl={0}>Action</Th>
              </Tr>
            </Tfoot>
          </Table>
        ) : (
          <Center w={'100%'} minH={200}>
            <Heading>No Data</Heading>
          </Center>
        )}
      </TableContainer>
    </Box>
  )
}

export default ChannelListTable
