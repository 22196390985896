import {
  ComponentWithAs,
  Grid,
  GridItem,
  IconProps,
  SimpleGrid,
  Box,
  Heading,
  Skeleton,
  Center,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import PfHeadline, {
  PfHeadlineItemProps,
} from '../../components/pfHeadline/pfHeadline'
import BuildingConfiguration, {
  periodGuard,
} from '../../elements/building/buildingConfiguration'
import EnergyDist from '../../elements/building/energyDist'
import EnergyUse from '../../elements/building/energyUse'
import BuildingInformation from '../../elements/building/buildingInformation'
import Picture from '../../elements/building/picture'
import DetailedData from '../../elements/building/detailedData'
import CO2 from '../../elements/icons/co2'
import Renewables from '../../elements/icons/renewables'
import WaterUsage from '../../elements/icons/waterUsage'
import WholeBuilding from '../../elements/icons/wholeBuilding'
import DefaultImage from '../../assets/Default-image.png'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import { isValidImageUrl } from '../../utils/basicFunc/basicFunc'
import { useEffect, useState } from 'react'
import Maps from '../../components/maps/maps'
import {
  BuildingGetOneQuery,
  PortfolioTarget,
  useBuildingGetOneQuery,
} from '../../graphql/generated/graphql'
import { useAuth } from '../../hooks/use-auth'

export type BuildingProps = {
  loading: boolean
  buildingDTO?: BuildingGetOneQuery['buildingGetOne']
  buildingName?: string
  userCompanyId?: string
  buildingSlug?: string
}

export const Building = ({
  loading,
  buildingDTO,
  buildingName,
  userCompanyId,
  buildingSlug,
}: BuildingProps) => {
  const [isValidImage, setIsValidImage] = useState(false)
  const [heroElement, setHeroElement] = useState<JSX.Element>()

  useEffect(() => {
    const checkImageUrl = async () => {
      try {
        const result = await isValidImageUrl(buildingDTO?.picture ?? '')
        setIsValidImage(result)
      } catch (error) {
        console.error('Error checking image URL:', error)
        setIsValidImage(false) // Handle the error case
      }
    }

    if (buildingDTO?.picture) {
      checkImageUrl()
    } else {
      setIsValidImage(false) // Handle the case where buildingPicURL is undefined
    }
  }, [buildingDTO?.picture])

  useEffect(() => {
    if (!loading) {
      if (buildingDTO?.address?.postcode) {
        setHeroElement(
          <Box height={'100%'}>
            <Maps
              mapBuildingsData={[
                {
                  name: buildingName || '',
                  id: buildingDTO?.id || '',
                  address: buildingDTO?.address,
                },
              ]}
              height={{ base: 350 }}
            />
          </Box>,
        )
      } else if (isValidImage) {
        setHeroElement(
          <Picture
            src={buildingDTO?.picture || DefaultImage}
            alt={buildingName || ''}
            h={!buildingDTO?.picture ? '350' : ''}
          />,
        )
      } else {
        setHeroElement(
          <Picture
            src={DefaultImage}
            alt={buildingName || ''}
            h={!buildingDTO?.picture ? '350' : ''}
          />,
        )
      }
    }
  }, [
    isValidImage,
    loading,
    buildingDTO?.picture,
    buildingName,
    buildingDTO?.id,
    buildingDTO?.address,
  ])

  const headlineItems: PfHeadlineItemProps[] =
    loading || !buildingDTO?.portfolio
      ? []
      : Object.entries(buildingDTO?.portfolio).map(
          ([type, item]: [string, PortfolioTarget]) => {
            const specs: {
              Icon: ComponentWithAs<'svg', IconProps>
              label: string
              units: string
              description: string
              upIsBetter: boolean
            } =
              type === 'wholeBuilding'
                ? {
                    Icon: WholeBuilding,
                    label: 'buildingEnergy',
                    units: 'kWh',
                    description: 'totalEnergyUsed',
                    upIsBetter: false,
                  }
                : type === 'carbonDioxide'
                ? {
                    Icon: CO2,
                    label: 'carbonDioxide',
                    units: 'tCO<sub>2</sub>',
                    description: 'totalEmissions',
                    upIsBetter: false,
                  }
                : type === 'waterUsage'
                ? {
                    Icon: WaterUsage,
                    label: 'water',
                    units: 'm<sup>3</sup>',
                    description: 'totalWaterUsed',
                    upIsBetter: false,
                  }
                : type === 'renewables'
                ? {
                    Icon: Renewables,
                    label: 'renewables',
                    units: 'kWh',
                    description: 'totalGenerated',
                    upIsBetter: true,
                  }
                : {
                    Icon: WholeBuilding,
                    label: 'Unknown',
                    units: '',
                    description: 'unknown total',
                    upIsBetter: false,
                  }
            return {
              ...specs,
              type: type.charAt(0).toUpperCase() + type.slice(1),
              value: item?.measurement,
              target: item?.target,
            }
          },
        )

  return (
    <>
      <Center>
        <Skeleton isLoaded={!loading}>
          <Heading color={'purple.500'} fontSize={{ base: '4xl', md: '5xl' }}>
            {buildingName}
          </Heading>
        </Skeleton>
      </Center>
      <Grid
        templateAreas={{
          base: ` "picture"
                  "info"
                  "head"
                  "head"
                  "detailedData"
                  "buildingConfig"
                  "energyUse"
                  "energyDist"
                  "empty1"
                  "empty2"`,
          md: ` "info picture"
                " head head "
                "energyUse energyDist"
                "detailedData buildingConfig"
                 `,
        }}
        gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
        gridTemplateRows={'auto'}
        paddingTop={3}
      >
        <GridItem area="info" textAlign={'left'}>
          <BuildingInformation
            loading={loading}
            epcRating={`${buildingDTO?.epcRating}`}
            floorArea={buildingDTO?.floorArea}
            epcUrl={buildingDTO?.epcUrl ?? undefined}
            breeamScore={buildingDTO?.breeamScore ?? undefined}
            breeamRating={buildingDTO?.breeamRating ?? undefined}
            constructionYear={buildingDTO?.constructionYear ?? undefined}
            address={buildingDTO?.address}
          />
        </GridItem>
        <GridItem area="picture">{heroElement}</GridItem>
        <GridItem area="head">
          <PfHeadline
            items={headlineItems}
            period={buildingDTO?.dashboardPeriod}
            loading={loading}
          />
        </GridItem>
        <GridItem area="energyUse">
          <EnergyUse buildingDTO={buildingDTO} loading={loading} />
        </GridItem>
        <GridItem area="energyDist">
          <EnergyDist buildingDTO={buildingDTO} loading={loading} />
        </GridItem>
        <GridItem area="detailedData">
          <DetailedData
            buildingId={buildingDTO?.id}
            companyId={userCompanyId}
          />
        </GridItem>
        <GridItem area="buildingConfig">
          <BuildingConfiguration
            id={buildingDTO?.id ?? ''}
            buildingSlug={buildingSlug ?? ''}
            name={buildingName ?? ''}
            type={''}
            picture={buildingDTO?.picture ?? ''}
            companyRelationships={buildingDTO?.companyRelationships ?? []}
            projectRef={buildingDTO?.projectRef ?? ''}
            floorArea={buildingDTO?.floorArea ?? 0}
            epcRating={buildingDTO?.epcRating ?? ''}
            epcUrl={buildingDTO?.epcUrl ?? ''}
            loading={loading}
            address={buildingDTO?.address!}
            breeamScore={buildingDTO?.breeamScore!}
            constructionYear={buildingDTO?.constructionYear!}
            dashboardPeriod={
              periodGuard(buildingDTO?.dashboardPeriod)
                ? buildingDTO?.dashboardPeriod
                : undefined
            }
          />
        </GridItem>
      </Grid>
    </>
  )
}

const WrappedBuilding = () => {
  const auth = useAuth()
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown building'
  const {
    data,
    loading: QueryLoading,
    //error,
  } = useBuildingGetOneQuery({ variables: { where: { slug: buildingSlug } } })
  const breadcrumbLinks = [
    { text: 'Home', route: '/' },
    { text: 'Buildings', route: '/buildings' },
    { text: buildingSlug, route: `/buildings/${buildingSlug}` },
  ]
  const loading = QueryLoading
  const companyRelationship = data?.buildingGetOne?.companyRelationships.find(
    (relationship) => relationship.companyId === auth.user.companyId,
  )
  const buildingName = companyRelationship
    ? companyRelationship.buildingName
    : data?.buildingGetOne?.name

  return (
    <SimpleGrid columns={12}>
      <GridItem
        colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
        colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
        padding={'5px'}
      >
        <Breadcrumbs links={breadcrumbLinks} />

        <Building
          loading={loading}
          buildingDTO={data?.buildingGetOne}
          buildingName={buildingName}
          userCompanyId={companyRelationship?.companyId}
          buildingSlug={data?.buildingGetOne?.slug ?? buildingSlug}
        />
      </GridItem>
    </SimpleGrid>
  )
}

export default WrappedBuilding
