import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { createContext, ReactNode, useContext, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import {
  AUTH_TOKEN,
  USER_AVATAR,
  USER_COMPANY_ID,
  USER_FIRST_NAME,
  USER_ID,
  USER_IS_ADMIN,
  USER_IS_STAFF,
  USER_LAST_NAME,
  USER_SLUG,
} from '../constants'
import { SIGN_IN, SIGN_OUT } from '../graphql/API/mutations'
import { ME_QUERY } from '../graphql/API/queries'
import { SignInMutation } from '../graphql/generated/graphql'

type AuthLib = {
  signIn: (
    email: string,
    password: string,
    callback: VoidFunction,
  ) => Promise<SignInMutation | undefined>
  signOut: (callback: VoidFunction) => void
  token: string | null
  message: string
  loading: boolean
  user: {
    id: string
    firstName: string
    lastName: string
    slug: string
    avatar: string
    isStaff: boolean
    isAdmin: boolean
    companyId: string
  }
}

const AuthContext = createContext<AuthLib>({
  signIn: async (
    _email: string,
    _password: string,
    _callback: VoidFunction,
  ): Promise<SignInMutation | undefined> => {
    return
  },
  signOut: (_callback: VoidFunction) => {},
  token: null,
  message: '',
  loading: false,
  user: {
    id: '',
    firstName: '',
    lastName: '',
    slug: '',
    avatar: '',
    isStaff: false,
    isAdmin: false,
    companyId: '',
  },
})

type ProvideAuthProps = {
  children: ReactNode
}

export function ProvideAuth({ children }: ProvideAuthProps) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function RequireAuth({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const auth = useProvideAuth()
  const location = useLocation()

  if (auth.token === null) {
    // Redirect to login page, but save target location so we
    // can return them the correct place after login
    return <Navigate to="/signin" state={{ from: location }} replace />
  }

  return children
}

export function useAuth() {
  return useContext(AuthContext)
}

function useProvideAuth(): AuthLib {
  const toast = useToast()
  const [authToken, setAuthToken] = useState(
    localStorage.getItem(AUTH_TOKEN) || null,
  )
  const [message, setMessage] = useState('')
  const [userId, setUserId] = useState(localStorage.getItem(USER_ID) || '')
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(USER_FIRST_NAME) || '',
  )
  const [userLastName, setUserLastName] = useState(
    localStorage.getItem(USER_LAST_NAME) || '',
  )
  const [userIsAdmin, setUserIsAdmin] = useState(
    localStorage.getItem(USER_IS_ADMIN) === 'TRUE' ? true : false,
  )
  const [userIsStaff, setUserIsStaff] = useState(
    localStorage.getItem(USER_IS_STAFF) === 'TRUE' ? true : false,
  )
  const [userSlug, setUserSlug] = useState(
    localStorage.getItem(USER_SLUG) || '',
  )

  const [userAvatar, setUserAvatar] = useState(
    localStorage.getItem(USER_AVATAR) || '',
  )

  const [userCompanyId, setUserCompanyId] = useState(
    localStorage.getItem(USER_COMPANY_ID) || '',
  )

  useQuery(ME_QUERY, {
    pollInterval: 60000 * 5,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      // We've called the query, it shouldn't error, but will
      // let us know if the auth-token as expired
      if (data.me.token) {
        localStorage.setItem(AUTH_TOKEN, data.me.token)
      } else {
        signout(() => {})
      }
    },
  })

  const [signIn, { loading: signInLoading }] = useMutation(SIGN_IN)

  const [signOut, { loading: signOutLoading }] = useMutation(SIGN_OUT, {
    onCompleted({ signOut }) {
      setMessage(signOut.message)
    },
    onError(error) {
      toast({
        title: error.message,
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const apolloClient = useApolloClient()

  const signin = async (
    email: string,
    password: string,
    callback: VoidFunction,
  ): Promise<SignInMutation | undefined> => {
    try {
      const response = await signIn({
        variables: { email, password },
        onCompleted({ signIn }) {
          // Check we signed in successfully
          if (!signIn.token) {
            return
          }

          // Sign the user in
          localStorage.setItem(AUTH_TOKEN, signIn.token)
          localStorage.setItem(USER_ID, signIn.user?.id.toString()!)
          localStorage.setItem(USER_FIRST_NAME, signIn.user?.firstName!)
          localStorage.setItem(USER_LAST_NAME, signIn.user?.lastName!)
          localStorage.setItem(
            USER_IS_ADMIN,
            signIn.user?.isAdmin ? 'TRUE' : 'FALSE',
          )
          localStorage.setItem(
            USER_IS_STAFF,
            signIn.user?.isStaff ? 'TRUE' : 'FALSE',
          )
          localStorage.setItem(USER_SLUG, signIn.user?.slug!)
          localStorage.setItem(USER_AVATAR, signIn.user?.picture!)
          localStorage.setItem(USER_COMPANY_ID, signIn.user?.companyId!)
          setAuthToken(signIn.token)
          setMessage(signIn.message)
          setUserId(signIn.user?.id.toString()!)
          setUserFirstName(signIn.user?.firstName!)
          setUserLastName(signIn.user?.lastName!)
          setUserIsAdmin(signIn.user?.isAdmin!)
          setUserIsStaff(signIn.user?.isStaff!)
          setUserSlug(signIn.user?.slug!)
          setUserAvatar(signIn.user?.picture!)
          setUserCompanyId(signIn.user?.companyId!)
          callback()
        },
        onError(error) {
          toast({
            title: error.message,
            status: 'error',
            position: 'top',
            duration: 5000,
            isClosable: true,
          })
        },
      })
      return { ...response?.data! }
    } catch (error) {
      console.log(error)
    }
  }

  const signout = async (callback: VoidFunction): Promise<void> => {
    try {
      //console.log(`Siging Out`)
      await signOut()
      await apolloClient.clearStore()
      localStorage.removeItem(AUTH_TOKEN)
      localStorage.removeItem(USER_ID)
      localStorage.removeItem(USER_FIRST_NAME)
      localStorage.removeItem(USER_LAST_NAME)
      localStorage.removeItem(USER_IS_ADMIN)
      localStorage.removeItem(USER_IS_STAFF)
      localStorage.removeItem(USER_SLUG)
      localStorage.removeItem(USER_COMPANY_ID)
      setAuthToken(null)
      setUserId('')
      setUserFirstName('')
      setUserLastName('')
      setUserIsAdmin(false)
      setUserIsStaff(false)
      setUserSlug('')
      //console.log(`Done signing out`)
    } finally {
      //console.log(`Calling back to redirect`)
      callback()
    }
  }

  const loading = signInLoading || signOutLoading

  return {
    signIn: signin,
    signOut: signout,
    token: authToken,
    message: message,
    loading,
    user: {
      id: userId,
      firstName: userFirstName,
      lastName: userLastName,
      avatar: userAvatar,
      slug: userSlug,
      isAdmin: userIsAdmin,
      isStaff: userIsStaff,
      companyId: userCompanyId,
    },
  }
}
