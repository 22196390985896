import { Search2Icon } from '@chakra-ui/icons'
import {
  Checkbox,
  CheckboxGroup,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { SelectOptionProps } from './formElements'

const CheckboxGroupSearch = ({
  onFormChange,
  value,
  checkboxOptions,
}: {
  onFormChange?: (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
      | string
      | (string | number)[],
    name?: string,
  ) => void
  value: (string | number)[]
  checkboxOptions: SelectOptionProps[]
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredChannels, setFilteredChannels] = useState(checkboxOptions)
  return (
    <>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Search2Icon />
        </InputLeftElement>
        <Input
          placeholder="Search..."
          size={'md'}
          mb={4}
          htmlSize={24}
          width="auto"
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setFilteredChannels(
              checkboxOptions.filter((channel) =>
                channel.text
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()),
              ),
            )
          }}
          value={searchTerm}
        />
      </InputGroup>

      <CheckboxGroup onChange={onFormChange} value={value}>
        <Stack spacing={[4]} direction={['column']}>
          {filteredChannels?.map((datum) => (
            <Checkbox
              key={datum?.value}
              value={datum?.value}
              isFocusable={false}
              borderColor={'black'}
            >
              {datum?.text}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </>
  )
}

export default CheckboxGroupSearch
