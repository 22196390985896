import { Skeleton, Td, Tr, Link, Hide, Text } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import PfTrafficLightBlock from '../../elements/pfTrafficLightBlock'
import { tableBreak } from '../../styles/buildingsVariables'

type BuildingRowProps = {
  locale: string
  name: string
  slug: string
  type: string
  floorArea: number
  floorAreaDisplay: number
  epcRating: string
  epcUrl: string
  thumbnail: string
  picture: string
  projectRef: string
  company: string
  id: string
  // useSimpleDash: boolean
  tlData: {
    type: string
    value: number
    target: number
    units: string
  }[]
  loading: boolean
}

const PfTableRow = ({
  locale,
  name,
  slug,
  floorAreaDisplay,
  epcRating,
  epcUrl,
  tlData,
  loading,
}: BuildingRowProps) => {
  return (
    <Tr>
      {/* Name */}
      <Td width={'100%'} whiteSpace={'pre-wrap'}>
        <Skeleton isLoaded={!loading}>
          <Link as={RouterLink} to={`${slug}`} textColor="linkColor">
            {name}
          </Link>
        </Skeleton>
      </Td>

      {/* Details */}
      <Hide breakpoint={tableBreak.large}>
        <Td width={'20%'} whiteSpace={'pre-wrap'}>
          <Skeleton isLoaded={!loading}>
            {floorAreaDisplay ? (
              floorAreaDisplay.toLocaleString(locale, {
                maximumSignificantDigits: 5,
              })
            ) : (
              <i>N/A</i>
            )}
          </Skeleton>
        </Td>
      </Hide>
      <Hide breakpoint={tableBreak.huge}>
        <Td width={'20%'} whiteSpace={'pre-wrap'}>
          <Skeleton isLoaded={!loading}>
            {(epcUrl && (
              <Link href={epcUrl} textColor="linkColor">
                {epcRating}
              </Link>
            )) || <Text>{epcRating}</Text>}
          </Skeleton>
        </Td>
      </Hide>

      {/* Targets */}
      <Hide breakpoint={tableBreak.tiny}>
        {tlData.slice(0, 1).map((tlDatum) => (
          <Td key={tlDatum.type} whiteSpace={'pre-wrap'}>
            {PfTrafficLightBlock({
              type: tlDatum.type,
              value: tlDatum.value,
              target: tlDatum.target,
              units: tlDatum.units,
              loading,
            })}
          </Td>
        ))}
      </Hide>
      <Hide breakpoint={tableBreak.small}>
        {tlData.slice(1, 2).map((tlDatum) => (
          <Td key={tlDatum.type} whiteSpace={'pre-wrap'}>
            {PfTrafficLightBlock({
              type: tlDatum.type,
              value: tlDatum.value,
              target: tlDatum.target,
              units: tlDatum.units,
              loading,
            })}
          </Td>
        ))}
      </Hide>
      <Hide breakpoint={tableBreak.medium}>
        {tlData.slice(2, 3).map((tlDatum) => (
          <Td key={tlDatum.type} whiteSpace={'pre-wrap'}>
            {PfTrafficLightBlock({
              type: tlDatum.type,
              value: tlDatum.value,
              target: tlDatum.target,
              units: tlDatum.units,
              loading,
            })}
          </Td>
        ))}
      </Hide>
    </Tr>
  )
}

export default PfTableRow
