import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
};

export type AddCompanyRelationshipInput = {
  buildingId: Scalars['String'];
  buildingName?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  companyReference?: InputMaybe<Scalars['String']>;
  companyReferenceName?: InputMaybe<Scalars['String']>;
  relationshipType: Scalars['String'];
};

export type Address = {
  buildingId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  houseNameOrNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  houseNameOrNumber?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  postcode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export type Alert = {
  createdAt?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type AlertCreateInput = {
  destination: Scalars['String'];
  messageType: Scalars['String'];
  severity: Scalars['String'];
  targetId: Scalars['String'];
};

export type AlertDeleteInput = {
  id: Scalars['String'];
};

export type AlertDeletePayload = {
  message: Scalars['String'];
};

export type AlertGetManyInput = {
  destination?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  messageType?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Scalars['String']>;
  targetId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type AlertGetOneInput = {
  destination?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  messageType?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Scalars['String']>;
  targetId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type AlertUpdateInput = {
  id: Scalars['String'];
  severity: Scalars['String'];
};

export type Building = {
  address: Address;
  breeamRating: Scalars['String'];
  breeamScore?: Maybe<Scalars['Float']>;
  companyRelationships: Array<CompanyRelationship>;
  constructionYear?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  dashboardPeriod: Scalars['String'];
  epcRating?: Maybe<Scalars['String']>;
  epcUrl?: Maybe<Scalars['String']>;
  floorArea: Scalars['Float'];
  hasDataServer: Scalars['Boolean'];
  hasFileServer: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  picture: Scalars['String'];
  portfolio: PortfolioTypes;
  projectRef: Scalars['String'];
  slug: Scalars['String'];
  thumb: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uploadConfig: Array<UploadConfig>;
};

export type BuildingCreateInput = {
  address: AddressInput;
  breeamScore?: InputMaybe<Scalars['Float']>;
  companyId: Scalars['String'];
  companyRelationshipType: Scalars['String'];
  constructionYear?: InputMaybe<Scalars['Int']>;
  dashboardStyle?: InputMaybe<Scalars['String']>;
  epcRating: Scalars['String'];
  epcUrl?: InputMaybe<Scalars['String']>;
  floorArea: Scalars['Float'];
  hasDataServer: Scalars['Boolean'];
  hasFileServer: Scalars['Boolean'];
  name: Scalars['String'];
  picture: Scalars['String'];
  projectRef: Scalars['String'];
  slug: Scalars['String'];
  thumb: Scalars['String'];
  uploadConfig: Array<UploadConfigInput>;
};

export type BuildingDeleteInput = {
  id: Scalars['String'];
};

export type BuildingDeletePayload = {
  message?: Maybe<Scalars['String']>;
};

export type BuildingUpdateInput = {
  address?: InputMaybe<AddressInput>;
  breeamScore?: InputMaybe<Scalars['Float']>;
  constructionYear?: InputMaybe<Scalars['Int']>;
  epcRating?: InputMaybe<Scalars['String']>;
  epcUrl?: InputMaybe<Scalars['String']>;
  floorArea?: InputMaybe<Scalars['Float']>;
  hasDataServer?: InputMaybe<Scalars['Boolean']>;
  hasFileServer?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  projectRef?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  thumb?: InputMaybe<Scalars['String']>;
  uploadConfig?: InputMaybe<Array<UploadConfigInput>>;
};

export type BuildingWhereUniqueInput = {
  dataPeriod?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type BuildingWhereUniqueSearchManyInput = {
  company?: InputMaybe<Scalars['String']>;
  dataPeriod?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type BuildingWhereUniqueSearchOneInput = {
  dataPeriod?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type ChangePasswordResponse = {
  message: Scalars['String'];
};

export type Channel = {
  buildingId?: Maybe<Scalars['String']>;
  channelType?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Channel>>;
  compositeChannelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  measurementType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadLabel?: Maybe<Scalars['String']>;
};

export type ChannelCreateInput = {
  buildingId: Scalars['String'];
  channelType?: InputMaybe<Scalars['String']>;
  measurementType: Scalars['String'];
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  period: Scalars['String'];
  reference?: InputMaybe<Scalars['String']>;
  uploadLabel?: InputMaybe<Scalars['String']>;
};

export type ChannelSearchManyInput = {
  buildingId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
};

export type ChannelSearchOneInput = {
  buildingId: Scalars['String'];
  uploadLabel: Scalars['String'];
};

export type ChannelUniqueIdInput = {
  channelId: Scalars['String'];
};

export type ChannelUpdateInput = {
  buildingId?: InputMaybe<Scalars['String']>;
  channelId: Scalars['String'];
  channelType?: InputMaybe<Scalars['String']>;
  children?: InputMaybe<Array<ChannelUpdateInput>>;
  measurementType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  uploadLabel?: InputMaybe<Scalars['String']>;
};

export type Company = {
  buildingRelationships: Array<CompanyRelationship>;
  createdAt: Scalars['DateTime'];
  fontColor?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  portfolioImage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userIds: Array<Scalars['String']>;
};

export type CompanyCreateInput = {
  fontColor?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  portfolioImage?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type CompanyDeleteInput = {
  id: Scalars['String'];
};

export type CompanyDeletePayload = {
  message: Scalars['String'];
};

export type CompanyRelationship = {
  buildingId: Scalars['String'];
  buildingName: Scalars['String'];
  companyId: Scalars['String'];
  companyReference: Scalars['String'];
  companyReferenceName: Scalars['String'];
  relationshipType: Scalars['String'];
};

export type CompanyRelationshipGetManyInput = {
  buildingId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
};

export type CompanyRelationshipGetOneInput = {
  buildingId: Scalars['String'];
  companyId: Scalars['String'];
};

export type CompanyUpdateInput = {
  fontColor?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  portfolioImage?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CompanyWhereSearch = {
  name?: InputMaybe<Scalars['String']>;
};

export type CompanyWhereUnique = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CompositeChannel = {
  buildingId?: Maybe<Scalars['String']>;
  channelList?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDashboardDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  shadowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompositeChannelCreateInput = {
  buildingId: Scalars['String'];
  name: Scalars['String'];
  period: Scalars['String'];
};

export type CompositeChannelGetManyInput = {
  buildingId?: InputMaybe<Scalars['String']>;
  channelId?: InputMaybe<Scalars['String']>;
};

export type CompositeChannelGetOneInput = {
  channelId: Scalars['String'];
};

export type CompositeChannelUpdateInput = {
  buildingId?: InputMaybe<Scalars['String']>;
  channelId: Scalars['String'];
  channelList?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
};

export type Dashboard = {
  address: Address;
  buildingId: Scalars['String'];
  buildingName: Scalars['String'];
  carbonDioxide: PortfolioTarget;
  coolingEnergy: PortfolioTarget;
  createdAt: Scalars['DateTime'];
  currentConsumption: Scalars['Float'];
  currentGeneration: Scalars['Float'];
  dashboardColors: DashboardColors;
  dashboardStyle: Scalars['String'];
  heatingEnergy: PortfolioTarget;
  id: Scalars['String'];
  infoMessage: Scalars['String'];
  lifetimeCarbonSavings: Scalars['Float'];
  lifetimeCarbonSavingsUnits: Scalars['String'];
  lightingEnergy: PortfolioTarget;
  occupierLogo: Scalars['String'];
  period: Scalars['String'];
  processEnergy: PortfolioTarget;
  renewables: PortfolioTarget;
  socketPower: PortfolioTarget;
  transportAPI: TransportApi;
  tryMessage: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  ventilation: PortfolioTarget;
  waterUsage: PortfolioTarget;
  weatherAPI: WeatherApi;
  wholeBuilding: PortfolioTarget;
};

export type DashboardColors = {
  fontColor: Scalars['String'];
  primaryColor: Scalars['String'];
  secondaryColor: Scalars['String'];
};

export type DashboardInput = {
  buildingId?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type DashboardUpdateInput = {
  buildingId: Scalars['String'];
  dashboardStyle?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
};

export type Data = {
  data: Array<Datum>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type DataInput = {
  chanId: Scalars['String'];
  endTime?: InputMaybe<Scalars['DateTime']>;
  maxCount?: InputMaybe<Scalars['Int']>;
  period: Scalars['String'];
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export type Datum = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datumId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type DatumCreateInput = {
  channelId: Scalars['String'];
  status: Scalars['String'];
  timestamp: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type DatumInput = {
  datumId: Scalars['String'];
};

export type DeletePayload = {
  message?: Maybe<Scalars['String']>;
};

export type EmailBuildingOverviewInput = {
  buildingId: Scalars['String'];
  companyId?: InputMaybe<Scalars['String']>;
};

export type EmailFullBuildingInput = {
  buildingId: Scalars['String'];
  companyId?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  period?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type FileOutputReturn = {
  result?: Maybe<Scalars['String']>;
};

export type GetAlertsByChannel = {
  benchmark?: Maybe<Scalars['Float']>;
  channelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  destination: Scalars['String'];
  id: Scalars['String'];
  isDashboardDefault: Scalars['Boolean'];
  lastComparisonTime: Scalars['DateTime'];
  lastComparisonValue: Scalars['Float'];
  messageType: Scalars['String'];
  name: Scalars['String'];
  nextExpectedTime: Scalars['DateTime'];
  period: Scalars['String'];
  severity: Scalars['String'];
  targetId: Scalars['String'];
  targetValue?: Maybe<Scalars['Float']>;
  triggerCondition: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type MeResponse = {
  token?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MixedChannel = {
  children?: Maybe<Array<Maybe<MixedChannel>>>;
  composite?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDashboardDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type MixedChannelInput = {
  children: Array<MixedChannelInput>;
  composite: Scalars['Boolean'];
  id: Scalars['String'];
  isDashboardDefault: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Mutation = {
  addCompanyRelationship?: Maybe<CompanyRelationship>;
  alertCreate: Alert;
  alertDelete: AlertDeletePayload;
  alertUpdate: Alert;
  buildingCreate: Building;
  buildingDelete?: Maybe<BuildingDeletePayload>;
  buildingUpdate: Building;
  changePassword: ChangePasswordResponse;
  channelCreate: Channel;
  channelDelete: DeletePayload;
  channelRelationshipTreeUpdate: Array<Maybe<Channel>>;
  channelUpdate: Channel;
  companyCreate: Company;
  companyDelete: CompanyDeletePayload;
  companyUpdate: Company;
  compositeChannelCreate: CompositeChannel;
  compositeChannelDelete: DeletePayload;
  compositeChannelRelationshipUpdate: Array<Maybe<MixedChannel>>;
  compositeChannelUpdate: CompositeChannel;
  dashboardUpdate: Dashboard;
  datumCreate: Array<Datum>;
  removeCompanyRelationship?: Maybe<DeletePayload>;
  resetPassword: ResetPasswordResponse;
  setPassword: SetPasswordResponse;
  signIn: SignInResponse;
  signOut: SignOutResponse;
  targetCreate: Target;
  targetDelete: DeletePayload;
  targetUpdate: Target;
  updateCompanyRelationship?: Maybe<CompanyRelationship>;
  updateStyles: Styles;
  userCreate: User;
  userDelete: UserDeletePayload;
  userUpdate: User;
};


export type MutationAddCompanyRelationshipArgs = {
  data: AddCompanyRelationshipInput;
};


export type MutationAlertCreateArgs = {
  where: AlertCreateInput;
};


export type MutationAlertDeleteArgs = {
  where: AlertDeleteInput;
};


export type MutationAlertUpdateArgs = {
  where: AlertUpdateInput;
};


export type MutationBuildingCreateArgs = {
  data: BuildingCreateInput;
};


export type MutationBuildingDeleteArgs = {
  data: BuildingDeleteInput;
};


export type MutationBuildingUpdateArgs = {
  data: BuildingUpdateInput;
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationChannelCreateArgs = {
  data: ChannelCreateInput;
};


export type MutationChannelDeleteArgs = {
  data: ChannelUniqueIdInput;
};


export type MutationChannelRelationshipTreeUpdateArgs = {
  data: Array<ChannelUpdateInput>;
};


export type MutationChannelUpdateArgs = {
  data: ChannelUpdateInput;
};


export type MutationCompanyCreateArgs = {
  data: CompanyCreateInput;
};


export type MutationCompanyDeleteArgs = {
  data: CompanyDeleteInput;
};


export type MutationCompanyUpdateArgs = {
  data: CompanyUpdateInput;
};


export type MutationCompositeChannelCreateArgs = {
  data: CompositeChannelCreateInput;
};


export type MutationCompositeChannelDeleteArgs = {
  data: ChannelUniqueIdInput;
};


export type MutationCompositeChannelRelationshipUpdateArgs = {
  data: Array<MixedChannelInput>;
};


export type MutationCompositeChannelUpdateArgs = {
  data: CompositeChannelUpdateInput;
};


export type MutationDashboardUpdateArgs = {
  data: DashboardUpdateInput;
};


export type MutationDatumCreateArgs = {
  data: DatumCreateInput;
};


export type MutationRemoveCompanyRelationshipArgs = {
  data: RemoveCompanyRelationshipInput;
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationTargetCreateArgs = {
  data: TargetCreateInput;
};


export type MutationTargetDeleteArgs = {
  data: TargetDeleteInput;
};


export type MutationTargetUpdateArgs = {
  data: TargetUpdateInput;
};


export type MutationUpdateCompanyRelationshipArgs = {
  data: UpdateCompanyRelationshipInput;
};


export type MutationUpdateStylesArgs = {
  data: StylesUpdateInput;
};


export type MutationUserCreateArgs = {
  data: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  data: UserDeleteInput;
};


export type MutationUserUpdateArgs = {
  data: UserChangeInput;
};

export type PortfolioTarget = {
  comment: Scalars['String'];
  comparison: Scalars['String'];
  measurement: Scalars['Float'];
  target: Scalars['Float'];
};

export type PortfolioTypes = {
  carbonDioxide: PortfolioTarget;
  coolingEnergy: PortfolioTarget;
  heatingEnergy: PortfolioTarget;
  lightingEnergy: PortfolioTarget;
  processEnergy: PortfolioTarget;
  renewables: PortfolioTarget;
  socketPower: PortfolioTarget;
  ventilation: PortfolioTarget;
  waterUsage: PortfolioTarget;
  wholeBuilding: PortfolioTarget;
};

export type Query = {
  alert: Alert;
  alerts: Array<Alert>;
  building: Building;
  buildingGetMany: Array<Maybe<Building>>;
  buildingGetOne?: Maybe<Building>;
  channelMeterRelationships: Array<Maybe<Channel>>;
  channelRetrieveUnique: Channel;
  channelSearchMany: Array<Maybe<Channel>>;
  channelSearchOne?: Maybe<Channel>;
  companies: Array<Company>;
  company: Company;
  companyRelationshipGetMany: Array<CompanyRelationship>;
  companyRelationshipGetOne: CompanyRelationship;
  compositeChannelRelationships: Array<MixedChannel>;
  dashboard: Dashboard;
  /** @deprecated use dataGetMany */
  data: Array<Datum>;
  dataGetMany: Data;
  datum: Datum;
  emailBuildingOverview: FileOutputReturn;
  emailFullBuilding: FileOutputReturn;
  getAlertsByChannel: Array<GetAlertsByChannel>;
  getManyCompositeChannel: Array<Maybe<CompositeChannel>>;
  getOneCompositeChannel: CompositeChannel;
  me: MeResponse;
  styles: Styles;
  target: Target;
  targets: Array<Target>;
  user: User;
  userGetMany: Array<Maybe<User>>;
  userGetOne: User;
};


export type QueryAlertArgs = {
  where: AlertGetOneInput;
};


export type QueryAlertsArgs = {
  where: AlertGetManyInput;
};


export type QueryBuildingArgs = {
  where: BuildingWhereUniqueInput;
};


export type QueryBuildingGetManyArgs = {
  where: BuildingWhereUniqueSearchManyInput;
};


export type QueryBuildingGetOneArgs = {
  where: BuildingWhereUniqueSearchOneInput;
};


export type QueryChannelMeterRelationshipsArgs = {
  where: SearchChannelByBuildingIdInput;
};


export type QueryChannelRetrieveUniqueArgs = {
  where: ChannelUniqueIdInput;
};


export type QueryChannelSearchManyArgs = {
  where: ChannelSearchManyInput;
};


export type QueryChannelSearchOneArgs = {
  where: ChannelSearchOneInput;
};


export type QueryCompaniesArgs = {
  where: CompanyWhereSearch;
};


export type QueryCompanyArgs = {
  where: CompanyWhereUnique;
};


export type QueryCompanyRelationshipGetManyArgs = {
  where: CompanyRelationshipGetManyInput;
};


export type QueryCompanyRelationshipGetOneArgs = {
  where: CompanyRelationshipGetOneInput;
};


export type QueryCompositeChannelRelationshipsArgs = {
  where: CompositeChannelGetManyInput;
};


export type QueryDashboardArgs = {
  where: DashboardInput;
};


export type QueryDataArgs = {
  where: DataInput;
};


export type QueryDataGetManyArgs = {
  where: DataInput;
};


export type QueryDatumArgs = {
  where: DatumInput;
};


export type QueryEmailBuildingOverviewArgs = {
  where: EmailBuildingOverviewInput;
};


export type QueryEmailFullBuildingArgs = {
  where: EmailFullBuildingInput;
};


export type QueryGetAlertsByChannelArgs = {
  channelId: Scalars['String'];
};


export type QueryGetManyCompositeChannelArgs = {
  where: CompositeChannelGetManyInput;
};


export type QueryGetOneCompositeChannelArgs = {
  where: CompositeChannelGetOneInput;
};


export type QueryStylesArgs = {
  where: StylesWhereUnique;
};


export type QueryTargetArgs = {
  where: TargetGetOneInput;
};


export type QueryTargetsArgs = {
  where: TargetGetManyInput;
};


export type QueryUserArgs = {
  where: UserGetOneInput;
};


export type QueryUserGetManyArgs = {
  where: UserGetManyInput;
};


export type QueryUserGetOneArgs = {
  where: UserGetOneInput;
};

export type RemoveCompanyRelationshipInput = {
  buildingId: Scalars['String'];
  companyId: Scalars['String'];
};

export type ResetPasswordResponse = {
  message: Scalars['String'];
};

export type SearchChannelByBuildingIdInput = {
  buildingId: Scalars['String'];
};

export type SetPasswordResponse = {
  message: Scalars['String'];
};

export type SignInResponse = {
  message: Scalars['String'];
  token: Scalars['String'];
  user?: Maybe<SignInUser>;
};

export type SignInUser = {
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customPermissions: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  lastName: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SignOutResponse = {
  message: Scalars['String'];
};

export type Styles = {
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fontColor?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  portfolioImage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type StylesCreateInput = {
  companyId?: InputMaybe<Scalars['String']>;
  fontColor?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  portfolioImage?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
};

export type StylesUpdateInput = {
  companyId: Scalars['String'];
  fontColor?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  portfolioImage?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
};

export type StylesWhereUnique = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Target = {
  benchmark?: Maybe<Scalars['Float']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDashboardDefault?: Maybe<Scalars['Boolean']>;
  lastComparisonTime?: Maybe<Scalars['DateTime']>;
  lastComparisonValue?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nextExpectedTime?: Maybe<Scalars['DateTime']>;
  period?: Maybe<Scalars['String']>;
  targetValue?: Maybe<Scalars['Float']>;
  triggerCondition?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TargetCreateInput = {
  benchmark?: InputMaybe<Scalars['Float']>;
  channelId: Scalars['String'];
  name: Scalars['String'];
  period: Scalars['String'];
  targetValue?: InputMaybe<Scalars['Float']>;
  triggerCondition: Scalars['String'];
};

export type TargetDeleteInput = {
  id: Scalars['String'];
};

export type TargetGetManyInput = {
  channelId: Scalars['String'];
};

export type TargetGetOneInput = {
  id: Scalars['String'];
};

export type TargetUpdateInput = {
  benchmark?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  targetValue?: InputMaybe<Scalars['Float']>;
  triggerCondition?: InputMaybe<Scalars['String']>;
};

export type TransportApi = {
  id: Scalars['String'];
  key: Scalars['String'];
};

export type UpdateCompanyRelationshipInput = {
  buildingId: Scalars['String'];
  buildingName?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  companyReference?: InputMaybe<Scalars['String']>;
  companyReferenceName?: InputMaybe<Scalars['String']>;
  relationshipType?: InputMaybe<Scalars['String']>;
};

export type UploadConfig = {
  name: Scalars['String'];
  type: Scalars['String'];
};

export type UploadConfigInput = {
  name: Scalars['String'];
  type: Scalars['String'];
};

export type User = {
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customPermissions?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserChangeInput = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UserCreateInput = {
  companyId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
};

export type UserDeleteInput = {
  id: Scalars['String'];
};

export type UserDeletePayload = {
  message?: Maybe<Scalars['String']>;
};

export type UserGetManyInput = {
  companyId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UserGetOneInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UserWhereUniqueInput = {
  id: Scalars['String'];
};

export type WeatherApi = {
  host: Scalars['String'];
  key: Scalars['String'];
};

export type ChannelRelationshipTreeUpdateMutationVariables = Exact<{
  data: Array<ChannelUpdateInput> | ChannelUpdateInput;
}>;


export type ChannelRelationshipTreeUpdateMutation = { channelRelationshipTreeUpdate: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, uploadLabel?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null, children?: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, uploadLabel?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null, children?: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, uploadLabel?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null }> | null }> | null } | null> };

export type CompositeChannelCreateMutationVariables = Exact<{
  data: CompositeChannelCreateInput;
}>;


export type CompositeChannelCreateMutation = { compositeChannelCreate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, buildingId?: string | null, channelList?: Array<string | null> | null, period?: string | null, shadowId?: string | null, isDashboardDefault?: boolean | null } };

export type CompositeChannelUpdateMutationVariables = Exact<{
  data: CompositeChannelUpdateInput;
}>;


export type CompositeChannelUpdateMutation = { compositeChannelUpdate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, buildingId?: string | null, channelList?: Array<string | null> | null, period?: string | null, shadowId?: string | null, isDashboardDefault?: boolean | null } };

export type CompositeChannelDeleteMutationVariables = Exact<{
  data: ChannelUniqueIdInput;
}>;


export type CompositeChannelDeleteMutation = { compositeChannelDelete: { message?: string | null } };

export type ChannelCreateMutationVariables = Exact<{
  data: ChannelCreateInput;
}>;


export type ChannelCreateMutation = { channelCreate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, uploadLabel?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null } };

export type CompositeChannelRelationshipUpdateMutationVariables = Exact<{
  data: Array<MixedChannelInput> | MixedChannelInput;
}>;


export type CompositeChannelRelationshipUpdateMutation = { compositeChannelRelationshipUpdate: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null } | null> | null } | null> | null } | null> | null } | null> | null } | null> | null } | null> };

export type UserUpdateMutationVariables = Exact<{
  data: UserChangeInput;
}>;


export type UserUpdateMutation = { userUpdate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, companyId?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, slug?: string | null, picture?: string | null, role?: string | null, customPermissions?: string | null } };

export type CompanyUpdateMutationVariables = Exact<{
  data: CompanyUpdateInput;
}>;


export type CompanyUpdateMutation = { companyUpdate: { id: string, createdAt: any, updatedAt: any, name: string, slug: string, portfolioImage?: string | null, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, fontColor?: string | null, userIds: Array<string> } };

export type ChannelUpdateMutationVariables = Exact<{
  data: ChannelUpdateInput;
}>;


export type ChannelUpdateMutation = { channelUpdate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, uploadLabel?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null } };

export type DeleteUserMutationVariables = Exact<{
  data: UserDeleteInput;
}>;


export type DeleteUserMutation = { userDelete: { message?: string | null } };

export type DeleteCompanyMutationVariables = Exact<{
  data: CompanyDeleteInput;
}>;


export type DeleteCompanyMutation = { companyDelete: { message: string } };

export type ChannelDeleteMutationVariables = Exact<{
  data: ChannelUniqueIdInput;
}>;


export type ChannelDeleteMutation = { channelDelete: { message?: string | null } };

export type TargetCreateMutationVariables = Exact<{
  data: TargetCreateInput;
}>;


export type TargetCreateMutation = { targetCreate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, period?: string | null, benchmark?: number | null, channelId?: string | null, targetValue?: number | null, triggerCondition?: string | null, nextExpectedTime?: any | null, lastComparisonTime?: any | null, lastComparisonValue?: number | null } };

export type TargetDeleteMutationVariables = Exact<{
  data: TargetDeleteInput;
}>;


export type TargetDeleteMutation = { targetDelete: { message?: string | null } };

export type TargetUpdateMutationVariables = Exact<{
  data: TargetUpdateInput;
}>;


export type TargetUpdateMutation = { targetUpdate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, period?: string | null, benchmark?: number | null, channelId?: string | null, targetValue?: number | null, triggerCondition?: string | null, nextExpectedTime?: any | null, lastComparisonTime?: any | null, lastComparisonValue?: number | null } };

export type AlertCreateMutationVariables = Exact<{
  where: AlertCreateInput;
}>;


export type AlertCreateMutation = { alertCreate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, destination?: string | null, messageType?: string | null, severity?: string | null } };

export type AlertUpdateMutationVariables = Exact<{
  where: AlertUpdateInput;
}>;


export type AlertUpdateMutation = { alertUpdate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, destination?: string | null, messageType?: string | null, severity?: string | null } };

export type AlertDeleteMutationVariables = Exact<{
  where: AlertDeleteInput;
}>;


export type AlertDeleteMutation = { alertDelete: { message: string } };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = { resetPassword: { message: string } };

export type SetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type SetPasswordMutation = { setPassword: { message: string } };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { changePassword: { message: string } };

export type BuildingCreateMutationVariables = Exact<{
  data: BuildingCreateInput;
}>;


export type BuildingCreateMutation = { buildingCreate: { id: string, createdAt: any, updatedAt: any, name: string, projectRef: string, slug: string, picture: string, thumb: string, epcRating?: string | null, epcUrl?: string | null, floorArea: number, hasFileServer: boolean, hasDataServer: boolean, dashboardPeriod: string, uploadConfig: Array<{ name: string, type: string }> } };

export type BuildingUpdateMutationVariables = Exact<{
  data: BuildingUpdateInput;
}>;


export type BuildingUpdateMutation = { buildingUpdate: { id: string, createdAt: any, updatedAt: any, name: string, projectRef: string, slug: string, picture: string, thumb: string, epcRating?: string | null, epcUrl?: string | null, floorArea: number, hasFileServer: boolean, hasDataServer: boolean, dashboardPeriod: string, uploadConfig: Array<{ name: string, type: string }> } };

export type BuildingDeleteMutationVariables = Exact<{
  data: BuildingDeleteInput;
}>;


export type BuildingDeleteMutation = { buildingDelete?: { message?: string | null } | null };

export type DashboardUpdateMutationVariables = Exact<{
  data: DashboardUpdateInput;
}>;


export type DashboardUpdateMutation = { dashboardUpdate: { id: string, period: string } };

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignInMutation = { signIn: { token: string, message: string, user?: { id: string, firstName: string, lastName: string, slug: string, picture?: string | null, isStaff: boolean, isAdmin: boolean, companyId: string } | null } };

export type SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignOutMutation = { signOut: { message: string } };

export type CreateCompanyMutationVariables = Exact<{
  data: CompanyCreateInput;
}>;


export type CreateCompanyMutation = { companyCreate: { id: string, createdAt: any, updatedAt: any, name: string, slug: string, portfolioImage?: string | null, logo?: string | null, userIds: Array<string> } };

export type UserCreateMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type UserCreateMutation = { userCreate: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, companyId?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, slug?: string | null, picture?: string | null, role?: string | null, customPermissions?: string | null } };

export type AddCompanyRelationshipMutationVariables = Exact<{
  data: AddCompanyRelationshipInput;
}>;


export type AddCompanyRelationshipMutation = { addCompanyRelationship?: { buildingId: string, companyId: string, buildingName: string, companyReference: string, companyReferenceName: string, relationshipType: string } | null };

export type RemoveCompanyRelationshipMutationVariables = Exact<{
  data: RemoveCompanyRelationshipInput;
}>;


export type RemoveCompanyRelationshipMutation = { removeCompanyRelationship?: { message?: string | null } | null };

export type UpdateCompanyRelationshipMutationVariables = Exact<{
  data: UpdateCompanyRelationshipInput;
}>;


export type UpdateCompanyRelationshipMutation = { updateCompanyRelationship?: { companyId: string, buildingId: string, buildingName: string, companyReference: string, companyReferenceName: string, relationshipType: string } | null };

export type BuildingGetIdQueryVariables = Exact<{
  where: BuildingWhereUniqueSearchOneInput;
}>;


export type BuildingGetIdQuery = { buildingGetOne?: { id: string, name: string } | null };

export type ChannelSearchManyQueryVariables = Exact<{
  where: ChannelSearchManyInput;
}>;


export type ChannelSearchManyQuery = { channelSearchMany: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null } | null> };

export type CompositeChannelRelationshipsQueryVariables = Exact<{
  where: CompositeChannelGetManyInput;
}>;


export type CompositeChannelRelationshipsQuery = { compositeChannelRelationships: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null, children?: Array<{ name?: string | null, id?: string | null, composite?: boolean | null, isDashboardDefault?: boolean | null } | null> | null } | null> | null } | null> | null } | null> | null } | null> | null }> };

export type ChannelMeterRelationshipsQueryVariables = Exact<{
  where: SearchChannelByBuildingIdInput;
}>;


export type ChannelMeterRelationshipsQuery = { channelMeterRelationships: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null, children?: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null, children?: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null, children?: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null, children?: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null, children?: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, reference?: string | null, period?: string | null, measurementType?: string | null, buildingId?: string | null, parentId?: string | null, channelType?: string | null }> | null }> | null }> | null }> | null }> | null } | null> };

export type GetOneCompositeChannelQueryVariables = Exact<{
  where: CompositeChannelGetOneInput;
}>;


export type GetOneCompositeChannelQuery = { getOneCompositeChannel: { id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, buildingId?: string | null, channelList?: Array<string | null> | null, period?: string | null, shadowId?: string | null, isDashboardDefault?: boolean | null } };

export type TargetsQueryVariables = Exact<{
  where: TargetGetManyInput;
}>;


export type TargetsQuery = { targets: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, period?: string | null, benchmark?: number | null, channelId?: string | null, targetValue?: number | null, triggerCondition?: string | null, nextExpectedTime?: any | null, lastComparisonTime?: any | null, lastComparisonValue?: number | null }> };

export type AlertsQueryVariables = Exact<{
  where: AlertGetManyInput;
}>;


export type AlertsQuery = { alerts: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: string | null, targetId?: string | null, destination?: string | null, messageType?: string | null, severity?: string | null }> };

export type BuildingGetManyQueryVariables = Exact<{
  where: BuildingWhereUniqueSearchManyInput;
}>;


export type BuildingGetManyQuery = { buildingGetMany: Array<{ id: string, name: string, projectRef: string, slug: string, picture: string, thumb: string, epcRating?: string | null, epcUrl?: string | null, breeamRating: string, breeamScore?: number | null, constructionYear?: number | null, floorArea: number, hasFileServer: boolean, hasDataServer: boolean, dashboardPeriod: string, uploadConfig: Array<{ name: string, type: string }>, companyRelationships: Array<{ companyId: string, buildingName: string, companyReference: string, companyReferenceName: string, relationshipType: string }>, address: { buildingId?: string | null, companyId?: string | null, houseNameOrNumber?: string | null, street?: string | null, city?: string | null, region?: string | null, country?: string | null, postcode?: string | null, latitude?: number | null, longitude?: number | null }, portfolio: { renewables: { measurement: number, target: number }, waterUsage: { measurement: number, target: number }, carbonDioxide: { measurement: number, target: number }, wholeBuilding: { measurement: number, target: number } } } | null> };

export type CompanyQueryVariables = Exact<{
  where: CompanyWhereUnique;
}>;


export type CompanyQuery = { company: { name: string, portfolioImage?: string | null, slug: string, logo?: string | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { token?: string | null } };

export type BuildingGetOneQueryVariables = Exact<{
  where: BuildingWhereUniqueSearchOneInput;
}>;


export type BuildingGetOneQuery = { buildingGetOne?: { id: string, name: string, projectRef: string, slug: string, picture: string, thumb: string, epcRating?: string | null, epcUrl?: string | null, breeamRating: string, breeamScore?: number | null, constructionYear?: number | null, floorArea: number, hasFileServer: boolean, hasDataServer: boolean, dashboardPeriod: string, uploadConfig: Array<{ name: string, type: string }>, companyRelationships: Array<{ buildingId: string, buildingName: string, companyId: string, companyReference: string, companyReferenceName: string, relationshipType: string }>, address: { buildingId?: string | null, companyId?: string | null, houseNameOrNumber?: string | null, street?: string | null, city?: string | null, region?: string | null, country?: string | null, postcode?: string | null, latitude?: number | null, longitude?: number | null }, portfolio: { renewables: { measurement: number, target: number, comment: string, comparison: string }, waterUsage: { measurement: number, target: number, comment: string, comparison: string }, carbonDioxide: { measurement: number, target: number, comment: string, comparison: string }, wholeBuilding: { measurement: number, target: number, comment: string, comparison: string }, heatingEnergy: { measurement: number, target: number, comment: string, comparison: string }, coolingEnergy: { measurement: number, target: number, comment: string, comparison: string }, processEnergy: { measurement: number, target: number, comment: string, comparison: string }, socketPower: { measurement: number, target: number, comment: string, comparison: string }, lightingEnergy: { measurement: number, target: number, comment: string, comparison: string }, ventilation: { measurement: number, target: number, comment: string, comparison: string } } } | null };

export type CompaniesQueryVariables = Exact<{
  where: CompanyWhereSearch;
}>;


export type CompaniesQuery = { companies: Array<{ id: string, createdAt: any, updatedAt: any, name: string, slug: string, portfolioImage?: string | null, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, fontColor?: string | null, userIds: Array<string> }> };

export type DataGetManyQueryVariables = Exact<{
  where: DataInput;
}>;


export type DataGetManyQuery = { dataGetMany: { id: string, name: string, data: Array<{ datumId?: string | null, channelId?: string | null, timestamp?: any | null, value?: number | null, status?: string | null }> } };

export type DashboardQueryVariables = Exact<{
  where: DashboardInput;
}>;


export type DashboardQuery = { dashboard: { id: string, createdAt: any, updatedAt: any, buildingId: string, buildingName: string, occupierLogo: string, dashboardStyle: string, period: string, tryMessage: string, infoMessage: string, currentConsumption: number, currentGeneration: number, lifetimeCarbonSavings: number, lifetimeCarbonSavingsUnits: string, renewables: { measurement: number, target: number, comment: string, comparison: string }, carbonDioxide: { measurement: number, target: number, comment: string, comparison: string }, waterUsage: { measurement: number, target: number, comment: string, comparison: string }, wholeBuilding: { measurement: number, target: number, comment: string, comparison: string }, ventilation: { measurement: number, target: number, comment: string, comparison: string }, coolingEnergy: { measurement: number, target: number, comment: string, comparison: string }, heatingEnergy: { measurement: number, target: number, comment: string, comparison: string }, lightingEnergy: { measurement: number, target: number, comment: string, comparison: string }, socketPower: { measurement: number, target: number, comment: string, comparison: string }, processEnergy: { measurement: number, target: number, comment: string, comparison: string }, dashboardColors: { primaryColor: string, secondaryColor: string, fontColor: string }, address: { companyId?: string | null, city?: string | null, postcode?: string | null, latitude?: number | null, longitude?: number | null }, weatherAPI: { key: string, host: string }, transportAPI: { id: string, key: string } } };

export type UserGetManyQueryVariables = Exact<{
  where: UserGetManyInput;
}>;


export type UserGetManyQuery = { userGetMany: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, companyId?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, slug?: string | null, picture?: string | null, role?: string | null, customPermissions?: string | null } | null> };

export type CompanyListQueryVariables = Exact<{
  where: CompanyWhereSearch;
}>;


export type CompanyListQuery = { companies: Array<{ id: string, name: string }> };

export type EmailBuildingOverviewQueryVariables = Exact<{
  where: EmailBuildingOverviewInput;
}>;


export type EmailBuildingOverviewQuery = { emailBuildingOverview: { result?: string | null } };

export type GetManyCompositeChannelQueryVariables = Exact<{
  where: CompositeChannelGetManyInput;
}>;


export type GetManyCompositeChannelQuery = { getManyCompositeChannel: Array<{ id?: string | null, createdAt?: any | null, updatedAt?: any | null, name?: string | null, buildingId?: string | null, channelList?: Array<string | null> | null, period?: string | null, shadowId?: string | null } | null> };


export const ChannelRelationshipTreeUpdateDocument = gql`
    mutation ChannelRelationshipTreeUpdate($data: [ChannelUpdateInput!]!) {
  channelRelationshipTreeUpdate(data: $data) {
    id
    createdAt
    updatedAt
    name
    uploadLabel
    reference
    period
    measurementType
    buildingId
    parentId
    channelType
    children {
      id
      createdAt
      updatedAt
      name
      uploadLabel
      reference
      period
      measurementType
      buildingId
      parentId
      channelType
      children {
        id
        createdAt
        updatedAt
        name
        uploadLabel
        reference
        period
        measurementType
        buildingId
        parentId
        channelType
      }
    }
  }
}
    `;
export type ChannelRelationshipTreeUpdateMutationFn = Apollo.MutationFunction<ChannelRelationshipTreeUpdateMutation, ChannelRelationshipTreeUpdateMutationVariables>;

/**
 * __useChannelRelationshipTreeUpdateMutation__
 *
 * To run a mutation, you first call `useChannelRelationshipTreeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelRelationshipTreeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelRelationshipTreeUpdateMutation, { data, loading, error }] = useChannelRelationshipTreeUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChannelRelationshipTreeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ChannelRelationshipTreeUpdateMutation, ChannelRelationshipTreeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelRelationshipTreeUpdateMutation, ChannelRelationshipTreeUpdateMutationVariables>(ChannelRelationshipTreeUpdateDocument, options);
      }
export type ChannelRelationshipTreeUpdateMutationHookResult = ReturnType<typeof useChannelRelationshipTreeUpdateMutation>;
export type ChannelRelationshipTreeUpdateMutationResult = Apollo.MutationResult<ChannelRelationshipTreeUpdateMutation>;
export type ChannelRelationshipTreeUpdateMutationOptions = Apollo.BaseMutationOptions<ChannelRelationshipTreeUpdateMutation, ChannelRelationshipTreeUpdateMutationVariables>;
export const CompositeChannelCreateDocument = gql`
    mutation CompositeChannelCreate($data: CompositeChannelCreateInput!) {
  compositeChannelCreate(data: $data) {
    id
    createdAt
    updatedAt
    name
    buildingId
    channelList
    period
    shadowId
    isDashboardDefault
  }
}
    `;
export type CompositeChannelCreateMutationFn = Apollo.MutationFunction<CompositeChannelCreateMutation, CompositeChannelCreateMutationVariables>;

/**
 * __useCompositeChannelCreateMutation__
 *
 * To run a mutation, you first call `useCompositeChannelCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompositeChannelCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compositeChannelCreateMutation, { data, loading, error }] = useCompositeChannelCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompositeChannelCreateMutation(baseOptions?: Apollo.MutationHookOptions<CompositeChannelCreateMutation, CompositeChannelCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompositeChannelCreateMutation, CompositeChannelCreateMutationVariables>(CompositeChannelCreateDocument, options);
      }
export type CompositeChannelCreateMutationHookResult = ReturnType<typeof useCompositeChannelCreateMutation>;
export type CompositeChannelCreateMutationResult = Apollo.MutationResult<CompositeChannelCreateMutation>;
export type CompositeChannelCreateMutationOptions = Apollo.BaseMutationOptions<CompositeChannelCreateMutation, CompositeChannelCreateMutationVariables>;
export const CompositeChannelUpdateDocument = gql`
    mutation CompositeChannelUpdate($data: CompositeChannelUpdateInput!) {
  compositeChannelUpdate(data: $data) {
    id
    createdAt
    updatedAt
    name
    buildingId
    channelList
    period
    shadowId
    isDashboardDefault
  }
}
    `;
export type CompositeChannelUpdateMutationFn = Apollo.MutationFunction<CompositeChannelUpdateMutation, CompositeChannelUpdateMutationVariables>;

/**
 * __useCompositeChannelUpdateMutation__
 *
 * To run a mutation, you first call `useCompositeChannelUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompositeChannelUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compositeChannelUpdateMutation, { data, loading, error }] = useCompositeChannelUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompositeChannelUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CompositeChannelUpdateMutation, CompositeChannelUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompositeChannelUpdateMutation, CompositeChannelUpdateMutationVariables>(CompositeChannelUpdateDocument, options);
      }
export type CompositeChannelUpdateMutationHookResult = ReturnType<typeof useCompositeChannelUpdateMutation>;
export type CompositeChannelUpdateMutationResult = Apollo.MutationResult<CompositeChannelUpdateMutation>;
export type CompositeChannelUpdateMutationOptions = Apollo.BaseMutationOptions<CompositeChannelUpdateMutation, CompositeChannelUpdateMutationVariables>;
export const CompositeChannelDeleteDocument = gql`
    mutation CompositeChannelDelete($data: ChannelUniqueIdInput!) {
  compositeChannelDelete(data: $data) {
    message
  }
}
    `;
export type CompositeChannelDeleteMutationFn = Apollo.MutationFunction<CompositeChannelDeleteMutation, CompositeChannelDeleteMutationVariables>;

/**
 * __useCompositeChannelDeleteMutation__
 *
 * To run a mutation, you first call `useCompositeChannelDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompositeChannelDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compositeChannelDeleteMutation, { data, loading, error }] = useCompositeChannelDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompositeChannelDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CompositeChannelDeleteMutation, CompositeChannelDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompositeChannelDeleteMutation, CompositeChannelDeleteMutationVariables>(CompositeChannelDeleteDocument, options);
      }
export type CompositeChannelDeleteMutationHookResult = ReturnType<typeof useCompositeChannelDeleteMutation>;
export type CompositeChannelDeleteMutationResult = Apollo.MutationResult<CompositeChannelDeleteMutation>;
export type CompositeChannelDeleteMutationOptions = Apollo.BaseMutationOptions<CompositeChannelDeleteMutation, CompositeChannelDeleteMutationVariables>;
export const ChannelCreateDocument = gql`
    mutation ChannelCreate($data: ChannelCreateInput!) {
  channelCreate(data: $data) {
    id
    createdAt
    updatedAt
    name
    uploadLabel
    reference
    period
    measurementType
    buildingId
    parentId
    channelType
  }
}
    `;
export type ChannelCreateMutationFn = Apollo.MutationFunction<ChannelCreateMutation, ChannelCreateMutationVariables>;

/**
 * __useChannelCreateMutation__
 *
 * To run a mutation, you first call `useChannelCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelCreateMutation, { data, loading, error }] = useChannelCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChannelCreateMutation(baseOptions?: Apollo.MutationHookOptions<ChannelCreateMutation, ChannelCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelCreateMutation, ChannelCreateMutationVariables>(ChannelCreateDocument, options);
      }
export type ChannelCreateMutationHookResult = ReturnType<typeof useChannelCreateMutation>;
export type ChannelCreateMutationResult = Apollo.MutationResult<ChannelCreateMutation>;
export type ChannelCreateMutationOptions = Apollo.BaseMutationOptions<ChannelCreateMutation, ChannelCreateMutationVariables>;
export const CompositeChannelRelationshipUpdateDocument = gql`
    mutation CompositeChannelRelationshipUpdate($data: [MixedChannelInput!]!) {
  compositeChannelRelationshipUpdate(data: $data) {
    name
    id
    composite
    isDashboardDefault
    children {
      name
      id
      composite
      isDashboardDefault
      children {
        name
        id
        composite
        isDashboardDefault
        children {
          name
          id
          composite
          isDashboardDefault
          children {
            name
            id
            composite
            isDashboardDefault
            children {
              name
              id
              composite
              isDashboardDefault
            }
          }
        }
      }
    }
  }
}
    `;
export type CompositeChannelRelationshipUpdateMutationFn = Apollo.MutationFunction<CompositeChannelRelationshipUpdateMutation, CompositeChannelRelationshipUpdateMutationVariables>;

/**
 * __useCompositeChannelRelationshipUpdateMutation__
 *
 * To run a mutation, you first call `useCompositeChannelRelationshipUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompositeChannelRelationshipUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compositeChannelRelationshipUpdateMutation, { data, loading, error }] = useCompositeChannelRelationshipUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompositeChannelRelationshipUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CompositeChannelRelationshipUpdateMutation, CompositeChannelRelationshipUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompositeChannelRelationshipUpdateMutation, CompositeChannelRelationshipUpdateMutationVariables>(CompositeChannelRelationshipUpdateDocument, options);
      }
export type CompositeChannelRelationshipUpdateMutationHookResult = ReturnType<typeof useCompositeChannelRelationshipUpdateMutation>;
export type CompositeChannelRelationshipUpdateMutationResult = Apollo.MutationResult<CompositeChannelRelationshipUpdateMutation>;
export type CompositeChannelRelationshipUpdateMutationOptions = Apollo.BaseMutationOptions<CompositeChannelRelationshipUpdateMutation, CompositeChannelRelationshipUpdateMutationVariables>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($data: UserChangeInput!) {
  userUpdate(data: $data) {
    id
    createdAt
    updatedAt
    companyId
    email
    firstName
    lastName
    slug
    picture
    role
    customPermissions
  }
}
    `;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const CompanyUpdateDocument = gql`
    mutation CompanyUpdate($data: CompanyUpdateInput!) {
  companyUpdate(data: $data) {
    id
    createdAt
    updatedAt
    name
    slug
    portfolioImage
    logo
    primaryColor
    secondaryColor
    fontColor
    userIds
  }
}
    `;
export type CompanyUpdateMutationFn = Apollo.MutationFunction<CompanyUpdateMutation, CompanyUpdateMutationVariables>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CompanyUpdateMutation, CompanyUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyUpdateMutation, CompanyUpdateMutationVariables>(CompanyUpdateDocument, options);
      }
export type CompanyUpdateMutationHookResult = ReturnType<typeof useCompanyUpdateMutation>;
export type CompanyUpdateMutationResult = Apollo.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions = Apollo.BaseMutationOptions<CompanyUpdateMutation, CompanyUpdateMutationVariables>;
export const ChannelUpdateDocument = gql`
    mutation ChannelUpdate($data: ChannelUpdateInput!) {
  channelUpdate(data: $data) {
    id
    createdAt
    updatedAt
    name
    uploadLabel
    reference
    period
    measurementType
    buildingId
    parentId
    channelType
  }
}
    `;
export type ChannelUpdateMutationFn = Apollo.MutationFunction<ChannelUpdateMutation, ChannelUpdateMutationVariables>;

/**
 * __useChannelUpdateMutation__
 *
 * To run a mutation, you first call `useChannelUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelUpdateMutation, { data, loading, error }] = useChannelUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChannelUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ChannelUpdateMutation, ChannelUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelUpdateMutation, ChannelUpdateMutationVariables>(ChannelUpdateDocument, options);
      }
export type ChannelUpdateMutationHookResult = ReturnType<typeof useChannelUpdateMutation>;
export type ChannelUpdateMutationResult = Apollo.MutationResult<ChannelUpdateMutation>;
export type ChannelUpdateMutationOptions = Apollo.BaseMutationOptions<ChannelUpdateMutation, ChannelUpdateMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($data: UserDeleteInput!) {
  userDelete(data: $data) {
    message
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($data: CompanyDeleteInput!) {
  companyDelete(data: $data) {
    message
  }
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const ChannelDeleteDocument = gql`
    mutation ChannelDelete($data: ChannelUniqueIdInput!) {
  channelDelete(data: $data) {
    message
  }
}
    `;
export type ChannelDeleteMutationFn = Apollo.MutationFunction<ChannelDeleteMutation, ChannelDeleteMutationVariables>;

/**
 * __useChannelDeleteMutation__
 *
 * To run a mutation, you first call `useChannelDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelDeleteMutation, { data, loading, error }] = useChannelDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChannelDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ChannelDeleteMutation, ChannelDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelDeleteMutation, ChannelDeleteMutationVariables>(ChannelDeleteDocument, options);
      }
export type ChannelDeleteMutationHookResult = ReturnType<typeof useChannelDeleteMutation>;
export type ChannelDeleteMutationResult = Apollo.MutationResult<ChannelDeleteMutation>;
export type ChannelDeleteMutationOptions = Apollo.BaseMutationOptions<ChannelDeleteMutation, ChannelDeleteMutationVariables>;
export const TargetCreateDocument = gql`
    mutation TargetCreate($data: TargetCreateInput!) {
  targetCreate(data: $data) {
    id
    createdAt
    updatedAt
    name
    period
    benchmark
    channelId
    targetValue
    triggerCondition
    nextExpectedTime
    lastComparisonTime
    lastComparisonValue
  }
}
    `;
export type TargetCreateMutationFn = Apollo.MutationFunction<TargetCreateMutation, TargetCreateMutationVariables>;

/**
 * __useTargetCreateMutation__
 *
 * To run a mutation, you first call `useTargetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTargetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [targetCreateMutation, { data, loading, error }] = useTargetCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTargetCreateMutation(baseOptions?: Apollo.MutationHookOptions<TargetCreateMutation, TargetCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TargetCreateMutation, TargetCreateMutationVariables>(TargetCreateDocument, options);
      }
export type TargetCreateMutationHookResult = ReturnType<typeof useTargetCreateMutation>;
export type TargetCreateMutationResult = Apollo.MutationResult<TargetCreateMutation>;
export type TargetCreateMutationOptions = Apollo.BaseMutationOptions<TargetCreateMutation, TargetCreateMutationVariables>;
export const TargetDeleteDocument = gql`
    mutation TargetDelete($data: TargetDeleteInput!) {
  targetDelete(data: $data) {
    message
  }
}
    `;
export type TargetDeleteMutationFn = Apollo.MutationFunction<TargetDeleteMutation, TargetDeleteMutationVariables>;

/**
 * __useTargetDeleteMutation__
 *
 * To run a mutation, you first call `useTargetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTargetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [targetDeleteMutation, { data, loading, error }] = useTargetDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTargetDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TargetDeleteMutation, TargetDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TargetDeleteMutation, TargetDeleteMutationVariables>(TargetDeleteDocument, options);
      }
export type TargetDeleteMutationHookResult = ReturnType<typeof useTargetDeleteMutation>;
export type TargetDeleteMutationResult = Apollo.MutationResult<TargetDeleteMutation>;
export type TargetDeleteMutationOptions = Apollo.BaseMutationOptions<TargetDeleteMutation, TargetDeleteMutationVariables>;
export const TargetUpdateDocument = gql`
    mutation TargetUpdate($data: TargetUpdateInput!) {
  targetUpdate(data: $data) {
    id
    createdAt
    updatedAt
    name
    period
    benchmark
    channelId
    targetValue
    triggerCondition
    nextExpectedTime
    lastComparisonTime
    lastComparisonValue
  }
}
    `;
export type TargetUpdateMutationFn = Apollo.MutationFunction<TargetUpdateMutation, TargetUpdateMutationVariables>;

/**
 * __useTargetUpdateMutation__
 *
 * To run a mutation, you first call `useTargetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTargetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [targetUpdateMutation, { data, loading, error }] = useTargetUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTargetUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TargetUpdateMutation, TargetUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TargetUpdateMutation, TargetUpdateMutationVariables>(TargetUpdateDocument, options);
      }
export type TargetUpdateMutationHookResult = ReturnType<typeof useTargetUpdateMutation>;
export type TargetUpdateMutationResult = Apollo.MutationResult<TargetUpdateMutation>;
export type TargetUpdateMutationOptions = Apollo.BaseMutationOptions<TargetUpdateMutation, TargetUpdateMutationVariables>;
export const AlertCreateDocument = gql`
    mutation AlertCreate($where: AlertCreateInput!) {
  alertCreate(where: $where) {
    id
    createdAt
    updatedAt
    id
    destination
    messageType
    severity
  }
}
    `;
export type AlertCreateMutationFn = Apollo.MutationFunction<AlertCreateMutation, AlertCreateMutationVariables>;

/**
 * __useAlertCreateMutation__
 *
 * To run a mutation, you first call `useAlertCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlertCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alertCreateMutation, { data, loading, error }] = useAlertCreateMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAlertCreateMutation(baseOptions?: Apollo.MutationHookOptions<AlertCreateMutation, AlertCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AlertCreateMutation, AlertCreateMutationVariables>(AlertCreateDocument, options);
      }
export type AlertCreateMutationHookResult = ReturnType<typeof useAlertCreateMutation>;
export type AlertCreateMutationResult = Apollo.MutationResult<AlertCreateMutation>;
export type AlertCreateMutationOptions = Apollo.BaseMutationOptions<AlertCreateMutation, AlertCreateMutationVariables>;
export const AlertUpdateDocument = gql`
    mutation AlertUpdate($where: AlertUpdateInput!) {
  alertUpdate(where: $where) {
    id
    createdAt
    updatedAt
    id
    destination
    messageType
    severity
  }
}
    `;
export type AlertUpdateMutationFn = Apollo.MutationFunction<AlertUpdateMutation, AlertUpdateMutationVariables>;

/**
 * __useAlertUpdateMutation__
 *
 * To run a mutation, you first call `useAlertUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlertUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alertUpdateMutation, { data, loading, error }] = useAlertUpdateMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAlertUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AlertUpdateMutation, AlertUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AlertUpdateMutation, AlertUpdateMutationVariables>(AlertUpdateDocument, options);
      }
export type AlertUpdateMutationHookResult = ReturnType<typeof useAlertUpdateMutation>;
export type AlertUpdateMutationResult = Apollo.MutationResult<AlertUpdateMutation>;
export type AlertUpdateMutationOptions = Apollo.BaseMutationOptions<AlertUpdateMutation, AlertUpdateMutationVariables>;
export const AlertDeleteDocument = gql`
    mutation AlertDelete($where: AlertDeleteInput!) {
  alertDelete(where: $where) {
    message
  }
}
    `;
export type AlertDeleteMutationFn = Apollo.MutationFunction<AlertDeleteMutation, AlertDeleteMutationVariables>;

/**
 * __useAlertDeleteMutation__
 *
 * To run a mutation, you first call `useAlertDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlertDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alertDeleteMutation, { data, loading, error }] = useAlertDeleteMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAlertDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AlertDeleteMutation, AlertDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AlertDeleteMutation, AlertDeleteMutationVariables>(AlertDeleteDocument, options);
      }
export type AlertDeleteMutationHookResult = ReturnType<typeof useAlertDeleteMutation>;
export type AlertDeleteMutationResult = Apollo.MutationResult<AlertDeleteMutation>;
export type AlertDeleteMutationOptions = Apollo.BaseMutationOptions<AlertDeleteMutation, AlertDeleteMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  resetPassword(email: $email) {
    message
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SetPasswordDocument = gql`
    mutation SetPassword($password: String!, $token: String!) {
  setPassword(password: $password, token: $token) {
    message
  }
}
    `;
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, options);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    message
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const BuildingCreateDocument = gql`
    mutation BuildingCreate($data: BuildingCreateInput!) {
  buildingCreate(data: $data) {
    id
    createdAt
    updatedAt
    name
    projectRef
    slug
    picture
    thumb
    epcRating
    epcUrl
    floorArea
    hasFileServer
    hasDataServer
    dashboardPeriod
    uploadConfig {
      name
      type
    }
  }
}
    `;
export type BuildingCreateMutationFn = Apollo.MutationFunction<BuildingCreateMutation, BuildingCreateMutationVariables>;

/**
 * __useBuildingCreateMutation__
 *
 * To run a mutation, you first call `useBuildingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingCreateMutation, { data, loading, error }] = useBuildingCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBuildingCreateMutation(baseOptions?: Apollo.MutationHookOptions<BuildingCreateMutation, BuildingCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingCreateMutation, BuildingCreateMutationVariables>(BuildingCreateDocument, options);
      }
export type BuildingCreateMutationHookResult = ReturnType<typeof useBuildingCreateMutation>;
export type BuildingCreateMutationResult = Apollo.MutationResult<BuildingCreateMutation>;
export type BuildingCreateMutationOptions = Apollo.BaseMutationOptions<BuildingCreateMutation, BuildingCreateMutationVariables>;
export const BuildingUpdateDocument = gql`
    mutation BuildingUpdate($data: BuildingUpdateInput!) {
  buildingUpdate(data: $data) {
    id
    createdAt
    updatedAt
    name
    projectRef
    slug
    picture
    thumb
    epcRating
    epcUrl
    floorArea
    hasFileServer
    hasDataServer
    dashboardPeriod
    uploadConfig {
      name
      type
    }
  }
}
    `;
export type BuildingUpdateMutationFn = Apollo.MutationFunction<BuildingUpdateMutation, BuildingUpdateMutationVariables>;

/**
 * __useBuildingUpdateMutation__
 *
 * To run a mutation, you first call `useBuildingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingUpdateMutation, { data, loading, error }] = useBuildingUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBuildingUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BuildingUpdateMutation, BuildingUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingUpdateMutation, BuildingUpdateMutationVariables>(BuildingUpdateDocument, options);
      }
export type BuildingUpdateMutationHookResult = ReturnType<typeof useBuildingUpdateMutation>;
export type BuildingUpdateMutationResult = Apollo.MutationResult<BuildingUpdateMutation>;
export type BuildingUpdateMutationOptions = Apollo.BaseMutationOptions<BuildingUpdateMutation, BuildingUpdateMutationVariables>;
export const BuildingDeleteDocument = gql`
    mutation BuildingDelete($data: BuildingDeleteInput!) {
  buildingDelete(data: $data) {
    message
  }
}
    `;
export type BuildingDeleteMutationFn = Apollo.MutationFunction<BuildingDeleteMutation, BuildingDeleteMutationVariables>;

/**
 * __useBuildingDeleteMutation__
 *
 * To run a mutation, you first call `useBuildingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingDeleteMutation, { data, loading, error }] = useBuildingDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBuildingDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BuildingDeleteMutation, BuildingDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingDeleteMutation, BuildingDeleteMutationVariables>(BuildingDeleteDocument, options);
      }
export type BuildingDeleteMutationHookResult = ReturnType<typeof useBuildingDeleteMutation>;
export type BuildingDeleteMutationResult = Apollo.MutationResult<BuildingDeleteMutation>;
export type BuildingDeleteMutationOptions = Apollo.BaseMutationOptions<BuildingDeleteMutation, BuildingDeleteMutationVariables>;
export const DashboardUpdateDocument = gql`
    mutation DashboardUpdate($data: DashboardUpdateInput!) {
  dashboardUpdate(data: $data) {
    id
    period
  }
}
    `;
export type DashboardUpdateMutationFn = Apollo.MutationFunction<DashboardUpdateMutation, DashboardUpdateMutationVariables>;

/**
 * __useDashboardUpdateMutation__
 *
 * To run a mutation, you first call `useDashboardUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDashboardUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dashboardUpdateMutation, { data, loading, error }] = useDashboardUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDashboardUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DashboardUpdateMutation, DashboardUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DashboardUpdateMutation, DashboardUpdateMutationVariables>(DashboardUpdateDocument, options);
      }
export type DashboardUpdateMutationHookResult = ReturnType<typeof useDashboardUpdateMutation>;
export type DashboardUpdateMutationResult = Apollo.MutationResult<DashboardUpdateMutation>;
export type DashboardUpdateMutationOptions = Apollo.BaseMutationOptions<DashboardUpdateMutation, DashboardUpdateMutationVariables>;
export const SignInDocument = gql`
    mutation signIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password) {
    token
    message
    user {
      id
      firstName
      lastName
      slug
      picture
      isStaff
      isAdmin
      companyId
    }
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignOutDocument = gql`
    mutation signOut {
  signOut {
    message
  }
}
    `;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, options);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($data: CompanyCreateInput!) {
  companyCreate(data: $data) {
    id
    createdAt
    updatedAt
    name
    slug
    portfolioImage
    logo
    userIds
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UserCreateDocument = gql`
    mutation UserCreate($data: UserCreateInput!) {
  userCreate(data: $data) {
    id
    createdAt
    updatedAt
    companyId
    email
    firstName
    lastName
    slug
    picture
    role
    customPermissions
  }
}
    `;
export type UserCreateMutationFn = Apollo.MutationFunction<UserCreateMutation, UserCreateMutationVariables>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserCreateMutation(baseOptions?: Apollo.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(UserCreateDocument, options);
      }
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<UserCreateMutation, UserCreateMutationVariables>;
export const AddCompanyRelationshipDocument = gql`
    mutation AddCompanyRelationship($data: AddCompanyRelationshipInput!) {
  addCompanyRelationship(data: $data) {
    buildingId
    companyId
    buildingName
    companyReference
    companyReferenceName
    relationshipType
  }
}
    `;
export type AddCompanyRelationshipMutationFn = Apollo.MutationFunction<AddCompanyRelationshipMutation, AddCompanyRelationshipMutationVariables>;

/**
 * __useAddCompanyRelationshipMutation__
 *
 * To run a mutation, you first call `useAddCompanyRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyRelationshipMutation, { data, loading, error }] = useAddCompanyRelationshipMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddCompanyRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyRelationshipMutation, AddCompanyRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompanyRelationshipMutation, AddCompanyRelationshipMutationVariables>(AddCompanyRelationshipDocument, options);
      }
export type AddCompanyRelationshipMutationHookResult = ReturnType<typeof useAddCompanyRelationshipMutation>;
export type AddCompanyRelationshipMutationResult = Apollo.MutationResult<AddCompanyRelationshipMutation>;
export type AddCompanyRelationshipMutationOptions = Apollo.BaseMutationOptions<AddCompanyRelationshipMutation, AddCompanyRelationshipMutationVariables>;
export const RemoveCompanyRelationshipDocument = gql`
    mutation RemoveCompanyRelationship($data: RemoveCompanyRelationshipInput!) {
  removeCompanyRelationship(data: $data) {
    message
  }
}
    `;
export type RemoveCompanyRelationshipMutationFn = Apollo.MutationFunction<RemoveCompanyRelationshipMutation, RemoveCompanyRelationshipMutationVariables>;

/**
 * __useRemoveCompanyRelationshipMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyRelationshipMutation, { data, loading, error }] = useRemoveCompanyRelationshipMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveCompanyRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyRelationshipMutation, RemoveCompanyRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyRelationshipMutation, RemoveCompanyRelationshipMutationVariables>(RemoveCompanyRelationshipDocument, options);
      }
export type RemoveCompanyRelationshipMutationHookResult = ReturnType<typeof useRemoveCompanyRelationshipMutation>;
export type RemoveCompanyRelationshipMutationResult = Apollo.MutationResult<RemoveCompanyRelationshipMutation>;
export type RemoveCompanyRelationshipMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyRelationshipMutation, RemoveCompanyRelationshipMutationVariables>;
export const UpdateCompanyRelationshipDocument = gql`
    mutation UpdateCompanyRelationship($data: UpdateCompanyRelationshipInput!) {
  updateCompanyRelationship(data: $data) {
    companyId
    buildingId
    buildingName
    companyReference
    companyReferenceName
    relationshipType
  }
}
    `;
export type UpdateCompanyRelationshipMutationFn = Apollo.MutationFunction<UpdateCompanyRelationshipMutation, UpdateCompanyRelationshipMutationVariables>;

/**
 * __useUpdateCompanyRelationshipMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyRelationshipMutation, { data, loading, error }] = useUpdateCompanyRelationshipMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyRelationshipMutation, UpdateCompanyRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyRelationshipMutation, UpdateCompanyRelationshipMutationVariables>(UpdateCompanyRelationshipDocument, options);
      }
export type UpdateCompanyRelationshipMutationHookResult = ReturnType<typeof useUpdateCompanyRelationshipMutation>;
export type UpdateCompanyRelationshipMutationResult = Apollo.MutationResult<UpdateCompanyRelationshipMutation>;
export type UpdateCompanyRelationshipMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyRelationshipMutation, UpdateCompanyRelationshipMutationVariables>;
export const BuildingGetIdDocument = gql`
    query BuildingGetId($where: BuildingWhereUniqueSearchOneInput!) {
  buildingGetOne(where: $where) {
    id
    name
  }
}
    `;

/**
 * __useBuildingGetIdQuery__
 *
 * To run a query within a React component, call `useBuildingGetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingGetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingGetIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBuildingGetIdQuery(baseOptions: Apollo.QueryHookOptions<BuildingGetIdQuery, BuildingGetIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingGetIdQuery, BuildingGetIdQueryVariables>(BuildingGetIdDocument, options);
      }
export function useBuildingGetIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingGetIdQuery, BuildingGetIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingGetIdQuery, BuildingGetIdQueryVariables>(BuildingGetIdDocument, options);
        }
export type BuildingGetIdQueryHookResult = ReturnType<typeof useBuildingGetIdQuery>;
export type BuildingGetIdLazyQueryHookResult = ReturnType<typeof useBuildingGetIdLazyQuery>;
export type BuildingGetIdQueryResult = Apollo.QueryResult<BuildingGetIdQuery, BuildingGetIdQueryVariables>;
export const ChannelSearchManyDocument = gql`
    query ChannelSearchMany($where: ChannelSearchManyInput!) {
  channelSearchMany(where: $where) {
    id
    createdAt
    updatedAt
    name
    reference
    period
    measurementType
    buildingId
    parentId
    channelType
  }
}
    `;

/**
 * __useChannelSearchManyQuery__
 *
 * To run a query within a React component, call `useChannelSearchManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelSearchManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelSearchManyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChannelSearchManyQuery(baseOptions: Apollo.QueryHookOptions<ChannelSearchManyQuery, ChannelSearchManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelSearchManyQuery, ChannelSearchManyQueryVariables>(ChannelSearchManyDocument, options);
      }
export function useChannelSearchManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelSearchManyQuery, ChannelSearchManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelSearchManyQuery, ChannelSearchManyQueryVariables>(ChannelSearchManyDocument, options);
        }
export type ChannelSearchManyQueryHookResult = ReturnType<typeof useChannelSearchManyQuery>;
export type ChannelSearchManyLazyQueryHookResult = ReturnType<typeof useChannelSearchManyLazyQuery>;
export type ChannelSearchManyQueryResult = Apollo.QueryResult<ChannelSearchManyQuery, ChannelSearchManyQueryVariables>;
export const CompositeChannelRelationshipsDocument = gql`
    query CompositeChannelRelationships($where: CompositeChannelGetManyInput!) {
  compositeChannelRelationships(where: $where) {
    name
    id
    composite
    isDashboardDefault
    children {
      name
      id
      composite
      isDashboardDefault
      children {
        name
        id
        composite
        isDashboardDefault
        children {
          name
          id
          composite
          isDashboardDefault
          children {
            name
            id
            composite
            isDashboardDefault
            children {
              name
              id
              composite
              isDashboardDefault
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCompositeChannelRelationshipsQuery__
 *
 * To run a query within a React component, call `useCompositeChannelRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompositeChannelRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompositeChannelRelationshipsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompositeChannelRelationshipsQuery(baseOptions: Apollo.QueryHookOptions<CompositeChannelRelationshipsQuery, CompositeChannelRelationshipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeChannelRelationshipsQuery, CompositeChannelRelationshipsQueryVariables>(CompositeChannelRelationshipsDocument, options);
      }
export function useCompositeChannelRelationshipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeChannelRelationshipsQuery, CompositeChannelRelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeChannelRelationshipsQuery, CompositeChannelRelationshipsQueryVariables>(CompositeChannelRelationshipsDocument, options);
        }
export type CompositeChannelRelationshipsQueryHookResult = ReturnType<typeof useCompositeChannelRelationshipsQuery>;
export type CompositeChannelRelationshipsLazyQueryHookResult = ReturnType<typeof useCompositeChannelRelationshipsLazyQuery>;
export type CompositeChannelRelationshipsQueryResult = Apollo.QueryResult<CompositeChannelRelationshipsQuery, CompositeChannelRelationshipsQueryVariables>;
export const ChannelMeterRelationshipsDocument = gql`
    query ChannelMeterRelationships($where: SearchChannelByBuildingIdInput!) {
  channelMeterRelationships(where: $where) {
    id
    createdAt
    updatedAt
    name
    reference
    period
    measurementType
    buildingId
    parentId
    channelType
    children {
      id
      createdAt
      updatedAt
      name
      reference
      period
      measurementType
      buildingId
      parentId
      channelType
      children {
        id
        createdAt
        updatedAt
        name
        reference
        period
        measurementType
        buildingId
        parentId
        channelType
        children {
          id
          createdAt
          updatedAt
          name
          reference
          period
          measurementType
          buildingId
          parentId
          channelType
          children {
            id
            createdAt
            updatedAt
            name
            reference
            period
            measurementType
            buildingId
            parentId
            channelType
            children {
              id
              createdAt
              updatedAt
              name
              reference
              period
              measurementType
              buildingId
              parentId
              channelType
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChannelMeterRelationshipsQuery__
 *
 * To run a query within a React component, call `useChannelMeterRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelMeterRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelMeterRelationshipsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChannelMeterRelationshipsQuery(baseOptions: Apollo.QueryHookOptions<ChannelMeterRelationshipsQuery, ChannelMeterRelationshipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelMeterRelationshipsQuery, ChannelMeterRelationshipsQueryVariables>(ChannelMeterRelationshipsDocument, options);
      }
export function useChannelMeterRelationshipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelMeterRelationshipsQuery, ChannelMeterRelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelMeterRelationshipsQuery, ChannelMeterRelationshipsQueryVariables>(ChannelMeterRelationshipsDocument, options);
        }
export type ChannelMeterRelationshipsQueryHookResult = ReturnType<typeof useChannelMeterRelationshipsQuery>;
export type ChannelMeterRelationshipsLazyQueryHookResult = ReturnType<typeof useChannelMeterRelationshipsLazyQuery>;
export type ChannelMeterRelationshipsQueryResult = Apollo.QueryResult<ChannelMeterRelationshipsQuery, ChannelMeterRelationshipsQueryVariables>;
export const GetOneCompositeChannelDocument = gql`
    query GetOneCompositeChannel($where: CompositeChannelGetOneInput!) {
  getOneCompositeChannel(where: $where) {
    id
    createdAt
    updatedAt
    name
    buildingId
    channelList
    period
    shadowId
    isDashboardDefault
  }
}
    `;

/**
 * __useGetOneCompositeChannelQuery__
 *
 * To run a query within a React component, call `useGetOneCompositeChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneCompositeChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneCompositeChannelQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOneCompositeChannelQuery(baseOptions: Apollo.QueryHookOptions<GetOneCompositeChannelQuery, GetOneCompositeChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneCompositeChannelQuery, GetOneCompositeChannelQueryVariables>(GetOneCompositeChannelDocument, options);
      }
export function useGetOneCompositeChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneCompositeChannelQuery, GetOneCompositeChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneCompositeChannelQuery, GetOneCompositeChannelQueryVariables>(GetOneCompositeChannelDocument, options);
        }
export type GetOneCompositeChannelQueryHookResult = ReturnType<typeof useGetOneCompositeChannelQuery>;
export type GetOneCompositeChannelLazyQueryHookResult = ReturnType<typeof useGetOneCompositeChannelLazyQuery>;
export type GetOneCompositeChannelQueryResult = Apollo.QueryResult<GetOneCompositeChannelQuery, GetOneCompositeChannelQueryVariables>;
export const TargetsDocument = gql`
    query Targets($where: TargetGetManyInput!) {
  targets(where: $where) {
    id
    createdAt
    updatedAt
    name
    period
    benchmark
    channelId
    targetValue
    triggerCondition
    nextExpectedTime
    lastComparisonTime
    lastComparisonValue
  }
}
    `;

/**
 * __useTargetsQuery__
 *
 * To run a query within a React component, call `useTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTargetsQuery(baseOptions: Apollo.QueryHookOptions<TargetsQuery, TargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TargetsQuery, TargetsQueryVariables>(TargetsDocument, options);
      }
export function useTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TargetsQuery, TargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TargetsQuery, TargetsQueryVariables>(TargetsDocument, options);
        }
export type TargetsQueryHookResult = ReturnType<typeof useTargetsQuery>;
export type TargetsLazyQueryHookResult = ReturnType<typeof useTargetsLazyQuery>;
export type TargetsQueryResult = Apollo.QueryResult<TargetsQuery, TargetsQueryVariables>;
export const AlertsDocument = gql`
    query Alerts($where: AlertGetManyInput!) {
  alerts(where: $where) {
    id
    createdAt
    updatedAt
    userId
    targetId
    destination
    messageType
    severity
  }
}
    `;

/**
 * __useAlertsQuery__
 *
 * To run a query within a React component, call `useAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAlertsQuery(baseOptions: Apollo.QueryHookOptions<AlertsQuery, AlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AlertsQuery, AlertsQueryVariables>(AlertsDocument, options);
      }
export function useAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlertsQuery, AlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AlertsQuery, AlertsQueryVariables>(AlertsDocument, options);
        }
export type AlertsQueryHookResult = ReturnType<typeof useAlertsQuery>;
export type AlertsLazyQueryHookResult = ReturnType<typeof useAlertsLazyQuery>;
export type AlertsQueryResult = Apollo.QueryResult<AlertsQuery, AlertsQueryVariables>;
export const BuildingGetManyDocument = gql`
    query BuildingGetMany($where: BuildingWhereUniqueSearchManyInput!) {
  buildingGetMany(where: $where) {
    id
    name
    projectRef
    slug
    picture
    thumb
    epcRating
    epcUrl
    breeamRating
    breeamScore
    constructionYear
    floorArea
    hasFileServer
    hasDataServer
    dashboardPeriod
    uploadConfig {
      name
      type
    }
    companyRelationships {
      companyId
      buildingName
      companyReference
      companyReferenceName
      relationshipType
    }
    address {
      buildingId
      companyId
      houseNameOrNumber
      street
      city
      region
      country
      postcode
      latitude
      longitude
    }
    portfolio {
      renewables {
        measurement
        target
      }
      waterUsage {
        measurement
        target
      }
      carbonDioxide {
        measurement
        target
      }
      wholeBuilding {
        measurement
        target
      }
    }
  }
}
    `;

/**
 * __useBuildingGetManyQuery__
 *
 * To run a query within a React component, call `useBuildingGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingGetManyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBuildingGetManyQuery(baseOptions: Apollo.QueryHookOptions<BuildingGetManyQuery, BuildingGetManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingGetManyQuery, BuildingGetManyQueryVariables>(BuildingGetManyDocument, options);
      }
export function useBuildingGetManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingGetManyQuery, BuildingGetManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingGetManyQuery, BuildingGetManyQueryVariables>(BuildingGetManyDocument, options);
        }
export type BuildingGetManyQueryHookResult = ReturnType<typeof useBuildingGetManyQuery>;
export type BuildingGetManyLazyQueryHookResult = ReturnType<typeof useBuildingGetManyLazyQuery>;
export type BuildingGetManyQueryResult = Apollo.QueryResult<BuildingGetManyQuery, BuildingGetManyQueryVariables>;
export const CompanyDocument = gql`
    query Company($where: CompanyWhereUnique!) {
  company(where: $where) {
    name
    portfolioImage
    slug
    logo
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    token
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const BuildingGetOneDocument = gql`
    query BuildingGetOne($where: BuildingWhereUniqueSearchOneInput!) {
  buildingGetOne(where: $where) {
    id
    name
    projectRef
    slug
    picture
    thumb
    epcRating
    epcUrl
    breeamRating
    breeamScore
    constructionYear
    floorArea
    hasFileServer
    hasDataServer
    dashboardPeriod
    uploadConfig {
      name
      type
    }
    companyRelationships {
      buildingId
      buildingName
      companyId
      companyReference
      companyReferenceName
      relationshipType
    }
    address {
      buildingId
      companyId
      houseNameOrNumber
      street
      city
      region
      country
      postcode
      latitude
      longitude
    }
    portfolio {
      renewables {
        measurement
        target
        comment
        comparison
      }
      waterUsage {
        measurement
        target
        comment
        comparison
      }
      carbonDioxide {
        measurement
        target
        comment
        comparison
      }
      wholeBuilding {
        measurement
        target
        comment
        comparison
      }
      heatingEnergy {
        measurement
        target
        comment
        comparison
      }
      coolingEnergy {
        measurement
        target
        comment
        comparison
      }
      processEnergy {
        measurement
        target
        comment
        comparison
      }
      socketPower {
        measurement
        target
        comment
        comparison
      }
      lightingEnergy {
        measurement
        target
        comment
        comparison
      }
      ventilation {
        measurement
        target
        comment
        comparison
      }
    }
  }
}
    `;

/**
 * __useBuildingGetOneQuery__
 *
 * To run a query within a React component, call `useBuildingGetOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingGetOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingGetOneQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBuildingGetOneQuery(baseOptions: Apollo.QueryHookOptions<BuildingGetOneQuery, BuildingGetOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingGetOneQuery, BuildingGetOneQueryVariables>(BuildingGetOneDocument, options);
      }
export function useBuildingGetOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingGetOneQuery, BuildingGetOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingGetOneQuery, BuildingGetOneQueryVariables>(BuildingGetOneDocument, options);
        }
export type BuildingGetOneQueryHookResult = ReturnType<typeof useBuildingGetOneQuery>;
export type BuildingGetOneLazyQueryHookResult = ReturnType<typeof useBuildingGetOneLazyQuery>;
export type BuildingGetOneQueryResult = Apollo.QueryResult<BuildingGetOneQuery, BuildingGetOneQueryVariables>;
export const CompaniesDocument = gql`
    query Companies($where: CompanyWhereSearch!) {
  companies(where: $where) {
    id
    createdAt
    updatedAt
    name
    slug
    portfolioImage
    logo
    primaryColor
    secondaryColor
    fontColor
    userIds
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const DataGetManyDocument = gql`
    query DataGetMany($where: DataInput!) {
  dataGetMany(where: $where) {
    id
    name
    data {
      datumId
      channelId
      timestamp
      value
      status
    }
  }
}
    `;

/**
 * __useDataGetManyQuery__
 *
 * To run a query within a React component, call `useDataGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataGetManyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDataGetManyQuery(baseOptions: Apollo.QueryHookOptions<DataGetManyQuery, DataGetManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataGetManyQuery, DataGetManyQueryVariables>(DataGetManyDocument, options);
      }
export function useDataGetManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataGetManyQuery, DataGetManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataGetManyQuery, DataGetManyQueryVariables>(DataGetManyDocument, options);
        }
export type DataGetManyQueryHookResult = ReturnType<typeof useDataGetManyQuery>;
export type DataGetManyLazyQueryHookResult = ReturnType<typeof useDataGetManyLazyQuery>;
export type DataGetManyQueryResult = Apollo.QueryResult<DataGetManyQuery, DataGetManyQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard($where: DashboardInput!) {
  dashboard(where: $where) {
    id
    createdAt
    updatedAt
    buildingId
    buildingName
    occupierLogo
    dashboardStyle
    renewables {
      measurement
      target
      comment
      comparison
    }
    carbonDioxide {
      measurement
      target
      comment
      comparison
    }
    waterUsage {
      measurement
      target
      comment
      comparison
    }
    wholeBuilding {
      measurement
      target
      comment
      comparison
    }
    ventilation {
      measurement
      target
      comment
      comparison
    }
    coolingEnergy {
      measurement
      target
      comment
      comparison
    }
    heatingEnergy {
      measurement
      target
      comment
      comparison
    }
    lightingEnergy {
      measurement
      target
      comment
      comparison
    }
    socketPower {
      measurement
      target
      comment
      comparison
    }
    processEnergy {
      measurement
      target
      comment
      comparison
    }
    period
    dashboardColors {
      primaryColor
      secondaryColor
      fontColor
    }
    tryMessage
    infoMessage
    currentConsumption
    currentGeneration
    lifetimeCarbonSavings
    lifetimeCarbonSavingsUnits
    address {
      companyId
      city
      postcode
      latitude
      longitude
    }
    weatherAPI {
      key
      host
    }
    transportAPI {
      id
      key
    }
  }
}
    `;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDashboardQuery(baseOptions: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const UserGetManyDocument = gql`
    query UserGetMany($where: UserGetManyInput!) {
  userGetMany(where: $where) {
    id
    createdAt
    updatedAt
    companyId
    email
    firstName
    lastName
    slug
    picture
    role
    customPermissions
  }
}
    `;

/**
 * __useUserGetManyQuery__
 *
 * To run a query within a React component, call `useUserGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetManyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserGetManyQuery(baseOptions: Apollo.QueryHookOptions<UserGetManyQuery, UserGetManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGetManyQuery, UserGetManyQueryVariables>(UserGetManyDocument, options);
      }
export function useUserGetManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGetManyQuery, UserGetManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGetManyQuery, UserGetManyQueryVariables>(UserGetManyDocument, options);
        }
export type UserGetManyQueryHookResult = ReturnType<typeof useUserGetManyQuery>;
export type UserGetManyLazyQueryHookResult = ReturnType<typeof useUserGetManyLazyQuery>;
export type UserGetManyQueryResult = Apollo.QueryResult<UserGetManyQuery, UserGetManyQueryVariables>;
export const CompanyListDocument = gql`
    query CompanyList($where: CompanyWhereSearch!) {
  companies(where: $where) {
    id
    name
  }
}
    `;

/**
 * __useCompanyListQuery__
 *
 * To run a query within a React component, call `useCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompanyListQuery(baseOptions: Apollo.QueryHookOptions<CompanyListQuery, CompanyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyListQuery, CompanyListQueryVariables>(CompanyListDocument, options);
      }
export function useCompanyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyListQuery, CompanyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyListQuery, CompanyListQueryVariables>(CompanyListDocument, options);
        }
export type CompanyListQueryHookResult = ReturnType<typeof useCompanyListQuery>;
export type CompanyListLazyQueryHookResult = ReturnType<typeof useCompanyListLazyQuery>;
export type CompanyListQueryResult = Apollo.QueryResult<CompanyListQuery, CompanyListQueryVariables>;
export const EmailBuildingOverviewDocument = gql`
    query EmailBuildingOverview($where: EmailBuildingOverviewInput!) {
  emailBuildingOverview(where: $where) {
    result
  }
}
    `;

/**
 * __useEmailBuildingOverviewQuery__
 *
 * To run a query within a React component, call `useEmailBuildingOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailBuildingOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailBuildingOverviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEmailBuildingOverviewQuery(baseOptions: Apollo.QueryHookOptions<EmailBuildingOverviewQuery, EmailBuildingOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailBuildingOverviewQuery, EmailBuildingOverviewQueryVariables>(EmailBuildingOverviewDocument, options);
      }
export function useEmailBuildingOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailBuildingOverviewQuery, EmailBuildingOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailBuildingOverviewQuery, EmailBuildingOverviewQueryVariables>(EmailBuildingOverviewDocument, options);
        }
export type EmailBuildingOverviewQueryHookResult = ReturnType<typeof useEmailBuildingOverviewQuery>;
export type EmailBuildingOverviewLazyQueryHookResult = ReturnType<typeof useEmailBuildingOverviewLazyQuery>;
export type EmailBuildingOverviewQueryResult = Apollo.QueryResult<EmailBuildingOverviewQuery, EmailBuildingOverviewQueryVariables>;
export const GetManyCompositeChannelDocument = gql`
    query GetManyCompositeChannel($where: CompositeChannelGetManyInput!) {
  getManyCompositeChannel(where: $where) {
    id
    createdAt
    updatedAt
    name
    buildingId
    channelList
    period
    shadowId
  }
}
    `;

/**
 * __useGetManyCompositeChannelQuery__
 *
 * To run a query within a React component, call `useGetManyCompositeChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyCompositeChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyCompositeChannelQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetManyCompositeChannelQuery(baseOptions: Apollo.QueryHookOptions<GetManyCompositeChannelQuery, GetManyCompositeChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyCompositeChannelQuery, GetManyCompositeChannelQueryVariables>(GetManyCompositeChannelDocument, options);
      }
export function useGetManyCompositeChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyCompositeChannelQuery, GetManyCompositeChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyCompositeChannelQuery, GetManyCompositeChannelQueryVariables>(GetManyCompositeChannelDocument, options);
        }
export type GetManyCompositeChannelQueryHookResult = ReturnType<typeof useGetManyCompositeChannelQuery>;
export type GetManyCompositeChannelLazyQueryHookResult = ReturnType<typeof useGetManyCompositeChannelLazyQuery>;
export type GetManyCompositeChannelQueryResult = Apollo.QueryResult<GetManyCompositeChannelQuery, GetManyCompositeChannelQueryVariables>;