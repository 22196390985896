import {
  Button,
  Center,
  GridItem,
  Heading,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import FormDrawer, {
  FormDataType,
} from '../../components/formDrawer/formDrawer'
import ListTable from '../../components/listTable/listTable'
import { GET_COMPANIES } from '../../graphql/API/queries'
import {
  CompanyCreateInput,
  useCompaniesQuery,
  useCreateCompanyMutation,
  useUserGetManyQuery,
  CompaniesQuery,
  User,
} from '../../graphql/generated/graphql'
import { useAuth } from '../../hooks/use-auth'
import { isCurrentUserStaff } from '../../utils/basicFunc/basicFunc'
import { allDrawerInputs } from '../../utils/pageDrawerInputLists/inputLists'
import { tableDataConstruct } from '../../utils/tableDataConstruct/tableDataConstruct'

const breadcrumbLinks = [
  { text: 'Home', route: '/' },
  { text: 'Companies', route: '/companies' },
]

const Companies = () => {
  const [isOpen, setDrawerVisible] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false)
  const { data: companyData } = useCompaniesQuery({
    variables: { where: {} },
  })
  const [createCompany] = useCreateCompanyMutation()
  const { data: userData } = useUserGetManyQuery({
    variables: { where: {} },
  })
  const userCheckboxOptions: { text: string; value: string }[] = (
    userData?.userGetMany || []
  ).map((user: Partial<User> | null) => {
    return { text: user?.firstName!, value: user?.id! }
  })
  const toast = useToast()
  const Auth = useAuth(),
    { user } = Auth,
    { isStaff } = user,
    havePermissions = isStaff ?? isCurrentUserStaff()

  const onClose = () => {
    setDrawerVisible(false)
  }

  const onDrawerFormSubmit = (formData: FormDataType) => {
    setButtonLoader(true)
    createCompany({
      variables: { data: formData as CompanyCreateInput },
      onCompleted: (data) => {
        setDrawerVisible(false)
        setButtonLoader(false)
      },
      update: (cache, { data }) => {
        const companyData = cache.readQuery<CompaniesQuery>({
          query: GET_COMPANIES,
          variables: { where: {} },
        })
        if (companyData) {
          const { companies } = companyData
          cache.writeQuery({
            query: GET_COMPANIES,
            variables: { where: {} },
            data: { companies: [...companies, data?.companyCreate] },
          })
        }
      },
      onError: (error) => {
        toast({
          title: error.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }
  const constructedData = tableDataConstruct({
    data: companyData && companyData.companies,
    page: 'companies',
  })

  return (
    <SimpleGrid columns={12} padding={{ base: '0 15px', md: 0 }}>
      <GridItem
        colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
        colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
      >
        <Breadcrumbs links={breadcrumbLinks} />
        <Center justifyContent={'space-between'} mt={'1%'}>
          <Heading fontSize={{ base: '24px', md: '28px', lg: '30px' }}>
            List of Companies
          </Heading>
          {havePermissions && (
            <Button onClick={() => setDrawerVisible(true)}>
              + Add Company
            </Button>
          )}
        </Center>
        <ListTable
          constructedData={constructedData}
          page={'companiesEdit'}
          formDrawerProps={{ checkboxOptions: userCheckboxOptions }}
        />
        <FormDrawer
          openedFrom={'Company'}
          isCreate={true}
          isOpen={isOpen}
          onClose={onClose}
          inputElements={allDrawerInputs['companies']}
          onDrawerFormSubmit={onDrawerFormSubmit}
          loading={buttonLoader}
        />
      </GridItem>
    </SimpleGrid>
  )
}

export default Companies
