import { CardData } from '../../Pages/buildings/dashboardWrapper'
import {
  CombiWithAddedProps,
  RelWithAddedProps,
} from '../../Pages/channels/channels'
import { PossibleDataTypesWithUndefined } from '../../components/listTable/listTable'
import {
  ChannelSearchManyQuery,
  DataGetManyQuery,
} from '../../graphql/generated/graphql'
import { SecondaryGraphData } from '../../elements/building/energyUse'

type CSVType = {
  timestamp: string
  // value: number
  // channelName: string
  [key: string]: string | number
}

export function isCurrentUserAdmin() {
  return Boolean(localStorage.getItem('user-is-admin')).valueOf()
}

export function isCurrentUserStaff() {
  return Boolean(localStorage.getItem('user-is-staff')).valueOf()
}

export function camelCaseToNormal(string: string) {
  return string.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export function simulateLoading() {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      // Resolve the promise after the specified delay
      resolve()
    }, 2000) // 2000 milliseconds (2 seconds) delay
  })
}

// Function is mainly to change all the key name 'id' to 'channelId'. Should ask backend to analyse other ways to do
export function changeKeyRecursively<T extends RelWithAddedProps>(
  array: T | T[] | null,
  oldKey: keyof T,
  newKey: string,
  keysToKeep: string[],
): T | T[] | null {
  if (array === null) {
    return array
  }

  if (Array.isArray(array)) {
    return array.map((item) =>
      changeKeyRecursively(item, oldKey, newKey, keysToKeep),
    ) as T[]
  }

  // If not an array, must be an object
  const newObj: Record<string, unknown> = {}
  for (const untypedKey of Object.keys(array)) {
    const key = untypedKey as keyof T
    if (key !== oldKey && !keysToKeep.includes(untypedKey)) {
      continue
    }
    if (Array.isArray(array[key])) {
      const subArray = array[key] as T[]
      if (key === oldKey) {
        newObj[newKey as string] = changeKeyRecursively(
          subArray,
          oldKey,
          newKey,
          keysToKeep,
        ) as unknown as keyof T
      } else {
        newObj[key as string] = changeKeyRecursively(
          subArray,
          oldKey,
          newKey,
          keysToKeep,
        ) as unknown as keyof T
      }
    } else {
      if (key === oldKey && keysToKeep.includes(newKey)) {
        newObj[newKey as string] = array[key]
      } else if (keysToKeep.includes(key as string)) {
        newObj[key as string] = array[key]
      }
    }
  }
  return newObj as T
}

export const sortTree = (
  arr: (CombiWithAddedProps | RelWithAddedProps)[],
  from: string,
): (CombiWithAddedProps | RelWithAddedProps)[] => {
  let sortedArr: (CombiWithAddedProps | RelWithAddedProps)[] =
    structuredClone(arr)
  if (from === 'combi') {
    sortedArr.sort((a, b) => childrenSort(a, b))
  } else {
    sortedArr.sort((a, b) => ascendingSort(a, b))
  }

  let flaggedObject = null
  const newArray: (CombiWithAddedProps | RelWithAddedProps | null)[] =
    sortedArr.map((obj) => {
      obj.singleChild = false
      if (obj?.children?.length) {
        const updatedChildren = sortTree(
          obj.children as (CombiWithAddedProps | RelWithAddedProps)[],
          from,
        )
        obj['hasVirtual'] = obj.children.some(
          (channel) => channel?.channelType === 'virtual',
        )
        obj = { ...obj, children: updatedChildren }
      } else {
        obj['children'] = []
        obj['hasVirtual'] = false
      }
      if (from === 'rel') {
        if (obj?.children?.length) {
          const allFixedChannels = (obj.children as RelWithAddedProps[]).every(
            (channel) => channel.channelType === 'actual',
          )

          ;(obj.children as RelWithAddedProps[]).forEach((channel) => {
            channel.singleChild =
              obj.children?.length === 2 && !allFixedChannels
          })
        }
        if (obj.channelType === 'virtual') {
          flaggedObject = obj
          return null
        }
        if (obj.channelType === 'shadow') {
          return null
        }
      }
      return obj!
    })

  if (flaggedObject) {
    newArray.unshift(flaggedObject)
  }

  return newArray.filter(Boolean) as (CombiWithAddedProps | RelWithAddedProps)[]
}

export const ascendingSort = (
  a: CombiWithAddedProps | RelWithAddedProps,
  b: CombiWithAddedProps | RelWithAddedProps,
) => {
  let x = a.name?.toLowerCase()
  let y = b.name?.toLowerCase()
  if (x && y) {
    if (x < y) {
      return -1
    }
    if (x > y) {
      return 1
    }
  }
  return 0
}

export const childrenSort = (
  a: CombiWithAddedProps | RelWithAddedProps,
  b: CombiWithAddedProps | RelWithAddedProps,
) => {
  let x = (a as CombiWithAddedProps).composite
  let y = (b as CombiWithAddedProps).composite
  let xIsDashboardDefault = (a as CombiWithAddedProps).isDashboardDefault
  let yIsDashboardDefault = (b as CombiWithAddedProps).isDashboardDefault
  if (xIsDashboardDefault && !yIsDashboardDefault && y) {
    return -1
  } else if (!xIsDashboardDefault && yIsDashboardDefault && x) {
    return 1
  } else {
    if (x && !y) {
      return 1
    } else if (!x && y) {
      return -1
    } else {
      return 0
    }
  }
}

function deepEqual(
  obj1: PossibleDataTypesWithUndefined,
  obj2: PossibleDataTypesWithUndefined,
): boolean {
  if (obj1 === obj2) {
    return true
  }

  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export function areArraysOfObjectsEqual(
  arr1:
    | (CombiWithAddedProps | RelWithAddedProps)[]
    | PossibleDataTypesWithUndefined,
  arr2:
    | (CombiWithAddedProps | RelWithAddedProps)[]
    | PossibleDataTypesWithUndefined,
) {
  if (arr1?.length !== arr2?.length) {
    return false
  }

  const sortedArr1 = arr1?.slice().sort(compareObjects)
  const sortedArr2 = arr2?.slice().sort(compareObjects)

  return deepEqual(
    sortedArr1 as PossibleDataTypesWithUndefined,
    sortedArr2 as PossibleDataTypesWithUndefined,
  )
}

function compareObjects(
  obj1: CombiWithAddedProps | RelWithAddedProps,
  obj2: CombiWithAddedProps | RelWithAddedProps,
) {
  const hash1 = JSON.stringify(obj1)
  const hash2 = JSON.stringify(obj2)
  return hash1.localeCompare(hash2)
}

export const topLargest = (
  secondary: CardData[] | SecondaryGraphData[],
  top: number,
) => {
  const sortedData = secondary.sort((a, b) => b.measurement - a.measurement)
  return sortedData.slice(0, top)
}

export const top3ByPercent = (secondary: CardData[]) => {
  const dataWithPercentage = secondary.map((item) => ({
    ...item,
    percentage: item.target ? (item.measurement / item.target) * 100 : 0,
  }))

  const sortedData = dataWithPercentage.sort(
    (a, b) => b.percentage - a.percentage,
  )

  return sortedData.slice(0, 3)
}

export async function isValidImageUrl(url: string) {
  try {
    const img = new Image()
    await new Promise((resolve, reject) => {
      img.onload = resolve
      img.onerror = reject
      img.src = url
    })
    return true
  } catch (error) {
    return false
  }
}

export function slugify(input: string) {
  return input
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/[^\w-]+/g, '') // Remove non-word characters except dashes
    .replace(/--+/g, '-') // Replace multiple dashes with a single dash
}

export function minMaxTimeStamp(selectedChannelData: DataGetManyQuery[]) {
  const allData = selectedChannelData.flatMap(
    (channel) => channel?.dataGetMany.data,
  )

  // Use the reduce function to find the minimum and maximum timestamps
  const { minTimestamp, maxTimestamp } = allData.reduce(
    (acc, item) => {
      const timestamp = new Date(item.timestamp).getTime()

      if (!acc.minTimestamp || timestamp < acc.minTimestamp) {
        acc.minTimestamp = timestamp
      }

      if (!acc.maxTimestamp || timestamp > acc.maxTimestamp) {
        acc.maxTimestamp = timestamp
      }

      return acc
    },
    { minTimestamp: 0, maxTimestamp: 0 },
  )

  return {
    minTimestamp: new Date(minTimestamp),
    maxTimestamp: new Date(maxTimestamp),
  }
}

export function flattenGraphData(selectedChannelData: DataGetManyQuery[]) {
  return selectedChannelData.flatMap((channel) => channel?.dataGetMany.data)
}

export function filterDataByTimestamp({
  selectedChannelData,
  startDate,
  endDate,
}: {
  selectedChannelData: DataGetManyQuery[]
  startDate: number
  endDate: number
}) {
  return selectedChannelData.map(({ dataGetMany }) => ({
    dataGetMany: {
      ...dataGetMany,
      data: dataGetMany.data.filter(({ timestamp }) => {
        const timestampDate = new Date(timestamp)
        return (
          timestampDate.getTime() >= startDate &&
          timestampDate.getTime() <= endDate
        )
      }),
    },
  }))
}

export function uniqueElement({
  arr1,
  arr2,
}: {
  arr1: string[]
  arr2: string[]
}) {
  return arr1.filter((item) => !arr2.includes(item))
}

// export function debounce({func, delay}:{func: (arg1:number, arg2:number) => void, delay:number}) {
//     let timeoutId;
//     return (...args) => {
//       clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => {
//         func(...args);
//       }, delay);
//     };
//   };

export const convertJsonToCsv = ({
  datasets,
  buildingName,
  channelListData,
}: {
  datasets: DataGetManyQuery[]
  buildingName: string
  channelListData: ChannelSearchManyQuery['channelSearchMany']
}) => {
  if (!datasets || datasets.length === 0) {
    return
  }

  const allChannels: string[] = datasets.map(
    (channelData) =>
      channelListData.find(
        (channel) => channelData.dataGetMany.data[0].channelId === channel?.id,
      )?.name ?? '',
  )
  // Flatten the datasets into a single array of BuildingDetailsArrayProps
  const flattenedData: CSVType[] = datasets.flatMap((dataset, i) =>
    dataset.dataGetMany.data.map((datum) => ({
      timestamp: datum.timestamp,
      // value: datum.value ?? 0,
      [allChannels[i]]: datum.value ?? 0,
    })),
  )

  const resultMap = new Map()

  // Iterate over the input data
  flattenedData.forEach((item) => {
    const { timestamp, ...rest } = item

    // Check if the timestamp already exists in the Map
    if (resultMap.has(timestamp)) {
      // If it exists, update the existing item by merging properties
      const existingItem = resultMap.get(timestamp)
      resultMap.set(timestamp, { ...existingItem, ...rest })
    } else {
      // If it doesn't exist, add a new entry to the Map
      resultMap.set(timestamp, { timestamp, ...rest })
    }
  })

  // Convert the Map values back to an array
  const resultArray = Array.from(resultMap.values())

  if (resultArray.length > 0) {
    const items = resultArray
    const replacer = (
      key: string,
      value: Partial<DataGetManyQuery['dataGetMany']['data']>,
    ) => (value === null ? '' : value)
    const header = ['timestamp', ...allChannels]
    const csv = [
      header.join(','), // header row first
      ...items.map((row: CSVType) =>
        header
          .map((fieldName: string) => JSON.stringify(row[fieldName], replacer))
          .join(','),
      ),
    ].join('\r\n')
    const blob = new Blob([csv], { type: 'data:text/csv;charset=utf-8,' })
    const blobURL = window.URL.createObjectURL(blob)

    // Create new tag for download file
    const anchor = document.createElement('a')
    anchor.download = `${buildingName}-selected-channels.csv` // change the name
    anchor.href = blobURL
    anchor.dataset.downloadurl = [
      'text/csv',
      anchor.download,
      anchor.href,
    ].join(':')
    anchor.click()

    // Remove URL.createObjectURL. The browser should not save the reference to the file.
    setTimeout(() => {
      // For Firefox, it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(blobURL)
    }, 100)
  }
}
