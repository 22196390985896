import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TableContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  Center,
  useToast,
} from '@chakra-ui/react'
import { FormEvent, useEffect, useState } from 'react'
import {
  camelCaseToNormal,
  isCurrentUserStaff,
} from '../../utils/basicFunc/basicFunc'
import {
  tableDataConstruct,
  tableHeaders,
} from '../../utils/tableDataConstruct/tableDataConstruct'
import FormDrawer, {
  FormDataType,
} from '../../components/formDrawer/formDrawer'
import { mapKeyValue } from '../../utils/pageDrawerInputLists/inputLists'
import InfoAlert from '../../components/infoAlert/infoAlert'
import { useAuth } from '../../hooks/use-auth'
import {
  Alert,
  AlertCreateInput,
  AlertUpdateInput,
  Target,
  TargetCreateInput,
  TargetUpdateInput,
  useAlertCreateMutation,
  useAlertDeleteMutation,
  useAlertsLazyQuery,
  useAlertUpdateMutation,
  useTargetCreateMutation,
  useTargetDeleteMutation,
  useTargetsLazyQuery,
  useTargetUpdateMutation,
} from '../../graphql/generated/graphql'

type AlertsPropsType = {
  isOpen: boolean
  onClose: () => void
  channelId: string
}

export type AlertTargetCombined = Target &
  Alert & { alertId?: string } & { [key: string]: string | number }

type AlertTableProps = {
  constructedData: AlertTargetCombined[]
  onEdit: (arg: AlertTargetCombined) => void
  onDelete: (arg: AlertTargetCombined) => void
}

const initialData: AlertTargetCombined[] = [
  {
    name: 'Alert',
    period: 'hourly',
    triggerCondition: 'none',
    severity: 'silenced',
  },
]

const AlertTable = ({ constructedData, onEdit, onDelete }: AlertTableProps) => {
  const Auth = useAuth(),
    { user } = Auth,
    { isStaff } = user,
    havePermissions = isStaff ?? isCurrentUserStaff()
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            {tableHeaders['targets'].map((datum: string) => (
              <Th key={datum}>{camelCaseToNormal(datum)}</Th>
            ))}
            <Th pt={'1.5%'} pb={'1.5%'} rowSpan={2}>
              Action
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {constructedData.map((values: AlertTargetCombined, i: number) => (
            <Tr key={i}>
              {tableHeaders['targets'].map((datum) => (
                <Td key={datum} textTransform={'capitalize'}>
                  {datum === 'triggerCondition'
                    ? camelCaseToNormal(values[datum]!).split(' ')[0]
                    : values[datum]}
                </Td>
              ))}
              <Td>
                {havePermissions && (
                  <>
                    <Button
                      variant={'outline'}
                      colorScheme={'purple.500'}
                      onClick={() => onEdit(values)}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme={'red'}
                      ml={4}
                      onClick={() => onDelete(values)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

const Alerts = ({ isOpen, onClose, channelId }: AlertsPropsType) => {
  const [targets, setTargets] = useState<AlertTargetCombined[]>([])
  const [constructedData, setConstructedData] = useState<
    AlertTargetCombined[] | undefined | null
  >([])
  const [clickedElement, setClickedElement] = useState<AlertTargetCombined>()
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [isDeleteOpen, setDeleteModal] = useState(false)
  const toast = useToast()
  const Auth = useAuth(),
    { user } = Auth,
    { isStaff } = user,
    havePermissions = isStaff ?? isCurrentUserStaff()
  const [getAlerts] = useAlertsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      const { alerts } = response
      setTargets((prevTargets) => {
        return prevTargets.map((target) => {
          const matchingAlert = alerts.find(
            (alert) => alert.targetId === target.id,
          )
          if (matchingAlert) {
            const { id, severity } = matchingAlert
            return {
              ...target,
              severity,
              alertId: id,
            }
          }
          return target
        }) as AlertTargetCombined[]
      })
    },
  })
  const [editTargets] = useTargetUpdateMutation({
    onError: (err) => {
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })
  const [editAlerts, { loading: alertLoader }] = useAlertUpdateMutation({
    onError: (err) => {
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })
  const [deleteTarget] = useTargetDeleteMutation({
    onError: (err) => {
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })
  const [deleteAlert, { loading: deleteLoader }] = useAlertDeleteMutation({
    onCompleted: () => {
      toast({
        title: 'Alert deleted successfully',
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: (err) => {
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  const [getTargets] = useTargetsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { where: { channelId } },
    onCompleted: async (response) => {
      setTargets(response.targets as AlertTargetCombined[])
      for (const target of response.targets) {
        await getAlerts({ variables: { where: { targetId: target.id } } })
      }
    },
  })

  useEffect(() => {
    if (isOpen) {
      getTargets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const [createAlert] = useAlertCreateMutation()

  const [createTarget] = useTargetCreateMutation({
    onCompleted: (response) => {
      let queryObject = {
        targetId: response.targetCreate.id,
        severity: initialData[0].severity,
        messageType: 'email',
        destination: '',
      }
      createAlert({
        variables: { where: queryObject as AlertCreateInput },
        onCompleted: (alertResponse) => {
          const { severity, id } = alertResponse.alertCreate
          let newTarget = {
            alertId: id,
            severity,
            ...response.targetCreate,
          }
          toast({
            title: 'Alert created successfully',
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
          setTargets([...targets, newTarget as AlertTargetCombined])
        },
        onError: (err) => {
          toast({
            title: err.message,
            status: 'error',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
        },
      })
    },
  })

  useEffect(() => {
    setConstructedData(
      tableDataConstruct({
        data: targets,
        page: 'targets',
      }) as AlertTargetCombined[],
    )
  }, [targets])

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  const onModalClose = () => {
    setTargets([])
    onClose()
  }

  const onAddAlert = () => {
    const { targetValue, triggerCondition, period, name } = initialData[0]
    let constructedData = {
      targetValue,
      triggerCondition,
      period,
      channelId,
      name,
    }
    createTarget({ variables: { data: constructedData as TargetCreateInput } })
  }

  const onConfirmDelete = () => {
    deleteTarget({
      variables: { data: { id: clickedElement?.id! } },
      onCompleted: () => {
        deleteAlert({
          variables: { where: { id: clickedElement?.alertId! } },
          onCompleted: () => {
            let deletedTarget = [...targets]
            deletedTarget = deletedTarget.filter(
              (target) => target.id !== clickedElement,
            )
            setDeleteModal(false)
            setTargets(deletedTarget)
          },
        })
      },
    })
  }

  const onAlertFormSubmit = (formData: FormDataType) => {
    const targetMutationVariable = {
        id: clickedElement?.id,
        period: formData.period,
        name: formData.name,
        triggerCondition: formData.triggerCondition,
        benchmark: null,
        targetValue: formData.targetValue ? +formData.targetValue : undefined,
      } as TargetUpdateInput,
      alertMutationVariable = {
        id: clickedElement?.alertId,
        severity: formData.severity,
      } as AlertUpdateInput
    let queryObject = {
      targetId: targetMutationVariable.id,
      severity: formData.severity,
      messageType: 'email',
      destination: '',
    } as AlertCreateInput
    editTargets({
      variables: { data: targetMutationVariable },
      onCompleted: (targetEditResp) => {
        if (alertMutationVariable.id) {
          editAlerts({
            variables: { where: alertMutationVariable },
            onCompleted: () => {
              let changedTarget: AlertTargetCombined[] =
                structuredClone(targets)
              changedTarget = changedTarget.map((target) =>
                clickedElement?.id === target.id
                  ? { ...target, ...formData }
                  : target,
              ) as AlertTargetCombined[]
              toast({
                title: 'Alert updated successfully',
                status: 'success',
                position: 'top',
                duration: 3000,
                isClosable: true,
              })
              setDrawerOpen(false)
              setTargets(changedTarget)
            },
          })
        } else {
          createAlert({
            variables: { where: queryObject },
            onCompleted: (alertResponse) => {
              const { severity, id } = alertResponse.alertCreate
              let newAlert = {
                alertId: id,
                severity,
              }
              toast({
                title: 'Alert updated successfully',
                status: 'success',
                position: 'top',
                duration: 3000,
                isClosable: true,
              })
              let editedTarget: AlertTargetCombined[] = structuredClone(targets)
              editedTarget = editedTarget.map((target) =>
                target.id === targetEditResp.targetUpdate.id
                  ? {
                      ...targetMutationVariable,
                      ...newAlert,
                    }
                  : target,
              ) as AlertTargetCombined[]

              setDrawerOpen(false)
              setTargets(editedTarget)
            },
          })
        }
      },
    })
  }

  const onDelete = (values: AlertTargetCombined) => {
    setDeleteModal(true)
    setClickedElement(values)
  }

  const onEdit = (values: AlertTargetCombined) => {
    setDrawerOpen(true)
    setClickedElement(values)
  }

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={'full'}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={onSubmit}>
            <ModalHeader>Add and Edit targets</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {constructedData?.length ? (
                <AlertTable
                  constructedData={constructedData!}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ) : (
                <Center>
                  <Heading>No Data</Heading>
                </Center>
              )}
            </ModalBody>

            <ModalFooter>
              {havePermissions && (
                <Button
                  colorScheme={'purple'}
                  mr={3}
                  variant={'outline'}
                  onClick={onAddAlert}
                >
                  Add
                </Button>
              )}

              <Button onClick={onModalClose}>Close</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <FormDrawer
        openedFrom={'Alert'}
        isCreate={false}
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        loading={alertLoader}
        inputElements={mapKeyValue({ page: 'targets', data: clickedElement! })}
        onDrawerFormSubmit={onAlertFormSubmit}
      />
      <InfoAlert
        isOpen={isDeleteOpen}
        onClose={() => setDeleteModal(false)}
        onSubmit={onConfirmDelete}
        headerText={`Delete ${clickedElement?.name}`}
        bodyText={"Are you sure? You can't undo this action afterwards."}
        buttonText={'Delete'}
        loading={deleteLoader}
      />
    </>
  )
}

export default Alerts
