import { Button } from '@chakra-ui/button'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control'
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import { Input, InputGroup } from '@chakra-ui/input'
import { Box, Center, Heading, Text, Link } from '@chakra-ui/layout'
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/toast'
import { t } from 'i18next'
import { ChangeEvent, Suspense, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  useChangePasswordMutation,
  useResetPasswordMutation,
  useSetPasswordMutation,
} from '../graphql/generated/graphql'
import { camelCaseToNormal } from '../utils/basicFunc/basicFunc'

type PasswordConditions = {
  moreThanTwelveCharacters: boolean
  upperCase: boolean
  lowerCase: boolean
  number: boolean
  specialCharacters: boolean
  moreThanTwentyTwoCharacter: boolean
}

type PasswordChecklistProps = {
  passwordConditions: PasswordConditions
}

interface FormData {
  password: string
  currentPassword: string
  confirmPassword: string
  email: string
}

const PASSWORD_CONDITIONS: PasswordConditions = {
  moreThanTwelveCharacters: true,
  upperCase: true,
  lowerCase: true,
  number: true,
  specialCharacters: true,
  moreThanTwentyTwoCharacter: true,
}
const PasswordChecklist = ({ passwordConditions }: PasswordChecklistProps) => {
  return (
    <Box>
      {Object.entries(passwordConditions).map(
        ([type, value]) =>
          type !== 'moreThanTwentyTwoCharacter' && (
            <Center
              justifyContent={'start'}
              color={value ? 'red' : 'green'}
              textTransform={'capitalize'}
              fontSize={13}
            >
              {value ? <WarningIcon mr={2} /> : <CheckCircleIcon mr={2} />}
              {camelCaseToNormal(type)}
            </Center>
          ),
      )}
      <Center fontWeight={'bold'}>OR</Center>
      <Center
        justifyContent={'start'}
        color={passwordConditions.moreThanTwentyTwoCharacter ? 'red' : 'green'}
        fontSize={13}
      >
        {passwordConditions.moreThanTwentyTwoCharacter ? (
          <WarningIcon mr={2} />
        ) : (
          <CheckCircleIcon mr={2} />
        )}
        {camelCaseToNormal(
          'You can have more than 22 characters to bypass all the above',
        )}
      </Center>
    </Box>
  )
}

const SetResetPassword = () => {
  const [searchParams] = useSearchParams(),
    token = searchParams.get('token'),
    navigate = useNavigate()
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<FormData>({ mode: 'onChange' })
  const location = useLocation(),
    { pathname } = location,
    toast = useToast()
  const [passwordConditions, setPasswordConditions] =
    useState<PasswordConditions>({
      ...PASSWORD_CONDITIONS,
    })
  const password = watch('password')
  let title = pathname.includes('change') ? 'Change Password' : 'Set Password'
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#?!@$%^&*\\-]).{12,150}$|^.{22,150}$/i

  if (pathname.includes('reset')) {
    title = 'Forgot Password'
  }
  const [resetPassword] = useResetPasswordMutation({
    onCompleted: (response) => {
      toast({
        title: response.resetPassword.message,
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: (err) => {
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })
  const [setPassword] = useSetPasswordMutation({
    onCompleted: (response) => {
      toast({
        title: response.setPassword.message,
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: (err) => {
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
  })
  const [changePassword] = useChangePasswordMutation()

  const onSubmit = (data: FormData) => {
    if (pathname.includes('reset')) {
      resetPassword({
        variables: data,
        onCompleted: () => {
          navigate('/signin')
        },
      })
    } else if (pathname.includes('verify')) {
      setPassword({
        variables: { password: data.confirmPassword, token: token! },
        onCompleted: () => {
          navigate('/signin')
        },
      })
    } else if (pathname.includes('change')) {
      changePassword({
        variables: {
          oldPassword: data.currentPassword,
          newPassword: data.password,
        },
        onCompleted: (response) => {
          navigate('/signin', { replace: true })
          toast({
            title: response.changePassword.message,
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
        },
      })
    }
  }

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e,
      { value } = target
    const newConditions: PasswordConditions = {
      moreThanTwelveCharacters: value.length < 12 || value.length > 22,
      upperCase: !/[A-Z]/.test(value) || value.length > 22,
      lowerCase: !/[a-z]/.test(value) || value.length > 22,
      number: !/\d/.test(value) || value.length > 22,
      specialCharacters: !/[#?!@$%^&*-]/.test(value) || value.length > 22,
      moreThanTwentyTwoCharacter: value.length <= 22,
    }

    setPasswordConditions(newConditions)
  }

  return (
    <Suspense
      fallback={
        <Modal isOpen={true} onClose={() => {}} size="md" isCentered>
          <ModalOverlay
            bgGradient={
              'radial-gradient(circle, purple.300 0%, purple.500 80%, purple.800 100%)'
            }
          />
        </Modal>
      }
    >
      <Modal isOpen={true} onClose={() => {}} size="md" isCentered>
        <ModalOverlay
          bgGradient={
            'radial-gradient(circle, purple.300 0%, purple.500 80%, purple.800 100%)'
          }
        />
        <ModalContent>
          <ModalBody p={30}>
            <Center mb={10}>
              <Heading>{title}</Heading>
            </Center>
            <form onSubmit={handleSubmit(onSubmit)}>
              {pathname.includes('reset') ? (
                <FormControl
                  variant="floating"
                  id="email"
                  isInvalid={!!errors.email}
                >
                  <Input
                    type="email"
                    placeholder={''}
                    {...register('email', {
                      required: 'requiredItem',
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'requiredEmail',
                      },
                    })}
                  />
                  <FormLabel>Email</FormLabel>
                  {errors.email ? (
                    <FormErrorMessage minH={8}>
                      {t((errors.email?.message as string) || '')}
                    </FormErrorMessage>
                  ) : (
                    <Box minH={8} mt={2}></Box>
                  )}
                </FormControl>
              ) : (
                <>
                  {pathname.includes('change') && (
                    <FormControl
                      variant="floating"
                      id="currentPassword"
                      isInvalid={!!errors.currentPassword}
                      mt={4}
                    >
                      <InputGroup>
                        <Input
                          type={'currentPassword'}
                          placeholder=" "
                          {...register('currentPassword', {
                            required: 'Please fill in the field',
                          })}
                        />
                        <FormLabel>Current Password</FormLabel>
                      </InputGroup>
                      <Box minH={8} mt={2}></Box>
                    </FormControl>
                  )}

                  <FormControl
                    variant="floating"
                    id="password"
                    isInvalid={!!errors.password}
                    mt={4}
                  >
                    <InputGroup>
                      <Input
                        type={'password'}
                        placeholder=" "
                        {...register('password', {
                          onChange: (e) => onPasswordChange(e),
                          required: 'Please fill in the field',
                          pattern: {
                            value: passwordRegex,
                            message: '',
                          },
                        })}
                      />
                      <FormLabel>Set Password</FormLabel>
                    </InputGroup>
                    <Box minH={8} mt={2}>
                      {Object.values(passwordConditions).some(
                        (value) => !value,
                      ) && (
                        <PasswordChecklist
                          passwordConditions={passwordConditions}
                        />
                      )}
                    </Box>
                  </FormControl>

                  <FormControl
                    variant="floating"
                    id="confirmPassword"
                    isInvalid={!!errors.confirmPassword}
                    mt={4}
                  >
                    <InputGroup>
                      <Input
                        type={'password'}
                        placeholder=" "
                        {...register('confirmPassword', {
                          required: 'Please fill in the field',
                          validate: (value) =>
                            value === password || 'Passwords do not match', // Custom validation
                        })}
                      />
                      <FormLabel>Confirm Password</FormLabel>
                    </InputGroup>
                    {errors.confirmPassword ? (
                      <FormErrorMessage minH={8}>
                        {errors.confirmPassword.message as string}
                      </FormErrorMessage>
                    ) : (
                      <Box minH={8} mt={2}></Box>
                    )}
                  </FormControl>
                </>
              )}
              <Center justifyContent={'space-between'}>
                {pathname.includes('reset') && (
                  <Box float={'left'}>
                    <Text color={'purple'}>
                      <Link href={'/signin'}>Back to login</Link>
                    </Text>
                  </Box>
                )}

                <Button colorScheme={'purple'} type={'submit'} float={'right'}>
                  Submit
                </Button>
              </Center>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Suspense>
  )
}

export default SetResetPassword
