import { useEffect, useState } from 'react'
import { GridItem, SimpleGrid } from '@chakra-ui/react'

import { useParams } from 'react-router-dom'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'

import {
  ChannelSearchManyQuery,
  useBuildingGetIdQuery,
  useChannelSearchManyLazyQuery,
  useGetManyCompositeChannelLazyQuery,
} from '../../graphql/generated/graphql'
import { BuildingDetails } from '../../components/buildingDetails/buildingDetails'

export type BuildingDetailsArrayProps = {
  timestamp: string
  value: number
  [key: string]: string | number
}

export type BuildingDetailsProps = {
  loading: boolean
  channelListData: ChannelSearchManyQuery['channelSearchMany']
  channelListDataLoading: boolean
  buildingName: string | undefined
}

export const WrappedBuildingDetails = () => {
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown_building'
  const breadcrumbLinks = [
    { text: 'Home', route: '/' },
    { text: 'Buildings', route: '/buildings' },
    { text: buildingSlug, route: `/buildings/${buildingSlug}` },
    { text: 'details', route: `/buildings/${buildingSlug}/details` },
  ]

  const [channelListData, setChannelListData] = useState<
    ChannelSearchManyQuery['channelSearchMany']
  >([])

  const { data: buildingData } = useBuildingGetIdQuery({
    variables: { where: { slug: buildingSlug } },
  })

  const [
    getChannelList,
    { data: channelData, loading: channelListDataLoading },
  ] = useChannelSearchManyLazyQuery()

  const [getCombiList, { data: combiList, loading: combiListLoading }] =
    useGetManyCompositeChannelLazyQuery()

  useEffect(() => {
    if (channelData?.channelSearchMany && combiList?.getManyCompositeChannel) {
      setChannelListData(
        channelData?.channelSearchMany
          .filter((channel) => channel?.channelType !== 'shadow')
          .concat(combiList.getManyCompositeChannel),
      )
    }
  }, [channelData, combiList])

  useEffect(() => {
    if (buildingData?.buildingGetOne) {
      getChannelList({
        variables: {
          where: {
            buildingId: buildingData.buildingGetOne.id as string,
          },
        },
      })
      getCombiList({
        variables: {
          where: {
            buildingId: buildingData.buildingGetOne.id as string,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingData])

  return (
    <>
      <SimpleGrid columns={12}>
        <GridItem
          colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
          colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
          p={{ base: '5px', md: 0 }}
        >
          <Breadcrumbs links={breadcrumbLinks} />
          <BuildingDetails
            loading={channelListDataLoading || combiListLoading}
            channelListData={channelListData}
            buildingName={buildingData?.buildingGetOne?.name!}
            channelListDataLoading={channelListDataLoading}
          />
        </GridItem>
      </SimpleGrid>
    </>
  )
}
