import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useToast,
  //useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useEmailBuildingOverviewQuery } from '../../../graphql/generated/graphql'
//import { useState } from 'react'

const DetailedData = (where: { buildingId?: string; companyId?: string }) => {
  const { t } = useTranslation('building')
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown building'
  const buildingId = where.buildingId
  const companyId = where.companyId

  const hrefDataDetailsFunction = () => {
    window.location.href = `/buildings/${buildingSlug}/details`
  }
  const hrefChannelConfigFunction = () => {
    window.location.href = `/buildings/${buildingSlug}/channels`
  }
  const toast = useToast()

  const emailBuilding = useEmailBuildingOverviewQuery({
    variables: {
      where: {
        buildingId: buildingId || '',
        companyId: companyId || '',
      },
    },
    onCompleted: (response) => {
      toast({
        title: t('emailSentResult'),
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: (err) => {
      toast({
        title: err.message,
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    },
    skip: true,
  })

  return (
    <Box
      py={{ base: 2, md: 6 }}
      px={{ base: '0.5rem', md: 6 }}
      background={'gray.300'}
      textColor={'purple.600'}
      h="100%"
    >
      <Box
        background={'white'}
        p={4}
        height={'100%'}
        display="flex"
        flexDirection="column"
        justifyContent={'space-between'}
      >
        <Box>
          <Heading as="h3" size="md">
            {t('detailedData')}
          </Heading>
          <Text py={6}>{t('detailedDataDesc')}</Text>
        </Box>

        <Box>
          <Button
            colorScheme={'purple.500'}
            width={'100%'}
            variant={'outline'}
            onClick={() => hrefChannelConfigFunction()}
          >
            {t('channelConfig')}
          </Button>

          <Stack direction="row" spacing={2} paddingTop={2}>
            <Button
              variant={'outline'}
              colorScheme={'purple.500'}
              width={'100%'}
              onClick={() => hrefDataDetailsFunction()}
              p={'4px'}
              fontSize={'15px'}
            >
              {t('data')}
            </Button>
            {emailBuilding ? (
              <Button
                colorScheme={'purple.500'}
                ml={1}
                width={'100%'}
                variant={'outline'}
                onClick={() => emailBuilding?.refetch()}
                whiteSpace={'normal'}
                p={'4px'}
                fontSize={'15px'}
              >
                {t('emailSummary')}
              </Button>
            ) : undefined}
          </Stack>
        </Box>
      </Box>
    </Box>
  )

  // return (
  //   <Box py={6} pl={6} pr={3} background={'gray.300'} textColor={'purple.600'}>
  //     <Box background={'white'} p={4}>
  //       <Heading as="h3" size="md">
  //         Generate Report
  //       </Heading>
  //       <RadioGroup py={4} onChange={setDuration} value={duration}>
  //         <Stack direction={'row'} spacing={4}>
  //           <Radio value="1">year</Radio>
  //           <Radio value="2">month</Radio>
  //           <Radio value="3">week</Radio>
  //           <Radio value="4">day</Radio>
  //         </Stack>
  //       </RadioGroup>
  //       <Center>
  //         <Button
  //           onClick={() => {
  //             toast({
  //               title: 'Report Requested',
  //               description:
  //                 'The requested report will be sent to the project lead engineer via email',
  //               status: 'success',
  //               duration: 9000,
  //               isClosable: true,
  //             })
  //           }}
  //           colorScheme={'purple'}
  //         >
  //           Request
  //         </Button>
  //       </Center>
  //     </Box>
  //   </Box>
  // )
}

export default DetailedData
