import { Grid, GridItem } from '@chakra-ui/layout'

const DashboardTypes = ({ type }: { type: string }) => {
  return type === 'full' ? (
    <Grid
      h="80px"
      w="160px"
      templateRows="repeat(3, auto)"
      templateColumns="repeat(8, auto)"
      gap={0.5}
      style={{ transition: '0.3s all' }}
      _hover={{ filter: 'brightness(0.7)', transition: '0.3s all' }}
      mr={10}
    >
      <GridItem bg="purple.500" colSpan={2} rowSpan={2} />
      <GridItem bg="purple.500" colSpan={2} rowSpan={2} />
      <GridItem bg="purple.500" colSpan={2} rowSpan={2} />
      <GridItem rowSpan={2} colSpan={2} bg="purple.500" />
      <GridItem bg="purple.500" colSpan={3} />
      <GridItem bg="purple.500" colSpan={3} />
      <GridItem bg="purple.500" colSpan={2} />
    </Grid>
  ) : (
    <Grid
      h="80px"
      w="160px"
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(3, 1fr)"
      gap={0.5}
      mr={10}
      style={{ transition: '0.3s all' }}
      _hover={{ filter: 'brightness(0.7)', transition: '0.3s all' }}
    >
      <GridItem bg="purple.500" />
      <GridItem bg="purple.500" />
      <GridItem bg="purple.500" />
      <GridItem bg="purple.500" />
      <GridItem bg="purple.500" />
      <GridItem bg="purple.500" />
    </Grid>
  )
}

export default DashboardTypes
