import { Box, Text, useMediaQuery } from '@chakra-ui/react'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'
import Info from '../icons/info'

type comparisonProps = {
  fontSize: number | string | undefined
  centerAlign?: boolean
  p: string
  text: string | undefined
  isSmallCard?: boolean
  color: string
}

const Comparison = ({
  fontSize = 15,
  centerAlign,
  color,
  p,
  text,
  isSmallCard = false,
}: comparisonProps) => {
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  return (
    <Box
      display={'flex'}
      p={p}
      color={color}
      fontSize={fontSize}
      alignItems={'center'}
      justifyContent={centerAlign ? 'center' : 'start'}
    >
      <Info fontSize={fontSize} />
      <Text
        ml={{
          base: 2,
          md: 3,
          lg: 2,
          xl: 3,
          '2xl': is1440p ? 3 : is4k ? 5 : 3,
        }}
      >
        {text}
      </Text>
    </Box>
  )
}

export default Comparison
