export type IndicatorType = {
  [key: string]: {
    type: string
    name: string
    unit: string
    description: string
    upIsBetter: boolean
    miniName: string
  }
}

export const indicatorTypeData: IndicatorType = {
  CarbonDioxide: {
    type: 'CarbonDioxide',
    // iconNudgeLeft: 3,
    // iconNudgeDown: 0,
    name: 'Carbon Dioxide',
    unit: 'tCO₂',
    description: 'total carbon emissions',
    upIsBetter: false,
    miniName: 'Carbon',
  },
  Cooling: {
    type: 'Cooling',
    // iconNudgeLeft: 0,
    // iconNudgeDown: 0,
    name: 'Cooling Energy',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Cooling',
  },
  Heating: {
    type: 'Heating',
    // iconNudgeLeft: 0,
    // iconNudgeDown: 0,
    name: 'Heating Energy',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Heating',
  },
  HotWater: {
    type: 'HotWater',
    // iconNudgeLeft: 0,
    // iconNudgeDown: 0,
    name: 'Hot Water',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Hot',
  },
  Lighting: {
    type: 'Lighting',
    // iconNudgeLeft: 0,
    // iconNudgeDown: 0,
    name: 'Lighting Energy',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Lighting',
  },
  ProcessPower: {
    type: 'ProcessPower',
    // iconNudgeLeft: 3,
    // iconNudgeDown: 0,
    name: 'Process Energy',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Process',
  },
  Renewables: {
    type: 'Renewables',
    name: 'Renewable Energy',
    // iconNudgeLeft: 5,
    // iconNudgeDown: 1,
    unit: 'MWh',
    description: 'total energy generated',
    upIsBetter: true,
    miniName: 'Renewables',
  },
  SmallPower: {
    type: 'SmallPower',
    // iconNudgeLeft: 2,
    // iconNudgeDown: 0,
    name: 'Socket Power',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Socket',
  },
  TotalElectricity: {
    type: 'TotalElectricity',
    // iconNudgeLeft: 0,
    // iconNudgeDown: 0,
    name: 'Total Electricity',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Electricity',
  },
  TotalGas: {
    type: 'TotalGas',
    // iconNudgeLeft: 0,
    // iconNudgeDown: 0,
    name: 'Total Gas',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Gas',
  },
  Ventilation: {
    type: 'Ventilation',
    // iconNudgeLeft: 3,
    // iconNudgeDown: 0,
    name: 'Ventilation Energy',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Ventilation',
  },
  WaterUsage: {
    type: 'WaterUsage',
    // iconNudgeLeft: 0,
    // iconNudgeDown: 0,
    name: 'Water Usage',
    unit: 'm³',
    description: 'total water used',
    upIsBetter: false,
    miniName: 'Water',
  },
  WholeBuilding: {
    type: 'WholeBuilding',
    // iconNudgeLeft: 3,
    // iconNudgeDown: 0,
    name: 'Building Energy',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Building',
  },
  Cafe: {
    type: 'Cafe',
    // iconNudgeLeft: 3,
    // iconNudgeDown: 0,
    name: 'Cafe',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Cafe',
  },
  Lab: {
    type: 'Lab',
    // iconNudgeLeft: 0,
    // iconNudgeDown: 0,
    name: 'Lab',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Lab',
  },
  Office: {
    type: 'Office',
    // iconNudgeLeft: 3,
    // iconNudgeDown: 0,
    name: 'Office',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Office',
  },
  Education: {
    type: 'Education',
    // iconNudgeLeft: 4,
    // iconNudgeDown: 0,
    name: 'Education',
    unit: 'MWh',
    description: 'total energy used',
    upIsBetter: false,
    miniName: 'Education',
  },
}
