import {
  Button,
  Center,
  ComponentWithAs,
  GridItem,
  Heading,
  IconProps,
  SimpleGrid,
  useToast,
  Box,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import FormDrawer, {
  FormDataType,
} from '../../components/formDrawer/formDrawer'
import PfHeadline from '../../components/pfHeadline/pfHeadline'
import PfTable, { BuildingProps } from '../../components/pfTable/pfTable'
import Hero from '../../elements/hero'
import carbonIcon from '../../elements/icons/co2'
import renewablesIcon from '../../elements/icons/renewables'
import waterUsageIcon from '../../elements/icons/waterUsage'
import wholeBuildingIcon from '../../elements/icons/wholeBuilding'
import Title from '../../elements/title'
import { GET_BUILDINGS } from '../../graphql/API/queries'
import {
  BuildingGetManyQuery,
  Company,
  PortfolioTypes,
  useBuildingGetManyQuery,
  useCompaniesQuery,
  useCompanyLazyQuery,
  BuildingCreateInput,
  useBuildingCreateMutation,
} from '../../graphql/generated/graphql'
import { SelectOptionProps } from '../../elements/formElements/formElements'
import { useAuth } from '../../hooks/use-auth'
import { isCurrentUserStaff } from '../../utils/basicFunc/basicFunc'
import { allDrawerInputs } from '../../utils/pageDrawerInputLists/inputLists'
export interface HeadlineItems {
  Icon: ComponentWithAs<'svg', IconProps>
  label: string
  value: number
  target: number
  units: string
  description: string
  upIsBetter: boolean
}

export interface BuildingsProps {
  loading: boolean
  company:
    | {
        name: string
        portfolioImage?: string | null
        slug: string
      }
    | undefined
  headlineItems: HeadlineItems[] | undefined
  buildings: BuildingProps[] | undefined
}

const breadcrumbLinks = [
  { text: 'Home', route: '/' },
  { text: 'Buildings', route: '/buildings' },
]

export const Buildings = ({
  loading,
  company,
  headlineItems,
  buildings,
}: BuildingsProps) => {
  const toast = useToast()
  const [isOpen, setDrawerVisible] = useState(false)
  const { data: companyData } = useCompaniesQuery({
    variables: { where: {} },
  })
  const [companySelectOptions, setCompanyOptions] = useState<
    SelectOptionProps[] | undefined
  >([])
  const Auth = useAuth(),
    { user } = Auth,
    { isStaff } = user,
    havePermissions = isStaff ?? isCurrentUserStaff()
  const [createBuilding, { loading: createBuildingLoader }] =
    useBuildingCreateMutation()

  useEffect(() => {
    let selectOptions: SelectOptionProps[] | undefined = []
    selectOptions = companyData?.companies?.map((datum: Partial<Company>) => {
      return { text: datum.name!, value: datum.id! }
    })
    setCompanyOptions(selectOptions)
  }, [companyData])

  const onClose = () => {
    setDrawerVisible(false)
  }

  const onDrawerFormSubmit = (formData: FormDataType) => {
    const {
      city,
      companyId,
      companyRelationshipType,
      country,
      epcRating,
      breeamRating,
      breeamScore,
      constructionYear,
      houseNameOrNumber,
      picture,
      postcode,
      projectRef,
      region,
      slug,
      street,
      name,
      epcUrl,
      dashboardStyle,
      floorArea,
    } = formData
    const data = {
      name,
      companyId,
      companyRelationshipType,
      epcRating,
      epcUrl,
      breeamRating,
      breeamScore: breeamScore ? +breeamScore : undefined,
      constructionYear: constructionYear ? +constructionYear : undefined,
      picture,
      dashboardStyle,
      projectRef,
      slug,
      floorArea: floorArea ? +floorArea : undefined,
      thumb: picture,
      uploadConfig: [],
      hasFileServer: true,
      hasDataServer: true,
      address: {
        city: city ?? '',
        country: country ?? '',
        houseNameOrNumber: houseNameOrNumber ?? '',
        postcode: postcode ?? '',
        region: region ?? '',
        street: street ?? '',
      },
    }
    createBuilding({
      variables: { data: data as BuildingCreateInput },
      refetchQueries: [
        {
          query: GET_BUILDINGS,
          variables: { where: {} },
        },
      ],
      onCompleted: () => {
        setDrawerVisible(false)
        toast({
          title: 'Building created succesfully',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }
  const mapBuildingsData = buildings?.map((building: BuildingProps) => {
    return {
      id: building.id,
      name: building.name,
      address: building.address,
    }
  })

  return (
    <Box padding={{ base: '0 15px', md: 0 }} mb={'5em'}>
      <SimpleGrid columns={12}>
        <GridItem
          colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
          colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
        >
          <Breadcrumbs links={breadcrumbLinks} />
          <Title loading={loading}>{company?.name}</Title>

          <Hero
            mapBuildingsData={mapBuildingsData}
            imageUrl={company?.portfolioImage!}
          />
          <PfHeadline items={headlineItems ?? []} loading={loading} />

          <Center justifyContent={'space-between'} mt={'2em'} mb={'1em'}>
            <Center>
              <Heading fontSize={{ base: '28px', md: '2.25rem' }}>
                List of Buildings
              </Heading>
              &nbsp;
              <Text fontSize={{ base: 20 }} color={'grey'} mt={{ md: '4px' }}>
                ({buildings?.length})
              </Text>
            </Center>
            {havePermissions && (
              <Button onClick={() => setDrawerVisible(true)}>
                + Create Building
              </Button>
            )}
          </Center>
          <FormDrawer
            openedFrom={'Building'}
            isCreate={true}
            isOpen={isOpen}
            onClose={onClose}
            inputElements={allDrawerInputs['buildingsCreate']}
            onDrawerFormSubmit={onDrawerFormSubmit}
            selectOptions={companySelectOptions}
            loading={createBuildingLoader}
            isSlug={true}
          />
          <PfTable buildings={buildings ?? []} loading={loading} />
        </GridItem>
      </SimpleGrid>
    </Box>
  )
}

const WrappedBuildings = () => {
  const auth = useAuth()
  const whereVariable =
    // isCurrentUserStaff()
    false ? { where: { company: auth.user.companyId } } : { where: {} }
  const { data: buildingsData, loading } = useBuildingGetManyQuery({
    variables: whereVariable,
  })
  const [getCompany, { data: companyData }] = useCompanyLazyQuery()
  useEffect(() => {
    if (buildingsData?.buildingGetMany) {
      getCompany({
        variables: {
          where: { id: auth.user.companyId },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData])

  const wholeBuilding = {
    Icon: wholeBuildingIcon,
    label: 'buildingEnergy',
    units: 'kWh',
    description: 'totalEnergyUsed',
    upIsBetter: false,
    value: 0,
    target: 0,
  }

  const renewables = {
    Icon: renewablesIcon,
    label: 'renewables',
    units: 'kWh',
    description: 'totalGenerated',
    upIsBetter: true,
    value: 0,
    target: 0,
  }

  const carbon = {
    Icon: carbonIcon,
    label: 'carbonDioxide',
    units: 'tCO<sub>2</sub>',
    description: 'totalEmissions',
    upIsBetter: false,
    value: 0,
    target: 0,
  }

  const water = {
    Icon: waterUsageIcon,
    label: 'water',
    units: 'm<sup>3<sup>',
    description: 'totalWaterUsed',
    upIsBetter: false,
    value: 0,
    target: 0,
  }

  const unknown = {
    Icon: wholeBuildingIcon,
    label: 'Unknown',
    type: 'unknown',
    units: '',
    description: 'unknown total',
    upIsBetter: false,
    value: 0,
    target: 0,
  }

  const buildings: BuildingGetManyQuery['buildingGetMany'] | undefined =
    buildingsData?.buildingGetMany
  const portfolios: Partial<PortfolioTypes>[] = (buildings || [])?.map(
    (building) => building?.portfolio! as Partial<PortfolioTypes>,
  )

  for (const portfolio of portfolios) {
    wholeBuilding.value += portfolio?.wholeBuilding?.measurement ?? 0
    wholeBuilding.target += portfolio?.wholeBuilding?.target ?? 0

    carbon.value += portfolio?.carbonDioxide?.measurement ?? 0
    carbon.target += portfolio?.carbonDioxide?.target ?? 0

    water.value += portfolio?.waterUsage?.measurement ?? 0
    water.target += portfolio?.waterUsage?.target ?? 0

    renewables.value += portfolio?.renewables?.measurement ?? 0
    renewables.target += portfolio?.renewables?.target ?? 0
  }

  const headlineItems: HeadlineItems[] = [
    wholeBuilding,
    renewables,
    carbon,
    water,
    unknown,
  ]

  const buildingsWithTargets = buildings?.map((building) => {
    const findCompanyRelation = building?.companyRelationships.find(
      (relationship) => relationship.companyId === auth.user.companyId,
    )
    return {
      name: findCompanyRelation
        ? findCompanyRelation.buildingName
        : building?.name,
      slug: building?.slug,
      thumbnail: building?.picture ?? '',
      epcRating: building?.epcRating ?? 'Unknown',
      epcUrl: building?.epcUrl ?? '',
      floorArea: building?.floorArea ?? 0,
      floorAreaDisplay: 0,
      picture: building?.picture ?? '',
      projectRef: building?.projectRef ?? 'reference',
      company: building?.companyRelationships ?? 'companyId',
      id: building?.id ?? '',
      address: building?.address ?? {},
      tlData: [
        {
          type: 'energy',
          target: (building?.portfolio?.wholeBuilding?.target || 0) * 1000 ?? 0,
          value:
            (building?.portfolio?.wholeBuilding?.measurement || 0) * 1000 ?? 0,
          units: 'Wh',
        },
        {
          type: 'renewables',
          target: (building?.portfolio?.renewables?.target || 0) * 1000 ?? 0,
          value:
            (building?.portfolio?.renewables?.measurement || 0) * 1000 ?? 0,
          units: 'Wh',
        },
        {
          type: 'carbon',
          target: (building?.portfolio?.carbonDioxide?.target || 0) * 1000 ?? 0,
          value:
            (building?.portfolio?.carbonDioxide?.measurement || 0) * 1000 ?? 0,
          units: 'kg',
        },
      ],
    } as BuildingProps
  })

  // getParams here
  return (
    <Buildings
      loading={loading}
      company={companyData?.company}
      headlineItems={headlineItems}
      buildings={buildingsWithTargets}
    />
  )
}

export default WrappedBuildings
