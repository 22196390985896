import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  Link,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Address } from '../../../graphql/generated/graphql'

interface BuildingInformationProps {
  loading: boolean
  leadEngName?: string
  clientName?: string
  clientLeadName?: string
  epcRating?: string
  breeamRating?: string
  breeamScore?: number
  constructionYear?: number
  floorArea?: number
  epcUrl?: string
  address?: Address
}

const BuildingInformation = ({
  loading,
  epcRating,
  breeamRating,
  breeamScore,
  constructionYear,
  floorArea,
  epcUrl,
  address,
}: BuildingInformationProps) => {
  const { t, i18n } = useTranslation('building')

  const showAddress: boolean =
    address &&
    (address.houseNameOrNumber ||
      address.street ||
      address.city ||
      address.region ||
      address.country ||
      address.postcode)
      ? true
      : false

  return (
    <Box bgColor={'purple.700'} textColor={'gray.300'} h="100%">
      <SimpleGrid columns={4}>
        {/* <GridItem
          colSpan={10}
          display={{ base: 'none', md: 'flex' }}
        ></GridItem> */}
        <GridItem colSpan={{ base: 4, md: 3 }}>
          <Center width={'100%'}>
            <Box paddingLeft={'10%'} w="100%" paddingRight="1em">
              <Stack direction="column" width={'100%'} spacing={1} my={10}>
                <Heading as="h3" size="md">
                  {t('buildingInformation').toLocaleUpperCase(
                    i18n.resolvedLanguage,
                  )}
                </Heading>
                <Spacer />
                {/* <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <GridItem w="100%">{t('leadEng')}</GridItem>
                  <GridItem w="100%" h="6">
                    <Skeleton isLoaded={!loading}>
                      {leadEngName ?? 'Eddie Van Halen'}
                    </Skeleton>
                  </GridItem>
                </Grid> */}
                {constructionYear ? (
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <GridItem w="100%" minH="6">
                      {t('constructionYear')}
                    </GridItem>
                    <GridItem w="100%" h="6">
                      <Skeleton isLoaded={!loading}>
                        <Text>{constructionYear}</Text>
                      </Skeleton>
                    </GridItem>
                  </Grid>
                ) : undefined}

                {breeamRating && breeamScore ? (
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <GridItem w="100%" minH="6">
                      {t('breeamRating')}
                    </GridItem>
                    <GridItem w="100%" minH="6">
                      <Skeleton isLoaded={!loading}>
                        <Text>
                          {breeamRating} ({Math.floor(breeamScore)}%)
                        </Text>
                      </Skeleton>
                    </GridItem>
                  </Grid>
                ) : undefined}

                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <GridItem w="100%" h="6">
                    {t('epcRating')}
                  </GridItem>
                  <GridItem w="100%" h="6">
                    <Skeleton isLoaded={!loading}>
                      {(epcUrl && <Link href={epcUrl}>{epcRating}</Link>) || (
                        <Text>{epcRating}</Text>
                      )}
                    </Skeleton>
                  </GridItem>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <GridItem w="100%" h="6">
                    {t('floorArea')}
                  </GridItem>
                  <GridItem w="100%" h="6">
                    <Skeleton isLoaded={!loading}>
                      {floorArea?.toLocaleString()}&thinsp;m<sup>2</sup>
                    </Skeleton>
                  </GridItem>
                </Grid>

                {showAddress && (
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <GridItem h="6">{t('address')}</GridItem>
                    <GridItem h="100%" textOverflow={'ellipsis'}>
                      <Skeleton isLoaded={!loading}>
                        <Text>
                          {address?.houseNameOrNumber} {address?.street}
                        </Text>

                        <Text>{address?.city}</Text>
                        <Text>{address?.region}</Text>
                        <Text>{address?.country}</Text>
                        <Text>{address?.postcode}</Text>
                      </Skeleton>
                    </GridItem>
                  </Grid>
                )}
              </Stack>
            </Box>
          </Center>
        </GridItem>
      </SimpleGrid>
    </Box>
  )
}

export default BuildingInformation
