import { gql } from '@apollo/client'

export const UPDATE_METER_RELATIONSHIP = gql`
  mutation ChannelRelationshipTreeUpdate($data: [ChannelUpdateInput!]!) {
    channelRelationshipTreeUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      uploadLabel
      reference
      period
      measurementType
      buildingId
      parentId
      channelType
      children {
        id
        createdAt
        updatedAt
        name
        uploadLabel
        reference
        period
        measurementType
        buildingId
        parentId
        channelType
        children {
          id
          createdAt
          updatedAt
          name
          uploadLabel
          reference
          period
          measurementType
          buildingId
          parentId
          channelType
        }
      }
    }
  }
`

export const CREATE_COMBI_CHANNEL = gql`
  mutation CompositeChannelCreate($data: CompositeChannelCreateInput!) {
    compositeChannelCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      buildingId
      channelList
      period
      shadowId
      isDashboardDefault
    }
  }
`

export const EDIT_COMBI_CHANNEL = gql`
  mutation CompositeChannelUpdate($data: CompositeChannelUpdateInput!) {
    compositeChannelUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      buildingId
      channelList
      period
      shadowId
      isDashboardDefault
    }
  }
`
export const DELETE_COMBI_CHANNEL = gql`
  mutation CompositeChannelDelete($data: ChannelUniqueIdInput!) {
    compositeChannelDelete(data: $data) {
      message
    }
  }
`

export const CREATE_VIRTUAL_CHANNEL = gql`
  mutation ChannelCreate($data: ChannelCreateInput!) {
    channelCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      uploadLabel
      reference
      period
      measurementType
      buildingId
      parentId
      channelType
    }
  }
`

export const UPDATE_COMPOSITE_RELATIONSHIP = gql`
  mutation CompositeChannelRelationshipUpdate($data: [MixedChannelInput!]!) {
    compositeChannelRelationshipUpdate(data: $data) {
      name
      id
      composite
      isDashboardDefault
      children {
        name
        id
        composite
        isDashboardDefault
        children {
          name
          id
          composite
          isDashboardDefault
          children {
            name
            id
            composite
            isDashboardDefault
            children {
              name
              id
              composite
              isDashboardDefault
              children {
                name
                id
                composite
                isDashboardDefault
              }
            }
          }
        }
      }
    }
  }
`
export const EDIT_USERS = gql`
  mutation UserUpdate($data: UserChangeInput!) {
    userUpdate(data: $data) {
      id
      createdAt
      updatedAt
      companyId
      email
      firstName
      lastName
      slug
      picture
      role
      customPermissions
    }
  }
`
export const EDIT_COMPANIES = gql`
  mutation CompanyUpdate($data: CompanyUpdateInput!) {
    companyUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      slug
      portfolioImage
      logo
      primaryColor
      secondaryColor
      fontColor
      userIds
    }
  }
`
export const EDIT_CHANNELS = gql`
  mutation ChannelUpdate($data: ChannelUpdateInput!) {
    channelUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      uploadLabel
      reference
      period
      measurementType
      buildingId
      parentId
      channelType
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($data: UserDeleteInput!) {
    userDelete(data: $data) {
      message
    }
  }
`

export const DELETE_COMPANY = gql`
  mutation deleteCompany($data: CompanyDeleteInput!) {
    companyDelete(data: $data) {
      message
    }
  }
`

export const DELETE_CHANNEL = gql`
  mutation ChannelDelete($data: ChannelUniqueIdInput!) {
    channelDelete(data: $data) {
      message
    }
  }
`

export const CREATE_TARGET = gql`
  mutation TargetCreate($data: TargetCreateInput!) {
    targetCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      period
      benchmark
      channelId
      targetValue
      triggerCondition
      nextExpectedTime
      lastComparisonTime
      lastComparisonValue
    }
  }
`

export const DELETE_TARGET = gql`
  mutation TargetDelete($data: TargetDeleteInput!) {
    targetDelete(data: $data) {
      message
    }
  }
`

export const UPDATE_TARGET = gql`
  mutation TargetUpdate($data: TargetUpdateInput!) {
    targetUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      period
      benchmark
      channelId
      targetValue
      triggerCondition
      nextExpectedTime
      lastComparisonTime
      lastComparisonValue
    }
  }
`

export const CREATE_ALERT = gql`
  mutation AlertCreate($where: AlertCreateInput!) {
    alertCreate(where: $where) {
      id
      createdAt
      updatedAt
      id
      destination
      messageType
      severity
    }
  }
`

export const UPDATE_ALERT = gql`
  mutation AlertUpdate($where: AlertUpdateInput!) {
    alertUpdate(where: $where) {
      id
      createdAt
      updatedAt
      id
      destination
      messageType
      severity
    }
  }
`

export const DELETE_ALERT = gql`
  mutation AlertDelete($where: AlertDeleteInput!) {
    alertDelete(where: $where) {
      message
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      message
    }
  }
`

export const SET_PASSWORD = gql`
  mutation SetPassword($password: String!, $token: String!) {
    setPassword(password: $password, token: $token) {
      message
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      message
    }
  }
`

export const CREATE_BUILDING = gql`
  mutation BuildingCreate($data: BuildingCreateInput!) {
    buildingCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      projectRef
      slug
      picture
      thumb
      epcRating
      epcUrl
      floorArea
      hasFileServer
      hasDataServer
      dashboardPeriod
      uploadConfig {
        name
        type
      }
    }
  }
`

export const UPDATE_BUILDING = gql`
  mutation BuildingUpdate($data: BuildingUpdateInput!) {
    buildingUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      projectRef
      slug
      picture
      thumb
      epcRating
      epcUrl
      floorArea
      hasFileServer
      hasDataServer
      dashboardPeriod
      uploadConfig {
        name
        type
      }
    }
  }
`
export const DELETE_BUILDING = gql`
  mutation BuildingDelete($data: BuildingDeleteInput!) {
    buildingDelete(data: $data) {
      message
    }
  }
`

export const UPDATE_DASHBOARD = gql`
  mutation DashboardUpdate($data: DashboardUpdateInput!) {
    dashboardUpdate(data: $data) {
      id
      period
    }
  }
`

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      message
      user {
        id
        firstName
        lastName
        slug
        picture
        isStaff
        isAdmin
        companyId
      }
    }
  }
`

export const SIGN_OUT = gql`
  mutation signOut {
    signOut {
      message
    }
  }
`
export const CREATE_COMPANIES = gql`
  mutation createCompany($data: CompanyCreateInput!) {
    companyCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      slug
      portfolioImage
      logo
      userIds
    }
  }
`

export const CREATE_USERS = gql`
  mutation UserCreate($data: UserCreateInput!) {
    userCreate(data: $data) {
      id
      createdAt
      updatedAt
      companyId
      email
      firstName
      lastName
      slug
      picture
      role
      customPermissions
    }
  }
`

export const ADD_COMPANY_RELATIONSHIP = gql`
  mutation AddCompanyRelationship($data: AddCompanyRelationshipInput!) {
    addCompanyRelationship(data: $data) {
      buildingId
      companyId
      buildingName
      companyReference
      companyReferenceName
      relationshipType
    }
  }
`

export const REMOVE_COMPANY_RELATIONSHIP = gql`
  mutation RemoveCompanyRelationship($data: RemoveCompanyRelationshipInput!) {
    removeCompanyRelationship(data: $data) {
      message
    }
  }
`

export const UPDATE_COMPANY_RELATIONSHIP = gql`
  mutation UpdateCompanyRelationship($data: UpdateCompanyRelationshipInput!) {
    updateCompanyRelationship(data: $data) {
      companyId
      buildingId
      buildingName
      companyReference
      companyReferenceName
      relationshipType
    }
  }
`
