import {
  useToast,
  Center,
  Box,
  Button,
  Skeleton,
  Text,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import {
  DataGetManyQuery,
  useDataGetManyLazyQuery,
} from '../../graphql/generated/graphql'
import { BuildingDetailsProps } from '../../Pages/buildings/buildingDetails'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import BrushChart from '../../elements/graphPlots/brushChart'
import Legend from '../../elements/graphPlots/Legend'
import { graphColors } from '../../utils/colorMaps/tightMap'
import { ChevronDownIcon } from '@chakra-ui/icons'
import domtoimage from 'dom-to-image'

import { convertJsonToCsv } from '../../utils/basicFunc/basicFunc'
import ChannelListTable from './channelListTable'
import { FilterData } from './filterData'

export type ParamsType = {
  period?: string
  startTime?: Date
  endTime?: Date
}

export const BuildingDetails = ({
  loading = false,
  channelListData,
  buildingName,
  channelListDataLoading,
}: BuildingDetailsProps) => {
  const toast = useToast()
  const [selectedChannels, setSelectedChannels] = useState<string[]>([])
  const [selectedChannelData, setSelectedChannelData] = useState<
    DataGetManyQuery[]
  >([])
  const [legendNames, setLegendNames] = useState<string[]>([])
  const [params, setParams] = useState<ParamsType>({})
  const graphRef = useRef(null)

  let startTime =
      params?.startTime && isNaN(params.startTime.getTime())
        ? undefined
        : params.startTime,
    endTime =
      params?.endTime && isNaN(params.endTime.getTime())
        ? undefined
        : params.endTime
  const urlParams = new URLSearchParams(window.location.search)

  let [getChannelData, { loading: channelGraphDataLoading }] =
    useDataGetManyLazyQuery()
  // { fetchPolicy: 'no-cache' },

  useEffect(() => {
    if (channelListData?.length) {
      if (urlParams.get('channelId') !== null) {
        setSelectedChannels([urlParams.get('channelId') ?? ''])
      } else {
        setSelectedChannels([channelListData[0]?.id!])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelListData])

  useEffect(() => {
    fetchChannelDataFromState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannels, params])

  const fetchChannelDataFromState = () => {
    selectedChannels.forEach(async (id) => {
      fetchChannelData(id)
    })
  }

  const onChannelSelect = (id: string) => {
    if (!selectedChannels.includes(id)) {
      if (selectedChannels.length <= 4) {
        setSelectedChannels([...selectedChannels, id])
      } else {
        toast({
          title: 'Can only select 5 channels at a time',
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      }
    } else if (selectedChannels.length) {
      let afterRemovedChannelData: DataGetManyQuery[] = selectedChannelData,
        removedChannelId = id,
        afterRemovedChannelList = selectedChannels.filter(
          (channelId) => channelId !== id,
        )
      afterRemovedChannelData = afterRemovedChannelData.filter(
        (channelData) => channelData.dataGetMany.id !== removedChannelId,
      )

      let channelNames: string[] = []
      afterRemovedChannelData.forEach((channelData) => {
        let name = channelListData?.find(
          (channel) => channel?.id === channelData?.dataGetMany.id,
        )?.name
        if (name) {
          channelNames.push(name)
        }
      })
      setLegendNames(channelNames)
      setSelectedChannelData(afterRemovedChannelData)
      setSelectedChannels(afterRemovedChannelList)
    }
  }

  const fetchChannelData = async (id: string) => {
    try {
      const { data } = await getChannelData({
        variables: {
          where: {
            chanId: id,
            period: params.period ? params.period : 'none',
            startTime,
            endTime,
          },
        },
      })

      const dataAlreadyExists = selectedChannelData.some(
        (existingData) => existingData.dataGetMany.id === data?.dataGetMany.id,
      )

      const dataUpdation = selectedChannelData.some((existingData) =>
        existingData.dataGetMany.data.some(
          (item) =>
            item?.channelId === data?.dataGetMany.id &&
            (existingData.dataGetMany.data[0]?.timestamp !==
              data?.dataGetMany.data[0]?.timestamp ||
              existingData.dataGetMany.data[
                existingData.dataGetMany.data.length - 1
              ]?.timestamp !==
                data?.dataGetMany.data[existingData.dataGetMany.data.length - 1]
                  ?.timestamp),
        ),
      )

      if (dataUpdation) {
        // if the data is already present, update the state
        setSelectedChannelData((prevData) => {
          const updatedChannelData = prevData.map((channelData) => {
            return channelData.dataGetMany.id === data?.dataGetMany.id
              ? (data as DataGetManyQuery)
              : channelData
          })
          return updatedChannelData
        })
      } else if (!dataAlreadyExists) {
        // If the data doesn't exist, update the state
        let channelNames: string[] = [],
          allChannelData = [...selectedChannelData, data]
        allChannelData.forEach((channelData) => {
          let name = channelListData?.find(
            (channel) => channel?.id === channelData?.dataGetMany.id,
          )?.name
          if (name) {
            channelNames.push(name)
          }
        })
        setLegendNames(channelNames)
        setSelectedChannelData((prevData) => [
          ...prevData,
          data as DataGetManyQuery,
        ])
      }
    } catch (error) {
      console.error(`Error fetching data for channel ${id}:`, error)
    }
  }

  const setFilterValues = (data: ParamsType) => {
    setParams(data)
  }

  const handleJpegConversion = () => {
    const node = graphRef?.current
    domtoimage
      .toJpeg(node!)
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = 'converted_image.png'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.error('Error converting to image:', error)
      })
  }

  return (
    <Box>
      <Box>
        <Skeleton isLoaded={!channelListDataLoading}>
          <Text fontSize={{ base: 22, md: 40 }} color={'purple.500'}>
            Detail Data of {buildingName}
          </Text>
        </Skeleton>
      </Box>

      <Box p={{ base: '20px', lg: '30px' }} border={'1px solid #cbc7c7'}>
        <Center justifyContent={'flex-end'} mb={'15px'}>
          <FilterData
            setFilterValues={setFilterValues}
            filterValues={params}
            loading={channelGraphDataLoading}
          />
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              Actions
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() =>
                  convertJsonToCsv({
                    datasets: selectedChannelData,
                    buildingName: buildingName ? buildingName : '',
                    channelListData,
                  })
                }
              >
                Download selected channels data
              </MenuItem>
              <MenuItem onClick={handleJpegConversion}>
                Download current graph as JPEG
              </MenuItem>
              {/* <MenuItem>Download all channel data</MenuItem> */}
            </MenuList>
          </Menu>
        </Center>
        <Box display={'flex'} flexDir={'column'} w={'100%'}>
          {loading || channelGraphDataLoading ? (
            <Skeleton minHeight={`calc(100vh - 400px)`} />
          ) : !(channelListData?.length && selectedChannelData.length) ? (
            <Center minHeight={`calc(100vh - 400px)`}>
              <Heading>No Data</Heading>
            </Center>
          ) : (
            <Box
              display={'flex'}
              bg={'white'}
              alignItems={'center'}
              flexDirection={{ base: 'column', lg: 'column' }}
              ref={graphRef}
              flexGrow={1}
            >
              <Box minW={'100%'} minH={`500px`} h={`calc(100vh - 427px)`}>
                <ParentSize>
                  {({ width, height }) => (
                    <BrushChart
                      width={width}
                      height={height}
                      selectedChannelData={selectedChannelData}
                    />
                  )}
                </ParentSize>
              </Box>
              <Box mb={2}>
                <Legend
                  legendNames={legendNames}
                  graphColors={graphColors}
                  size={12}
                />
              </Box>
            </Box>
          )}
          <Divider />
          <ChannelListTable
            onChannelSelect={onChannelSelect}
            channelListData={channelListData}
            selectedChannels={selectedChannels}
          />
        </Box>
      </Box>
    </Box>
  )
}
