import { Box, Center, Heading, Text } from '@chakra-ui/react'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {
  XYChart,
  AnimatedAxis,
  AnimatedGrid,
  AnimatedBarGroup,
  AnimatedBarSeries,
  Tooltip,
  // RenderTooltipParams,
} from '@visx/xychart'
import { useTranslation } from 'react-i18next'
import { BuildingGetOneQuery } from '../../../graphql/generated/graphql'
import { topLargest } from '../../../utils/basicFunc/basicFunc'
import { indicatorTypeData } from '../../../utils/indicators/indicators'

type RenderTooltipFunction = (
  params: any, // REMOVED SUPPORT FOR THE BELOW LINE (RenderTooltipParams), NOT ABLE TO ASSIGN TYPE
  // params: RenderTooltipParams<SecondaryGraphData>,
) => JSX.Element

export const overlayStyles = {
  position: 'absolute' as const,
  width: '100%',
  height: '100%',
  background: '#ffffffc7',
  borderRadius: '5px',
  zIndex: 1,
}

const accessors = {
  xAccessor: (d: SecondaryGraphData) => (
    <Text>Energy Used: {d.measurement / 1000}&thinsp;kWh</Text>
  ),
  label: (d: SecondaryGraphData) => indicatorTypeData[d.type!]?.name,
}

export type SecondaryGraphData = {
  target: number
  measurement: number
  type: string
}

export function buildPlotData(
  buildingDTO: BuildingGetOneQuery['buildingGetOne'],
) {
  const identifyKey = (key: string) => {
    let type = ''
    switch (key) {
      case 'coolingEnergy':
        type = 'Cooling'
        break
      case 'lightingEnergy':
        type = 'Lighting'
        break
      case 'heatingEnergy':
        type = 'Heating'
        break
      case 'processEnergy':
        type = 'ProcessPower'
        break
      case 'socketPower':
        type = 'SmallPower'
        break
      case 'ventilation':
        type = 'Ventilation'
        break
    }
    return type
  }

  const secondaryGraphData: SecondaryGraphData[] = []

  for (const [key, value] of Object.entries(buildingDTO?.portfolio || {})) {
    if (identifyKey(key)) {
      secondaryGraphData.push({
        type: identifyKey(key),
        measurement: value?.measurement ?? 0,
        target: value?.target ?? 0,
      })
    }
  }

  const isGraphInvalid = secondaryGraphData?.every(
    (secondaryData) => !secondaryData?.measurement,
  )
  const defaultPlotData = [
    {
      type: 'Lighting',
      measurement: 500,
      target: 2000,
    },
    {
      type: 'ProcessPower',
      measurement: 1500,
      target: 2000,
    },
    {
      type: 'Ventilation',
      measurement: 2500,
      target: 2000,
    },
    {
      type: 'Cooling',
      measurement: 2000,
      target: 2000,
    },
    {
      type: 'Heating',
      measurement: 2000,
      target: 2000,
    },
  ]

  return {
    data: isGraphInvalid ? defaultPlotData : topLargest(secondaryGraphData, 5),
    isGraphInvalid,
  }
}

export interface IEnergyUseProps {
  loading: boolean
  buildingDTO: BuildingGetOneQuery['buildingGetOne']
}

const EnergyUse = ({ buildingDTO, loading }: IEnergyUseProps) => {
  const { t } = useTranslation('building')
  const { data, isGraphInvalid } = buildPlotData(buildingDTO)

  if (loading || data === undefined) {
    return (
      <Box
        py={6}
        px={'0.25rem'}
        background={'gray.300'}
        textColor={'purple.600'}
        h="100%"
      >
        <Box background={'white'} p={4} h="100%">
          <Heading as="h3" size="md">
            Energy Use
          </Heading>
          <Box height="300px" width="100px">
            loading...
          </Box>
        </Box>
      </Box>
    )
  }

  const renderTooltip: RenderTooltipFunction = ({
    tooltipData,
    colorScale,
  }) => {
    return (
      <Box>
        <Text
          style={{
            color: colorScale(tooltipData.nearestDatum.key),
          }}
          mb={1}
        >
          {accessors.label(tooltipData.nearestDatum.datum)}
        </Text>
        <Text mb={1}>
          {accessors.xAccessor(tooltipData.nearestDatum.datum)}
        </Text>
      </Box>
    )
  }

  return (
    <Box
      py={{ base: 2, md: 6 }}
      px={{ base: '0.5rem', md: 6 }}
      background={'gray.300'}
      textColor={'purple.600'}
      h="100%"
    >
      <Box background="white" p={4} h="100%">
        <Heading as="h3" size="md">
          {t('energyUse')}
        </Heading>
        <Box height="300px" width="100%" position={'relative'}>
          <Center {...overlayStyles} display={isGraphInvalid ? 'flex' : 'none'}>
            <Heading>{t('noData')}</Heading>
          </Center>
          <ParentSize>
            {({ width, height }) => (
              <XYChart
                height={height}
                width={width}
                xScale={{ type: 'band', paddingInner: 0.2 }}
                yScale={{ type: 'linear' }}
                margin={{ top: 40, right: 50, left: 70, bottom: 70 }}
              >
                <AnimatedGrid
                  rows={false}
                  columns={false}
                  animationTrajectory={'center'}
                />
                <AnimatedBarGroup>
                  <AnimatedBarSeries
                    dataKey="Energy Use"
                    // data={topLargest(use, 6)}
                    data={data.sort((a, b) => b.measurement - a.measurement)}
                    yAccessor={(d) => d.measurement! / 1000}
                    xAccessor={(d) => indicatorTypeData[d.type!]?.miniName}
                    colorAccessor={() => '#372e70'}
                  />
                </AnimatedBarGroup>
                <AnimatedAxis
                  key={'use-axis'}
                  orientation={'bottom'}
                  label={'End Use'}
                  animationTrajectory="outside"
                  labelProps={{
                    fontFamily: 'bouldregular',
                    textAnchor: 'middle',
                    fontSize: 14,
                  }}
                  labelOffset={15}
                  tickLabelProps={(props) => {
                    return {
                      ...props,
                      fontFamily: 'bouldregular',
                      fontSize: 10,
                      // angle: 45,
                      textAnchor: 'middle',
                    }
                  }}
                />
                <AnimatedAxis
                  key={'consumption-axis'}
                  orientation="left"
                  animationTrajectory="max"
                  label={'Consumption /kWh'}
                  labelProps={{
                    fontFamily: 'bouldregular',
                    textAnchor: 'middle',
                    fontSize: 14,
                  }}
                  labelOffset={45}
                  tickLabelProps={(props) => {
                    return {
                      ...props,
                      fontFamily: 'bouldregular',
                    }
                  }}
                />
                <Tooltip<SecondaryGraphData>
                  snapTooltipToDatumX
                  snapTooltipToDatumY
                  showVerticalCrosshair
                  showSeriesGlyphs
                  renderTooltip={renderTooltip}
                />
              </XYChart>
            )}
          </ParentSize>
        </Box>
      </Box>
    </Box>
  )
}

export default EnergyUse
