import {
  Button,
  Center,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  Box,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Filter } from 'react-feather'
import { useForm } from 'react-hook-form'
import { ParamsType } from './buildingDetails'

export const FilterData = ({
  setFilterValues,
  filterValues,
  loading,
}: {
  setFilterValues: (arg: ParamsType) => void
  filterValues: ParamsType
  loading: boolean
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [localState, setLocalState] = useState<ParamsType>(filterValues)
  const { reset, handleSubmit, register } = useForm<ParamsType>({
    mode: 'onChange',
  })

  const startTime =
      localState?.startTime && isNaN(localState?.startTime?.getTime()!)
        ? filterValues?.startTime && isNaN(filterValues?.startTime?.getTime()!)
          ? filterValues?.startTime?.toISOString().slice(0, -1)
          : undefined
        : localState?.startTime?.toISOString().slice(0, -1),
    endTime =
      localState?.endTime && isNaN(localState?.endTime?.getTime()!)
        ? filterValues?.endTime && isNaN(filterValues?.endTime?.getTime()!)
          ? filterValues?.endTime?.toISOString().slice(0, -1)
          : undefined
        : localState?.endTime?.toISOString().slice(0, -1)

  const isValidateDateRange = () => {
    const { startTime, endTime } = localState
    if (startTime && endTime && startTime > endTime) {
      return false
    } else return true
  }

  const onSubmit = (data: ParamsType) => {
    if (isValidateDateRange()) {
      setFilterValues({
        startTime: new Date(data.startTime ?? ''),
        endTime: new Date(data.endTime ?? ''),
        period: data.period,
      })
      onClose()
    } else {
      toast({
        title: 'Please select a valid start and end time',
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const onReset = () => {
    setFilterValues({})
    onClose()
    reset()
    setLocalState({})
  }

  return (
    <>
      <Button onClick={onOpen} mr={5} colorScheme={'blue'}>
        <Filter />
      </Button>

      <Modal
        onClose={() => {
          onClose()
          setLocalState({ period: 'none' })
        }}
        isOpen={isOpen}
        isCentered
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Filter Data</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Center mb={5}>
                <FormControl id="startTime" mr={5}>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type="dateTime-local"
                    {...register('startTime')}
                    onChange={(e) =>
                      setLocalState({
                        ...localState,
                        startTime: new Date(e.target.value),
                      })
                    }
                    value={startTime}
                  />
                </FormControl>
                <FormControl id="endTime">
                  <FormLabel>End Date</FormLabel>
                  <Input
                    type="dateTime-local"
                    {...register('endTime')}
                    onChange={(e) =>
                      setLocalState({
                        ...localState,
                        endTime: new Date(e.target.value),
                      })
                    }
                    value={endTime}
                  />
                </FormControl>
              </Center>

              <FormControl id="period">
                <FormLabel>Period</FormLabel>
                <Select
                  placeholder="Select Period"
                  {...register('period')}
                  onChange={(e) =>
                    setLocalState({
                      ...localState,
                      period: e.target.value,
                    })
                  }
                  value={localState.period ?? filterValues.period}
                >
                  <option value="none">None</option>
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </Select>
              </FormControl>
            </ModalBody>
            <ModalFooter justifyContent={'space-between'}>
              <Button onClick={onReset} mr={5}>
                Reset
              </Button>
              <Box>
                <Button
                  onClick={() => {
                    reset()
                    onClose()
                    setLocalState({})
                  }}
                  mr={5}
                >
                  Cancel
                </Button>
                <Button
                  type={'submit'}
                  colorScheme={'blue'}
                  isLoading={loading}
                >
                  Submit
                </Button>
              </Box>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FilterData
