const baseColor = [
  '#91b706',
  '#91b706',
  '#adba0a',
  '#c7bd17',
  '#e1bf27',
  '#f9c138',
  '#f9c138',
  '#fda129',
  '#fe7f28',
  '#fa5931',
  '#f2253e',
  '#f2253e',
]

const upIsBetterColors = [
  '#95F985',
  '#8AF480',
  '#7EE97A',
  '#73DE75',
  '#68D36F',
  '#5DC86A',
  '#52BD64',
  '#47B25F',
  '#3CA75A',
  '#31AC54',
  '#00AB08',
]

const basePos = [
  0.0, 0.3, 0.3125, 0.325, 0.3375, 0.35, 0.65, 0.6625, 0.675, 0.6875, 0.7, 1.0,
]

type tightMapCalcProps = {
  value: number
  range: number
  upIsBetter: boolean
  spread?: number
}

export const tightMapCalc = ({
  value,
  range,
  upIsBetter,
  spread = 20,
}: tightMapCalcProps) => {
  const colorList = upIsBetter ? upIsBetterColors : [...baseColor]

  const colorMap = colorList.map((color, idx) => ({
    color: color,
    pos: basePos[idx],
  }))

  const colorStop =
    value <= 100 - spread
      ? colorMap[0]
      : value <= 100 + spread
      ? colorMap[6]
      : colorMap[10]

  if (value <= 100 - spread) {
    return {
      color: colorStop.color,
      colorMap: [
        { color: colorStop.color, pos: 0.0 },
        { color: colorStop.color, pos: 1.0 },
      ],
    }
  }
  if (value >= range) {
    return {
      color: colorStop.color,
      colorMap: [
        { color: colorStop.color, pos: 0.0 },
        { color: colorStop.color, pos: 1.0 },
      ],
    }
  }
  if (value >= 100 + spread) {
    return {
      color: colorStop.color,
      colorMap,
    }
  }
  // If we get here, we need to build a custom color map
  // for the value, which is yellow colored
  colorMap[7] = { color: '#f9c138', pos: 1 }

  return {
    color: colorStop.color,
    colorMap: colorMap.slice(0, 7),
  }
}

export const graphColors = ['#a79dc0', 'red', 'blue', 'green', 'yellow']

// export const graphColors = [
//   '#a79dc0',
//   '#372e70',
//   '#8a2b56',
//   '#2b8a6b',
//   '#91b706',
//   '#fbcd69',
//   '#ec6361',
// ]
