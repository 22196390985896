import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend'

import { scaleOrdinal } from '@visx/scale'

type LegendProps = {
  legendNames: string[]
  graphColors: string[]
  size: number
}

export default function Legend({
  legendNames,
  graphColors,
  size,
}: LegendProps) {
  const ordinalColorScale = scaleOrdinal({
    domain: legendNames,
    range: graphColors,
  })
  return (
    <LegendOrdinal
      scale={ordinalColorScale}
      labelFormat={(label) => `${label.toUpperCase()}`}
    >
      {(labels) => (
        <div style={{ display: 'flex' }}>
          {labels.map(
            (label, i) =>
              !!label.datum && (
                <LegendItem
                  key={`legend-quantile-${i}`}
                  margin="0 5px"
                  // onClick={() => {
                  //   if (events) alert(`clicked: ${JSON.stringify(label)}`)
                  // }}
                >
                  <svg
                    width={size}
                    height={size}
                    style={{ margin: '2px 0', flexShrink: 0 }}
                  >
                    <circle
                      fill={label.value}
                      r={size / 2}
                      cx={size / 2}
                      cy={size / 2}
                    />
                  </svg>
                  <LegendLabel
                    align="left"
                    style={{ fontSize: size, margin: '3px' }}
                  >
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ),
          )}
        </div>
      )}
    </LegendOrdinal>
  )
}
