import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { SignInMutation } from '../../../graphql/generated/graphql'
import { useAuth } from '../../../hooks/use-auth'

type FormValues = {
  email: string
  password: string
}

const SignInForm = () => {
  const { t } = useTranslation('forms')
  const auth = useAuth()
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({ mode: 'onChange' })

  const [show, setShow] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | null>(null)
  const navigate = useNavigate()
  const location = useLocation()

  const handlePWShowClick = () => setShow(!show)

  const onSubmit = async (data: FormValues) => {
    let signInPromise = new Promise<SignInMutation | undefined>((resolve) => {
      resolve(auth.signIn(data.email, data.password, () => {}))
    })

    signInPromise
      .then((signInData) => {
        if (signInData?.signIn.token === null) {
          // Sign in failed, reset the form and notify the user
          reset()
          setFormError(signInData?.signIn.message)
        } else {
          setFormError(null)
          navigate(from, { replace: true })
        }
      })
      .catch((error) => {
        reset()
        setFormError(error)
      })
  }

  const from = location.state?.from.pathname || '/buildings'
  location.state?.from.pathname &&
    formError === null &&
    setFormError('signIn.signInToSee')

  return (
    <ModalContent>
      <ModalHeader>
        <Center>
          <Heading as="h1">{t('signIn.signIn')}</Heading>
        </Center>
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Center mb={4} minH={6}>
            {formError && (
              <Text size="lg" textColor={'red.400'}>
                {t(formError)}
              </Text>
            )}
          </Center>
          <FormControl variant="floating" id="email" isInvalid={!!errors.email}>
            <Input
              type="email"
              placeholder={t('signIn.email') ?? 'email...'}
              {...register('email', {
                required: 'requiredItem',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'requiredEmail',
                },
              })}
            />
            <FormLabel>{t('signIn.email')}</FormLabel>
            {errors.email ? (
              <FormErrorMessage minH={8}>
                {t(errors.email?.message || '')}
              </FormErrorMessage>
            ) : (
              <Box minH={8} mt={2}></Box>
            )}
          </FormControl>
          <FormControl
            variant="floating"
            id="password"
            isInvalid={!!errors.password}
            mt={4}
          >
            <InputGroup>
              <Input
                type={show ? 'text' : 'password'}
                placeholder=" "
                {...register('password', {
                  required: 'requiredPass',
                })}
              />
              <FormLabel>{t('signIn.password')}</FormLabel>
              <InputRightElement>
                <IconButton
                  size="sm"
                  onClick={handlePWShowClick}
                  aria-label={t('signIn.aria.hidePass')}
                  icon={show ? <ViewIcon /> : <ViewOffIcon />}
                />
              </InputRightElement>
            </InputGroup>
            {errors.password ? (
              <FormErrorMessage minH={8}>
                {t(errors.password?.message || '')}
              </FormErrorMessage>
            ) : (
              <Box minH={8} mt={2}></Box>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Center justifyContent={'space-between'} w={'100%'}>
            <Box>
              <Text color={'purple'}>
                <Link href={'/reset'}>Forgot Password</Link>
              </Text>
            </Box>
            <Box>
              <Button variant="ghost" type="reset" mr={5}>
                {t('clear')}
              </Button>
              <Button
                isLoading={isSubmitting}
                type="submit"
                colorScheme="purple"
              >
                {t('signIn.signIn')}
              </Button>
            </Box>
          </Center>
        </ModalFooter>
      </form>
    </ModalContent>
  )
}

export default SignInForm
