import {
  Box,
  Center,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react'

import Comparison from '../../comparison/comparison'
import CardIcons from './cardIcons'
import CircleIndicator from './circleIndicator'

import { tightMapCalc } from '../../../utils/colorMaps/tightMap'
import { indicatorTypeData } from '../../../utils/indicators/indicators'
import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'
import { CardData } from '../../../Pages/buildings/dashboardWrapper'

const PrimaryTile = ({
  type,
  measurement,
  target,
  comment,
  comparison,
}: CardData) => {
  const percent = target ? Math.ceil((measurement! / target!) * 100) : 0
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  const { color: iconColor } = tightMapCalc({
    value: percent,
    range: 150,
    upIsBetter: indicatorTypeData[type!].upIsBetter,
  })
  const indicatorSize = useBreakpointValue({
    base: 100,
    md: 300,
    lg: 200,
    xl: 400,
    '2xl': is1440p ? 500 : is4k ? 800 : 400,
  })
  const iconSize = useBreakpointValue({
    base: '25px',
    md: 55,
    lg: 30,
    xl: '40px',
    '2xl': is1440p ? 70 : is4k ? 120 : 50,
  })
  const comparisonFontSize = useBreakpointValue({
    base: '2.5vw',
    md: 20,
    lg: '1.2vw',
    '2xl': is1440p ? '1.2vw' : is4k ? '1.3vw' : '1.2vw',
  })
  return (
    <Center
      h={'100%'}
      flexDirection={{ md: 'row', lg: 'column' }}
      justifyContent={{ base: 'space-between', lg: 'center' }}
      fontWeight={400}
      lineHeight={1}
    >
      <Box
        position={'relative'}
        height={{ lg: '60%' }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexBasis={{ base: '50%', lg: 'unset' }}
        flexShrink={{ base: 0, lg: 'unset' }}
        p={{ base: '10px', md: 'none' }}
      >
        <CircleIndicator percent={percent} size={indicatorSize} type={type!} />
        <Box
          position={'absolute'}
          color={'dashboard.fontColor'}
          width={'min-content'}
        >
          <Center>
            <CardIcons iconType={type!} color={iconColor} width={iconSize} />
          </Center>
          <Center
            m={{
              base: '8px 0',
              md: '15px 0',
              lg: '10px 0',
              '2xl': is1440p ? '20px 0' : is4k ? '30px 0' : '15px 0',
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'baseline'}
            >
              <Text
                fontSize={{
                  base: '18px',
                  md: '40px',
                  lg: '25px',
                  xl: '30px',
                  '2xl': is1440p ? 55 : is4k ? 100 : 45,
                }}
                lineHeight={0.8}
              >
                {measurement}
              </Text>
              &nbsp;
              <Text
                fontSize={{
                  base: '13px',
                  md: '25px',
                  lg: '15px',
                  xl: '22px',
                  '2xl': is1440p ? 35 : is4k ? 65 : 30,
                }}
              >
                {indicatorTypeData[type!].unit}
              </Text>
            </Box>
          </Center>
          <Center>
            <Text
              textAlign={'center'}
              fontSize={{
                base: '4vw',
                md: '28px',
                lg: '1.6vw',
                '2xl': is1440p ? 40 : is4k ? 70 : 32,
              }}
            >
              {indicatorTypeData[type!].name}
            </Text>
          </Center>
        </Box>
      </Box>
      <Box
        borderTop={{
          lg: `1px solid ${iconColor}`,
          '2xl': is4k ? `3px solid ${iconColor}` : `1px solid ${iconColor}`,
        }}
        borderLeft={{
          base: `1px solid ${iconColor}`,
          md: `2px solid ${iconColor}`,
          lg: 'none',
        }}
        p={{ base: '15px', md: '30px', lg: 'unset' }}
        // pl={'15px'}
        color={'dashboard.fontColor'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-evenly'}
        mt={{ lg: 5 }}
        // ml={{ md: 5 }}
        lineHeight={'initial'}
        height={{ base: '100%', lg: '45%' }}
      >
        {/* <Box justifySelf={'end'}> */}
        <Comparison
          p={'5px 0'}
          fontSize={comparisonFontSize}
          text={comparison!}
          color={'dashboard.fontColor'}
          centerAlign={true}
        />
        {/* </Box> */}
        <Text
          fontSize={{ base: '2.9vw', md: '2.7vw', lg: '1.3vw' }}
          m={'20px 0'}
          textAlign={'justify'}
        >
          {comment
            ? comment
            : "We're still establishing targets for this building, once we have those we'll use this space to let you know how we're doing."}
        </Text>
      </Box>
    </Center>
  )
}

export default PrimaryTile
